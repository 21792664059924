<template>
  <div class="body signup-wrapper" v-if="signUpBanner">
    <div class="top">
      <h1>L<span>ai</span>ya</h1>
      <p class="quote">{{ signUpBanner.text }}</p>
    </div>
    <div class="bottom">
      <button @click="upgradeNow()">{{ signUpBanner.button_text }}</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { getSignupBanner } from '../../../services/strapi-service'

const isPayingModalOpen = ref(false)
const router = useRouter()
const route = useRoute()
const { id } = route.params
const signUpBanner = ref<{ text: string; button_text: string } | null>(null)

const upgradeNow = () => {
  localStorage.setItem('upgradeId', id as string)
  router.push({ name: 'plans' })
}

onMounted(async () => {
  const res = await getSignupBanner()
  signUpBanner.value = res
})
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.body {
  width: 100%;

  @include md {
    width: 1440px;
  }

}

.signup-wrapper {
  color: white;

  .top {
    background-color: $mid-black;
    padding: 2.5rem;

    @include lg {
      padding-left: 0;
    }


    h1 {
      font-size: 30px;

      span {
        color: $yellow;
      }
    }

    p {
      font-size: 2rem;
      font-weight: 300;
      line-height: 140%;
      letter-spacing: -0.32px;
    }
  }

  .bottom {
    background-color: $light-black;
    padding: 2.5rem 0;

    @include sm {
      display: flex;
      align-items: center;
    }

    button {
      margin: 0 auto;
      background-color: $mid-green;
      color: $black;
      border: 1px solid $mid-green;

      &:hover {
        background-color: transparent;
        color: $mid-green;
      }
    }
  }

  @include sm {
    display: flex;

    .top {
      flex: 1;
      display: flex;
      align-items: center;
      gap: 3rem;
    }

    .bottom {
      padding-right: 10rem;
      padding-left: 10rem;
    }
  }
}
</style>
