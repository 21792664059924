import './assets/main.scss'
import { createApp, markRaw } from 'vue'
import { createPinia, type PiniaPluginContext } from 'pinia'
import { VueRecaptchaPlugin } from 'vue-recaptcha/head'
import VueGtag, { bootstrap } from 'vue-gtag'
import VuePaginatePlugin from '@svifty7/vue-paginate'

import App from './App.vue'
import router from './router'
import requestInterceptor from './http-interceptors/request-interceptor'

import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import 'vue-cookie-accept-decline/dist/vue-cookie-accept-decline.css'
import { createHead } from '@unhead/vue'
import * as Sentry from '@sentry/vue'

const app = createApp(App)
const head = createHead()
app.use(head)
const pinia = createPinia()
pinia.use(({ store }: PiniaPluginContext) => {
  store.router = markRaw(router)
})
app.use(VueRecaptchaPlugin, {
  v3SiteKey: import.meta.env.VITE_STRAPI_CAPTCHA_SITE
})
app.use(VuePaginatePlugin)

if (import.meta.env.VITE_ENVIRONMENT !== 'local') {
  Sentry.init({
    app,
    environment: import.meta.env.VITE_ENVIRONMENT,
    dsn: 'https://a4878725d0516548c40eb53b22fd6a9d@o4507447814651904.ingest.de.sentry.io/4507447818387536',
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

app.use(pinia)
app.use(router)
app.component('vue-cookie-accept-decline', VueCookieAcceptDecline)
// disable GA if user does not accept cookies
app.use(
  VueGtag,
  {
    bootstrap: false,
    appName: 'Laiya',
    pageTrackerScreenviewEnabled: true,
    config: { id: import.meta.env.VITE_STRAPI_GOOGLE_MEASUREMENT_ID }
  },
  router
)
requestInterceptor()

app.mount('#app')
