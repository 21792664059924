import { defineStore } from 'pinia'
import { getBlogContent, pinArticle, regenerateAllArticles, updateAllArticles } from '../services/messaging-service'

export const useBlogContentStore = defineStore({
  id: 'blog-content',
  state: (): BlogContentInterface => ({ articles: [] }),
  actions: {
    async getArticles(id: number) {
      this.articles = await getBlogContent(id)
    },
    async regenerateArticles(id: number) {
      this.articles = await regenerateAllArticles(id)
    },
    async pinSinglePost(id: number) {
      const res = await pinArticle(id)

      this.articles = this.articles.map((article) => {
        if (article.id !== id) return article

        return res
      })
    },
    async updateArticles(id: number) {
      await updateAllArticles(id, this.articles)
    },
    clearStore() {
      this.articles = []
    }
  },
  getters: {
    unpinnedPosts: (state) => state.articles.filter((article) => !article.pinned),
    inactive: (state) => state.articles.filter((article) => !article.is_active)
  }
})

type BlogContentInterface = {
  articles: Article[]
}

export type Article = {
  title: string
  except: string
  pinned: boolean
  id: number
  is_active: boolean
}
