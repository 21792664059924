<template>
  <div>
    <modal-component @close="() => emit('close')">
      <template v-slot:title>
        <p class="lead-text">We have something exciting to show you</p>
      </template>
      <template v-slot:body>
        <p class="first-p">
          Based on your answers we have created user profiles of your three top users. This information can be used to
          create a website tailored to your users and refine your marketing material.
        </p>
        <p>This is the audience profile part of our full offering, you can upgrade to see these additional areas:</p>

        <div class="section-icon-wrapper">
          <div
            v-for="(item, i) in sections"
            :key="i"
            class="section"
            :class="[i === 0 ? item.name + ' router-link-active' : '', 'paid']"
          >
            <div class="nav-wrapper">
              <img :id="item.name + '_image'" :src="getImageUrl(item.name, i === 0)" />

              <div class="bottom" :class="[i === 0 ? 'first' : '']">
                <p>{{ item.description }}</p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="close">
          <button @click="() => emit('close')">Close</button>
        </div>
      </template>
    </modal-component>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import ModalComponent from '@components/ui/modal-component/ModalComponent.vue';

const benefits = ref(null);
const emit = defineEmits(['close']);

// TODO: get from CMS
const sections = [
  { name: 'personas', description: 'Audience profiling' },
  { name: 'architecture', description: 'Website structure' },
  { name: 'colours', description: 'Colour palette and styling' },
  { name: 'messaging', description: 'Content and messaging' }
];

const getImageUrl = (name: string, isActive: boolean) => {
  return new URL(`../../assets/icons/${name}${isActive ? '_active.svg' : '.svg'}`, import.meta.url).href;
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.section-icon-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include lg {
    flex-wrap: nowrap;
  }
}

.lead-text {
  font-size: 1.1rem;
  height:fit-content;
}

.first-p {
  margin-bottom: 1rem;
}

p {
  font-size: 16px;
}

.close {
  width: 100%;
  display: flex;
  gap: 1.25rem;
  flex-direction: column;

  button {
    margin: 0;
    width: 100%;
  }
  @include sm {
    flex-direction: row;
    justify-content: center;
  }
}

.section {
  width: 46%;

  @include sm {
    width: 24%;
  }

  height: 200px;
  margin-top: 30px;
  &.router-link-active,
  &:hover {
    &.personas {
      .nav-wrapper {
        background-color: $mid-green;
      }
    }
  }

  .nav-wrapper {
    display: flex;
    margin-bottom: 1.25rem;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    background-color: $grey-2;
    width: 100%;
    height: 100%;

    .bottom {
      border-radius: 0 0 15px 15px;
      background-color: $grey-3;
      display: flex;
      align-items: center;
      min-height: 80px;
      p {
        text-align: center;
        font-weight: 600;
        font-size: 0.9rem;
        padding: 0 10px;
      }
    }
    .first {
      background-color: $dark-green;
    }
    img {
      width: 85%;
      height: 100%;
    }
  }
}
</style>
