<template>
  <div class="try">
    <div class="try-it-title" v-show="section === 'HOME'">
      <h3>Create a new project</h3>
    </div>
    <div class="company-input" :class="section">
      <CompanyDescriptionInput :showName="true" :section="section" @submitted="handleSubmit" />
    </div>
  </div>
</template>

<script setup lang="ts">
import CompanyDescriptionInput from '@components/company-description/CompanyDescriptionInput.vue'
import SpinnerComponent from '@components/spinner/SpinnerComponent.vue'
import { storeToRefs } from 'pinia'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { useCompanyStore } from '../stores/company'
import { personaPayload, usePersonaStore } from '../stores/persona'

const router = useRouter()
const isLoading = ref(false)
const section = ref('HOME')
const companySummary = ref('')
const store = usePersonaStore()
const { company } = storeToRefs(useCompanyStore())

const handleSubmit = async ({ summary, audience, usp, artStyle }: personaPayload) => {
  if (section.value === 'HOME') {
    companySummary.value = summary
    section.value = 'AUDIENCE'
  } else {
    isLoading.value = true
    await store.createPersonas({ summary: companySummary.value as string, audience, usp, artStyle })
    isLoading.value = false
    router.push({ name: 'personas', params: { id: company.value.id } })
  }
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.try {
  margin: 0 auto 6.875rem auto;
  .try-it-title {
    background-color: $black;
    padding: 48px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;

    h3 {
      margin-bottom: 0;
      color: white;
    }
  }

  .company-input {
    padding: 48px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: $mid-green;
    position: relative;
    &.AUDIENCE {
      padding: 0;
      background-color: transparent;
    }

    form {
      margin-bottom: 0;
    }
  }

  flex: 1;
  position: relative;
  max-width: 60rem;
}

:deep(input) {
  background-color: #cfffee;
}
</style>
