import axios from 'axios'
import { saveAs } from 'file-saver'

const baseUrl = import.meta.env.VITE_API_URL

export const getMyCompany = async (companyId: number) => {
  const response = await axios.get(`${baseUrl}/company/${companyId}`)
  return response.data
}

export const updateArtStyle = async (companyId: number, artStyle: string) => {
  const response = await axios.put(`${baseUrl}/company/${companyId}/art-style/${artStyle}`)
  return response.data
}

export const getAllCompanies = async (userId: number) => {
  const response = await axios.get(`${baseUrl}/company/all/${userId}`)
  return response.data
}

export const regenerateCompanySection = async (companyId: number, section: string) => {
  const response = await axios.post(`${baseUrl}/company/${companyId}/regenerate`, { section })
  return response.data
}

export const canViewFullProject = async (projectId: number) => {
  const response = await axios.get(`${baseUrl}/payment/can-view-full/${projectId}`)
  return response.data
}

export const changeCompanyName = async (projectId: number, name: string) => {
  const response = await axios.put(`${baseUrl}/company/name/${projectId}`, { name })
  return response.data
}

export const downloadProject = async (projectId: number, name: string) => {
  const response = await axios.get(`${baseUrl}/company/download/${projectId}`)
  return response.data
}

export const getSectionSummary = async (id: number, section: string) => {
  const response = await axios.get(`${baseUrl}/info/section_summary/${id}`, { params: { section } })
  return response.data
}

export const uploadCompanyLogo = async (companyId: number, formData: FormData) => {
  const response = await axios.post(`${baseUrl}/company/upload-logo/${companyId}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
  return response.data
}
