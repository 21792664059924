import axios from 'axios'

const baseUrl = import.meta.env.VITE_API_URL

export const addToMailList = async (payload: MailChimpType, token: string) => {
  const res = await axios.post(`${baseUrl}/mailchimp/add`, { ...payload, token })
  return res.data
}

export type MailChimpType = {
  email: string
  surname: string
  name: string
}
