<template>
  <div class="payment-wrapper">
    <div class="top top-borders"><h3>Payment</h3></div>
    <div class="card bottom-borders"><CardComponent /></div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import CardComponent from '@/components/payments/card-component/CardComponent.vue'

const selectedPlan = ref(JSON.parse(localStorage.getItem('selectedPlan')))
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.payment-wrapper {
  width: fit-content;

  margin: 5rem auto;
  .top {
    padding: 2rem 2.5rem;
    background-color: $black;
    color: white;

    h3 {
      margin-bottom: 0;
    }
  }

  .card {
    background-color: white;
    padding: 2rem 2.5rem;
  }
}
</style>
