import axios from 'axios'
import type { SocialPlatform, SocialPost } from '../stores/social-media'

const baseUrl = import.meta.env.VITE_API_URL

export const getCompanySocials = async (companyId: number) => {
  const response = await axios.get(`${baseUrl}/social/${companyId}`)
  return response.data
}

export const pinSocialPost = async (id: number) => {
  const response = await axios.put(`${baseUrl}/social/pin/${id}`)
  return response.data
}

export const updateCompanySocials = async ({ id, data }: { id: number; data: SocialPost[] }) => {
  const response = await axios.post(`${baseUrl}/social/${id}`, { posts: data })
  return response.data
}

export const regenerateCompanySocials = async (id: number) => {
  const response = await axios.get(`${baseUrl}/social/regenerate/${id}`)
  return response.data
}
