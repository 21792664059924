<script setup lang="ts">
const { enabled, backgroundSize } = defineProps({
  enabled: {
    type: Boolean,
    default: true
  },
  pinned: {
    type: Boolean,
    default: false
  },
  isYellow: {
    type: Boolean,
    default: false
  },
  hasBG: {
    type: Boolean,
    default: false
  },
  backgroundSize: { type: Number, default: 32 }
})

const emit = defineEmits(['pin'])
</script>

<template>
  <button :disabled="!enabled" class="pin" @click="() => emit('pin')" :class="{background: pinned && hasBG}">
    <span
      :style="{ backgroundSize: backgroundSize + 'px' }"
      class="button-icon"
      :class="{ yellow: isYellow, pinned }"
    ></span>
  </button>
</template>

<style scoped lang="scss">
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

button {
  background-color: transparent;
  border: none;
  padding: 0.5rem 0 0 0.5rem;
  width: 40px;
  height: 40px;
  margin-bottom: 0;

  &.background {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px;
    margin: 0.5rem;
  }

  @include sm {
    display: none;
  }

  .button-icon {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('@assets/icons/pin-black.svg');

    &.yellow {
      background-image: url('@assets/icons/pin-yellow.svg');
    }

    &.pinned {
      background-image: url('@assets/icons/pinned.svg');

      &.yellow {
        background-image: url('@assets/icons/pinned-yellow.svg');
      }
    }


  }

  &:hover {
    background-color: transparent;
    border: none;

    .button-icon {
      background-image: url('@assets/icons/pin_filled.svg');

      &.yellow {
        background-image: url('@assets/icons/pin-white.svg');
      }

      &.pinned {
        background-image: url('@assets/icons/pinned-yellow.svg');

        &.yellow {
          background-image: url('@assets/icons/pinned-white.svg');
        }
      }
    }
  }
}
</style>
