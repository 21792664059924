import {
  createCompanyColours,
  editCompanyColours,
  getCompanyColours,
  pinColour,
  updateCompanyColours
} from '@/services/colour-service'
import { defineStore } from 'pinia'
import { uploadCompanyLogo } from '../services/company-service'
import { useCompanyStore } from '@/stores/company'

export const useColourStore = defineStore({
  id: 'colours',
  state: (): storeInterface => ({ colours: [] }),
  actions: {
    async createColours(id: number) {
      this.colours = []
      const response = await createCompanyColours(id)
      this.colours = response.colours
    },
    async getColours(id: number) {
      const res = await getCompanyColours(id)
      if (!res) {
        this.colours = []
      } else {
        this.colours = res
      }
    },
    async updateColours(id: number, additions: string) {
      const response = await updateCompanyColours({ id, additions })
      if (response) {
        this.colours = response.colours
      } else {
        this.colours = []
      }
    },
    async toggleColourPin(id: number) {
      const res = await pinColour(id)
      await this.getColours(res.company_id)
    },
    async editColours() {
      await editCompanyColours(this.companyColours)
    },
    async updateLogo(id: number, data: FormData) {
      // this.colours = []
      const res = await uploadCompanyLogo(id, data)
      const companyStore = useCompanyStore()
      await companyStore.getCompany(id)
      this.colours = res
    },
    clearStore: () => {
      // this.colours = []
    }
  },
  getters: {
    companyColours: (state) => state.colours,
    nonLogoColours: (state) => state.colours.filter((colour) => colour.type.toLowerCase() !== 'logo'),
    primaryColours: (state) => state.colours.filter((item) => item.type.toLowerCase() === 'primary'),
    secondaryColours: (state) => state.colours.filter((item) => item.type.toLowerCase() === 'secondary'),
    signifierColours: (state) => state.colours.filter((item) => item.type.toLowerCase() === 'signifier')
  }
})

type storeInterface = {
  colours: Colour[]
}

export type Colour = {
  name: string
  hex: string
  'how-to-use'?: string
  pinned: boolean
  type: string
  id: number
}
