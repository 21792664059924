import axios from 'axios'
import type { IHomePage } from '../interfaces/strapi'

const baseUrl = import.meta.env.VITE_STRAPI_API_URL

const headers = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
  Authorization: `Bearer ${import.meta.env.VITE_STRAPI_TOKEN}`
}

export const getArtStyles = async () => {
  const response = await axios.get(`${baseUrl}/art-style?populate=art_style.Image`, { headers })
  return response.data.data.attributes.art_style
}

export const getPaidBenefits = async () => {
  const response = await axios.get(`${baseUrl}/paid-benefit?populate=*`, { headers })
  return response.data.data.attributes
}

export const getBenefits = async () => {
  const response = await axios.get(`${baseUrl}/benefit?populate=benefit.image`, { headers })
  return response.data.data.attributes
}

export const getSignupBanner = async () => {
  const response = await axios.get(`${baseUrl}/sign-up-banner`, { headers })
  return response.data.data.attributes
}

export const getPosts = async (page: number = 1) => {
  const response = await axios.get(
    `${baseUrl}/posts?populate=featured&fields[0]=title&fields[1]=description&fields[2]=slug&fields[3]=publishedAt&sort=publishedAt:desc&pagination[page]=${page}&pagination[pageSize]=12`,
    {
      headers
    }
  )

  const posts = response.data.data.map((post: any) => {
    return parseBlogPost(post)
  })

  return { posts, meta: response.data.meta.pagination }
}

export const getPost = async (slug: string) => {
  const response = await axios.get(`${baseUrl}/posts?filters[slug][$eq]=${slug}&populate=*`, {
    headers
  })

  const posts = response.data.data.map((post: any) => {
    return parseBlogPost(post)
  })

  return posts[0] as BlogPost
}

export const getFaqs = async () => {
  const response = await axios.get(`${baseUrl}/faq?populate=seo.image&populate=faqs`, {
    headers
  })

  return response.data.data.attributes
}

export const getTerms = async () => {
  const response = await axios.get(`${baseUrl}/terms-and-conditions?populate=data&populate=seo.image`, {
    headers
  })

  return response.data.data.attributes
}

export const getPrivacyPolicy = async () => {
  const response = await axios.get(`${baseUrl}/privacy-policy?populate=*`, {
    headers
  })

  return response.data.data.attributes
}

export const getCookiePolicy = async () => {
  const response = await axios.get(`${baseUrl}/cookie-policy?populate=content&populate=seo.og_image`, {
    headers
  })

  return response.data.data.attributes
}

export const getUxLinks = async () => {
  const response = await axios.get(`${baseUrl}/ux-guidance-link?populate=*`, {
    headers
  })

  return response.data.data.attributes.links as UXLink[]
}

export const getFooterLinks = async () => {
  const response = await axios.get(`${baseUrl}/footer-link?populate=*`, {
    headers
  })

  return response.data.data.attributes.link
}

export const getPainPoints = async () => {
  const response = await axios.get(`${baseUrl}/challenge?populate=*`, {
    headers
  })

  return response.data.data.attributes.challenges
}

export const getProducts = async () => {
  const response = await axios.get(`${baseUrl}/products?populate=*`, {
    headers
  })

  return response.data.data
}

export const getInsights = async () => {
  const response = await axios.get(`${baseUrl}/insigt?populate=*`, {
    headers
  })

  return response.data.data.attributes.insight
}

export const getHomePage = async (): Promise<IHomePage> => {
  const response = await axios.get(`${baseUrl}/page-home?populate=seo.og_image`, { headers })
  return response.data.data.attributes
}

export const getAboutUsPage = async () => {
  const response = await axios.get(`${baseUrl}/about-us?populate=seo.image&populate=hotspots.logo`, { headers })
  return response.data.data.attributes
}

export const getLearningCentrePage = async () => {
  const response = await axios.get(`${baseUrl}/learning-center?populate=seo.image`, { headers })
  return response.data.data.attributes
}

const parseBlogPost = (post: any) => {
  return {
    id: post?.id,
    title: post?.attributes.title,
    featured: post?.attributes?.featured?.data?.attributes?.url,
    description: post?.attributes.description,
    published: post?.attributes.publishedAt,
    slug: post?.attributes.slug,
    content: post?.attributes.content,
    blog_content: post?.attributes.blog_content
  }
}

export type BlogPost = {
  id: number;
  title: string;
  featured: string;
  description: string;
  published: string;
  slug: string;
  content: string;
  blog_content: any;
};

export type FAQ = {
  title: string;
  content: string;
  id: number;
};

export type TermsAndConditions = {
  footer: string;
  data: Term[];
};

export type Term = {
  Title: string;
  text: any;
  id: number;
};

export type UXLink = {
  name: string;
  link: string;
  section: string;
};

export type AboutUs = {
  id: number;
  title: string;
  mainbody: string;
  subheading: string;
  subcontent: string;
  hotspots: Array<AboutUsHotspot>;
};

export type AboutUsHotspot = {
  logo: string;
  body: string;
};
