import axios from 'axios';

const baseUrl = import.meta.env.VITE_API_URL;

export const getSetupIntent = async () => {
  const response = await axios.get(`${baseUrl}/payment/setup-intent`);
  return response.data;
};

export const savePaymentIntent = async (payload: { setup_intent: string }) => {
  const response = await axios.post(`${baseUrl}/payment/save-payment-method`, payload);
  return response.data;
};

export const createPaymentSession = async (payload: { type: string; plan_id: number; upgrade_id?: number }) => {
  const response = await axios.post(`${baseUrl}/payment/session/create`, payload);
  return response.data;
};

export const getPaymentSession = async () => {
  const response = await axios.get(`${baseUrl}/payment/session`);
  return response.data;
};

export const deletePaymentSession = async () => {
  const response = await axios.delete(`${baseUrl}/payment/session/destroy`);
  return response.data;
};

export const pay = async (
  priceId: number,
  type: string,
  projectId: number | undefined,
  coupon: string | null = null,
  couponType: string | null = null
) => {
  const response = await axios.post(`${baseUrl}/payment/pay`, {
    priceId,
    type,
    companyId: projectId,
    coupon,
    couponType
  });
  return response.data;
};

export const getUserSubscription = async () => {
  const response = await axios.get(`${baseUrl}/payment/subscription`);
  return response.data;
};

export const getBillingPortalUrl = async () => {
  const response = await axios.get(`${baseUrl}/payment/billing-portal`);
  return response.data;
};

export const getPrices = async () => {
  const response = await axios.get(`${baseUrl}/info/prices`);
  return response.data;
};

export const checkIfCouponIsValid = async (coupon: string) => {
  const response = await axios.post(`${baseUrl}/payment/verify-coupon`, { coupon });
  return response.data;
};

export type IntentDetails = {
  setup_intent: string | string[];
  isSubscription: boolean;
  priceId: string;
  companyId?: number;
  coupon?: string;
  couponType?: string;
};

export type CostItem = {
  id: number;
  title: string;
  descritption: string;
  price?: number;
  isSubscription?: boolean;
  period?: string;
  isBundle?: boolean;
  numberOfProjects?: number;
  type: 'PROJECT' | 'REGENERATION';
};

export type PaymentSessionData = {
  plan_id: number;
  type: string;
  upgrade_id?: number;
};
