<template>
  <div class="loader-wrapper">
    <img src="../../../assets/animations/content-spinner.gif" alt="" class="loader" />
  </div>
</template>
<script lang="ts"></script>
<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.loader-wrapper {
  padding-bottom: 110px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .loader {
    display: block;
    width: 100%;

    @include sm {
      max-width: 400px;
    }
  }
}
</style>
