<template>
  <main>
    <div class="left">
      <h2>Upload your logo</h2>
      <p class="sub-text">Upload your logo to generate brand-specific colours.</p>

      <div v-if="myCompany?.logo && !isUploading">
        <p class="lead-text">Your Logo</p>
        <img class="logo" :src="myCompany.logo" alt="" />
        <button class="secondary" @click="toggleUpload()">Upload new logo</button>
      </div>
      <div v-if="isUploading && preview">
        <p class="lead-text">Your Logo Preview</p>
        <img class="logo" :src="preview" alt="" />
      </div>
    </div>
    <div class="right" v-if="isUploading || !myCompany?.logo">
      <div v-if="!isLoading" class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
        <h3 v-if="isDragging">Release to drop files here.</h3>
        <h3 v-else>Drag & drop to upload</h3>

        <label for="fileInput" class="file-label"> Or browse </label>
        <input
          multiple
          type="file"
          name="file"
          id="fileInput"
          class="hidden-input"
          @change="onChange"
          accept=".pdf,.jpg,.jpeg,.png"
        />
      </div>

      <button v-if="!isLoading" :disabled="!file" @click="uploadLogo()" class="upload-button">Upload</button>
      <div v-if="isLoading">
        <page-blocking-loader
          title="Updating your vision"
          message="Please hang tight for just a moment; we're almost ready to impress you!"
        />
      </div>
    </div>
    <div class="colours right" v-else>
      <ColourComponent
        v-for="colour in companyColours.filter((item) => item.type.toLowerCase() === 'primary')"
        :key="colour.id"
        :colour="colour"
        :isEditing="isEditing"
        :isLogoSection="true"
      />
      <div class="empty" v-if="companyColours.filter((item) => item.type.toLowerCase() === 'primary').length < 3">
        <div class="circle" @click="addNewColor()">
          <img src="@assets/icons/cross.svg" alt="" srcset="" />
        </div>
        <p>Add new colour</p>
      </div>
    </div>
  </main>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useCompanyStore } from '../../stores/company'
import { storeToRefs } from 'pinia'
import ColourComponent from './ColourComponent.vue'
import { type Colour, useColourStore } from '../../stores/colours'
import SpinnerComponent from '../spinner/SpinnerComponent.vue'
import PageBlockingLoader from '../spinner/PageBlockingLoader.vue'

const file = ref(null)
const preview = ref(null)
const isDragging = ref(false)
const isUploading = ref(false)
const { myCompany } = storeToRefs(useCompanyStore())
const { companyColours } = storeToRefs(useColourStore())
const store = useColourStore()
const isLoading = ref(false)

const { isEditing } = defineProps({
  isEditing: {
    type: Boolean,
    default: false,
    required: true
  }
})

const toggleUpload = () => {
  isUploading.value = !isUploading.value
}
const onChange = (e) => {
  isUploading.value = true
  file.value = structuredClone(e.target.files[0])

  if (e.target.files[0]) var reader = new FileReader()
  reader.onload = (e) => {
    preview.value = e.target.result
  }
  reader.readAsDataURL(e.target.files[0])
}

const dragover = (e) => {
  e.preventDefault()
  isDragging.value = true
}

const dragleave = (e) => {
  e.preventDefault()
  isDragging.value = false
}

const drop = (e) => {
  e.preventDefault()
  isDragging.value = false
  isUploading.value = true
  file.value = structuredClone(e.dataTransfer.files[0])

  if (e.dataTransfer.files[0]) var reader = new FileReader()
  reader.onload = (e) => {
    preview.value = e.target.result
  }
  reader.readAsDataURL(e.dataTransfer.files[0])
}

const uploadLogo = async () => {
  if (!file.value) return
  try {
    isLoading.value = true
    const formData = new FormData()
    formData.append('logo', file.value, file.value.name)
    await store.updateLogo(myCompany.value.id, formData)
  } catch (error) {
    console.log(error)
  }
  isLoading.value = false
  isUploading.value = !isUploading.value
}

const emptyColour = {
  hex: '#ebe9e7',
  name: 'New Colour',
  type: 'primary',
  pinned: false,
  'how-to-use': ''
}

const addNewColor = () => {
  companyColours.value.push(emptyColour)
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

main {
  @include sm {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;

    .colours {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
    }
  }

  .left {
    @include xl {
      padding-top: 40px;
    }

    h2 {
      margin-bottom: 1rem;
    }

    .sub-text {
      margin-bottom: 3.75rem;
      font-size: 1.25rem;
    }

    max-width: 446px;
  }

  .right {
    flex: 1;

    .empty {
      border-radius: 30px;
      border: 1px dashed $grey-border;
      height: 100%;
      width: 100%;
      min-height: 250px;
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .circle {
        border-radius: 50%;
        border: 1px solid $black;
        height: 4rem;
        width: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

  .logo {
    width: 13.75rem;
    height: 13.75rem;
    margin: 1.5rem 0;
    border-radius: 30px;
    box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.06);
  }

  .upload-button {
    display: block;
    margin: 2.5rem auto;
  }

  .dropzone-container {
    background-color: white;
    border: 3px dashed $grey-border;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6.25rem 0;

    .file-label {
      cursor: pointer;
    }

    .hidden-input {
      display: none;
    }

    label {
      color: $links;
      font-size: 1rem;
    }
  }
}

.spinner-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
</style>
