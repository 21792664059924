<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { ref } from 'vue';
import { useAuthStore, User } from '../../stores/auth';
import { useForm } from 'vee-validate';
import { object, string } from 'yup';
import { useToastStore } from '../../stores/toast';

const { user, billingPortalUrl } = storeToRefs(useAuthStore());
const store = useAuthStore();
const { alert } = useToastStore();
const isEditing = ref<boolean>(false);

const schema = object({
  name: string().required(),
  surname: string().required()
});

const { values, defineInputBinds, meta, handleSubmit, errors } = useForm({
  validationSchema: schema,
  initialValues: {
    name: user.value.name,
    surname: user.value.surname
  }
});

const name = defineInputBinds('name');
const surname = defineInputBinds('surname');

const onSubmit = async () => {
  isEditing.value = !isEditing.value;
  if (!values) return;
  if (!isEditing.value) {
    try {
      await store.updateUserDetails(values as User);
    } catch (error) {
      alert(error.response.data.message, 'error');
    }
  }
};
</script>

<template>
  <div class="full-span bottom-borders top-borders">
    <div class="top settings top-borders">
      <h3>User details</h3>
    </div>
    <div class="bottom">
      <div v-if="!isEditing" class="details">
        <div class="section">
          <p class="lead-text">Full name</p>
          <p>{{ user?.name }} {{ user?.surname }}</p>
        </div>
        <div class="section">
          <p class="lead-text">Email</p>
          <p>{{ user?.email }}</p>
        </div>
      </div>
      <div v-else class="details section">
        <form>
          <div class="input-wrapper">
            <label>First name</label>
            <input type="text" placeholder="Name" v-bind="name" />
          </div>
          <div class="input-wrapper">
            <label>Surname</label>
            <input type="text" placeholder="Surname" v-bind="surname" />
          </div>
        </form>
      </div>
      <div class="buttons">
        <button @click="onSubmit()">{{ isEditing ? 'Save changes' : 'Edit' }}</button>
        <button v-if="isEditing" @click="() => (isEditing = false)" class="secondary">Cancel</button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.settings {
  background-color: $mid-green;
}
.full-span {
  width: 100%;
  background-color: white;
}

.section {
  margin-bottom: 2rem;

  .lead-text {
    font-weight: 600;
  }
}

.top {
  padding: 2rem 2.5rem;
  width: 100%;
  h3 {
    margin: 0;
  }
}

.bottom {
  padding: 2rem 2.5rem;

  .buttons {
    display: flex;
    gap: 1.5rem;
  }
}

form {
  .input-wrapper {
    margin-top: 1rem;
    input {
      background-color: $grey-bg;
      border: 1px solid $grey-5;
      &:hover {
        border: 2px solid $black !important;
      }
    }
  }
}
</style>
