<template>
  <div v-if="isLoading" class="verify-wrapper top-borders bottom-borders">
    <div class="top top-borders">
      <h3>Verifying your account</h3>
    </div>

    <div class="spinner">
      <spinner-component :height="'250'" :duration="1"></spinner-component>
    </div>
  </div>
  <div v-else class="verify-wrapper top-borders bottom-borders">
    <div class="top top-borders">
      <h3>{{ success ? 'Account verified' : 'Verification Failed' }}</h3>
    </div>

    <div class="content bottom-borders">
      <div v-if="success">
        <p>Thank you for verifying your email address. Your account is now fully activated and ready to go!</p>
        <p>
          You can log in to your account to start your project and explore all the exciting features Laiya has to offer.
          Happy exploring!
        </p>
      </div>

      <p v-else>
        We're sorry, but it seems like the verification of your email address has failed. To complete the process,
        please click the button below to try again. If you continue to experience issues, please contact our support
        team for assistance.
      </p>
      <button v-if="success" @click="backToLogin">Continue</button>
      <button v-else @click="tryAgain">Try again</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { verifyEmail } from '@/services/auth-service';
import { useRoute, useRouter } from 'vue-router';
import SpinnerComponent from '../../components/spinner/SpinnerComponent.vue';
import { useAuthStore } from '../../stores/auth';

const success = ref(false);
const isLoading = ref(true);
const summary = localStorage.getItem('summary');

const route = useRoute();
const store = useAuthStore();
const router = useRouter();

const { token } = route.params;
const backToLogin = () => {
  store.toggleLogin(true);
  if (summary) {
    router.push({ name: 'target-audience', query: { summary } });
  } else {
    router.push('/create-project');
  }
  localStorage.removeItem('summary');
};

const tryAgain = () => {
  router.push('/login');
};

onMounted(async () => {
  try {
    const res = await verifyEmail(token as string);

    if (res.status === 200) {
      store.processLogin(res.data.user, res.data.token);
      success.value = true;
    }
  } catch (error) {
    success.value = false;
  }

  isLoading.value = false;
});
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.arrow {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  justify-content: center;

  .chevron {
    height: 16px;
    width: 16px;
    margin-left: 0.5rem;
  }

  p {
    padding: 2.5rem 0 !important;
  }
}
.verify-wrapper {
  width: 100%;
  max-width: 600px;
  margin: 44px auto 6.875rem auto;
  background-color: $grey-3;
  height: wrap-content;
  .top {
    background-color: $black;
    color: white;
    padding: 3rem;

    h3 {
      margin: 0;
    }
  }

  .content {
    margin: 0;
    padding: 3rem 3rem 1.875rem 3rem;
    background-color: $grey-2;

    p {
      margin-bottom: 1rem;
    }

    button {
      margin: 40px auto;
    }
  }

  .center {
    text-align: center;
    padding: 3rem;
    cursor: pointer;
  }
}

.spinner {
  margin: 0 auto 6.875rem auto;
  width: fit-content;

  :deep(.image-wrapper) {
    margin: 0 auto;
  }
  h3 {
    text-align: center;
  }
}
</style>
