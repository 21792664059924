<script setup lang="ts">
import { useRouter } from 'vue-router'

const router = useRouter()
</script>

<template>
  <section class="page_404">
    <h2>Houston, we have a 404!</h2>
    <p>It appears you've reached the outer limits of our website.</p>
    <img src="../assets/images/character-sad.svg" alt="" />
    <button @click="() => router.push('/')">Go to homepage</button>
  </section>
</template>

<style lang="scss" scoped>
section {
  margin: 0 0 6.875rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;

  p {
    font-size: 20px;
  }

  button {
    border-radius: 220px;
  }
}
</style>
