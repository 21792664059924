<template>
  <div class="wrapper about-dark">
    <div class="heading center-text">
      <h2>{{ page.title }}</h2>
      <p>
        {{ page.mainbody }}
      </p>
    </div>

    <div class="heading small center-text">
      <h2>{{ page.subheading }}</h2>
      <p>
        {{ page.subcontent }}
      </p>
    </div>

    <div class="card-container">
      <div class="card" v-for="hotspot in page.hotspots">
        <div class="company-name-container">
          <img v-bind:src="hotspot.logo.data.attributes.url" />
        </div>
        <p v-for="content in hotspot.body">
          {{ content.children[0].text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router';
import { computed, onBeforeMount, onMounted, onUnmounted, ref } from 'vue';
import { AboutUs, getAboutUsPage, getHomePage } from '../services/strapi-service';
import { useHead } from '@vueuse/head';
import { constructHead } from '../utils/constructHead';

const route = useRoute();

const page = ref<AboutUs[]>([]);

onMounted(async () => {
  document.getElementById('parent-wrapper').classList.add('about-dark');

  const res = await getAboutUsPage();
  page.value = res;
});

onUnmounted(async () => {
  document.getElementById('parent-wrapper').classList.remove('about-dark');
});

onBeforeMount(async () => {
  const res = await getAboutUsPage();

  page.value = res;
  if (res.seo) {
    useHead(computed(() => constructHead(res.seo!)));
  }
});
</script>

<!-- Unscoped / overrides-->
<style lang="scss">
@import '@assets/scss/variables.scss';
.about-dark {
  background: $mid-black !important;
}
</style>
<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.wrapper {
  margin-top: 44px;
  min-height: 0;
  background: $mid-black !important;
}
.heading {
  max-width: 59.5rem;
  margin: 0 auto;
  color: white;
  margin-bottom: 6.875rem;

  &.small {
    max-width: 36.625rem;
    margin-bottom: 3.75rem;
  }
  &.center-text {
    text-align: center;
    h2 {
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
      font-size: 2.625rem;
    }
    p {
      font-size: 1.25rem;
    }
  }
}
.card-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 6.875rem;
  .card {
    border-radius: 1.875rem;
    background: #121212;
    padding: 3rem;
    gap: 3rem;
    width: 50%;

    &:first-of-type {
      margin-right: 1.5rem;
    }
    color: white;
    .company-name-container {
      width: 17.625rem;
      background: #242424;
      border-radius: 0.9375rem;
      padding: 2.5rem;
      gap: 1.5rem;
      height: 6.5rem;
      max-height: 6.5rem;
      margin-bottom: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      margin-bottom: 1.25rem;
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: 890px) {
  .card-container {
    display: inline-block;
  }
  .card-container .card {
    width: 100%;
    margin: 0 0 1.875rem;
    .company-name-container {
      max-width: 100%;
      img {
        max-width: 90%;
      }
    }
  }
}
</style>
