<template>
  <div class="wrapper">
    <img src="../assets/images/character.svg" alt="" class="character" />
    <div class="pricing-plans">
      <div><h2>Regenerations plans</h2></div>

      <div v-for="(plan, i) of plans" :key="i" class="card" :class="i % 2 ? '' : 'dark'">
        <div class="top top-borders">
          <h3>{{ plan.name }}</h3>
        </div>
        <div class="bottom bottom-borders">
          <h1>£{{ plan.price }}</h1>
          <button class="secondary" @click="() => validateHasPaymentMethod(plan)">Select plan</button>
        </div>
        <modal-component
          v-if="showConfirmation == plan.db_id"
          @save="selectPlan(plan)"
          @close="() => (showConfirmation = null)"
        >
          <template v-slot:title>
            <h3>Confirm purchase {{ plan.name }}</h3>
          </template>
          <template v-slot:body>
            <p>Your card ending with {{ user?.pm_last_four }} will be charged £{{ plan.price }} for {{ plan.name }}.</p>
            <p @click="useDifferentCard(plan)" class="link">Use a different card ></p>
          </template>
          <template v-if="isLoading" v-slot:footer>
            <button :disabled="isLoading" class="secondary full">Cancel</button>
            <button class="full" :disabled="isLoading">
              <span v-if="!isLoading">Confirm</span>
              <button-loader v-else />
            </button>
          </template>
        </modal-component>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { buyRegenerations, createPaymentSession, pay } from '../services/payment-service'
import ModalComponent from '../components/ui/modal-component/ModalComponent.vue'
import { useAuthStore } from '../stores/auth'
import ButtonLoader from '../components/ui/button-loader/ButtonLoader.vue'
import { storeToRefs } from 'pinia'

const showConfirmation = ref(null)
const paymentFailed = ref(null)
const showPaymentFailedModal = ref(true)

const isLoading = ref(false)
const router = useRouter()
const route = useRoute()

const { project } = route.query
const { user } = storeToRefs(useAuthStore())

const useDifferentCard = async (plan) => {
  localStorage.setItem('selectedPlan', JSON.stringify(plan))
  const payload = {
    plan_id: plan.db_id,
    type: 'regeneration',
    upgrade_id: +project
  }
  await createPaymentSession(payload)
  router.push({ name: 'payment-details' })
}

const validateHasPaymentMethod = (plan) => {


  if (user?.value?.pm_last_four) {
    showConfirmation.value = plan.db_id
  } else {
    useDifferentCard(plan)
  }
}

const selectPlan = async (plan) => {
  isLoading.value = true
  if (!project) return
  const data = await pay(plan.db_id, 'regeneration', +project)

  router.push({ name: 'payment-success', query: { id: data.data.invoice_number } })

  isLoading.value = false
}

const getImageUrl = (index: number) => {
  const name = index % 2 ? 'black' : 'yellow'
  return new URL(`../assets/icons/tick-${name.toLowerCase()}.svg`, import.meta.url).href
}
// TODO get from cms
const plans = [
  {
    name: '5 Regenerations',
    description: 'Craft a tailor-made, single project solution designed for business owners.',
    price: 10,

    db_id: 3
  },
  {
    name: '10 Regenerations',
    description: 'Empower your day-to-day workflow with the ability to create up to five projects.',
    price: 15,
    db_id: 4
  }
]

onMounted(async () => {
  await useAuthStore().refreshUser()
})

</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.wrapper {
  margin-top: 44px;
  padding-bottom: 110px;
  position: relative;
  min-height: unset;

  @include sm {
    padding-bottom: 300px;
  }

  .character {
    display: none;
    position: absolute;
    z-index: 0;
    transform: scaleX(-1);

    @include lg {
      display: block;
      height: auto;
      width: 506px;
      bottom: 15px;
      left: 7px;
    }

    @include laptop {
      width: 586px;
      left: 32px;
      bottom: -90px;
    }
  }

  .pricing-plans {
    z-index: 2;
    display: grid;
    gap: 1.5rem;
    @include sm {
      grid-template-columns: repeat(3, 1fr);
    }

    .card {
      .top {
        padding: 2rem 2.5rem;
        background-color: $grey-2;
      }

      .bottom {
        background-color: white;
        padding: 2rem 2.5rem;

        h1 {
          margin-bottom: 1rem;
        }

        button {
          margin-bottom: 2rem;
        }

        .item {
          display: flex;
          align-items: center;
          gap: 0.75rem;
        }
      }

      &.dark {
        .top {
          padding: 2rem 2.5rem;
          background-color: $black;
          color: white;
        }

        .bottom {
          background-color: $mid-black;
          color: white;
          padding: 2rem 2.5rem;

          button {
            color: $black;
            background-color: $mid-green;
          }

          .item {
            display: flex;
            align-items: center;
            gap: 0.75rem;
          }
        }
      }
    }
  }
}

.full {
  width: 100%;
}

.link {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
</style>
