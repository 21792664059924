import type { ISEO } from '../interfaces/strapi';

export const constructHead = (seoData: ISEO) => {
  return {
    title: seoData.title ?? 'Laiya',
    meta: [
      { name: 'description', content: seoData.description ?? 'Use AI to build a blueprint for your website' },
      { name: 'robots', content: seoData.robots },
      { property: 'og:title', content: seoData.og_title ?? 'Laiya' },
      { property: 'og:description', content: seoData.og_description ?? 'Use AI to build a blueprint for your website' },
      { property: 'og:image', content: seoData.og_image?.data?.attribute?.url },
      { property: 'og:url', content: seoData.og_url ?? 'https://laiya.ai/' },
      { property: 'og:site_name', content: seoData.og_site_name ?? 'Laiya' }
    ]
  };
};
