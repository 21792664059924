<template>
  <div class="faq">
    <div class="title" :class="!isOpen && 'closed'">
      <p class="lead-text">{{ faq.title }}</p>
      <div class="icon">
        <!-- <img @click="() => (isOpen = false)" v-if="isOpen" src="@assets/icons/close-yellow.svg" alt="" /> -->
        <svg
          @click="() => (isOpen = !isOpen)"
          class="hover-svg"
          width="24"
          height="25"
          viewBox="0 0 24 25"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          :class="isOpen ? 'open' : 'closed'"
        >
          <g id="Group">
            <path
              id="Vector"
              d="M13.8 3.5V10.7H21V14.3H13.8V21.5H10.2V14.3H3V10.7H10.2V3.5H13.8ZM16.8 0.5H7.2V7.7H0V17.3H7.2V24.5H16.8V17.3H24V7.7H16.8V0.5Z"
              fill="currentColor"
            />
          </g>
        </svg>
      </div>
    </div>
    <div class="content" v-if="isOpen">
      <p>{{ faq.content }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const isOpen = ref(false)
const { faq } = defineProps({
  faq: { type: Object, required: true }
})
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.faq {
  margin: 0 auto;
  margin-bottom: 1.5rem;
  max-width: 39rem;
  transition: all 0.2s ease-in-out;

  .title {
    background-color: $black;
    color: white;
    padding: 2rem 2.5rem;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    transition: all 0.2s ease-in-out;

    .icon {
      width: 20px;
      height: 20px;
    }

    svg {
      color: #5c5c5c;
      transition: all 0.2s ease-in-out;

      cursor: pointer;

      &.open {
        color: #f1fbb5;
        transform: rotate(45deg);
      }
    }

    &.closed {
      background-color: $grey-2;
      color: $black;
      border-bottom-left-radius: 30px;
      border-bottom-right-radius: 30px;
    }

    &:hover {
      background-color: $black;
      color: white;

      svg {
        color: #f1fbb5;
      }
    }

    img {
      cursor: pointer;
    }

    .lead-text {
      max-width: 500px;
    }
  }

  .content {
    background-color: white;
    padding: 2rem 2.5rem;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    transition: all 0.2s ease-in-out;
  }
}
</style>
