<template>
  <section v-if="unpinnedSocials.length" class="full-width">
    <div class="body">
      <div class="top">
        <div class="title">
          <h2>Social media content</h2>
          <p class="lead-text">Below you will find suggested media channels and marketing campaign content.</p>
        </div>
        <div class="buttons">
          <EditButtonComponent @edit="toggleEditing()" :is-editing="isEditing" />
          <RegenerateButton @regenerate="() => (showWarning = true)" :enabled="!isEditing" />
        </div>
      </div>

      <div class="platforms">
        <h3>Suggested social media</h3>
        <div class="platform-container">
          <div v-for="(platform, i) of socialPlatforms" :key="i">
            <div class="platform" v-if="platform.social_posts?.length">
              <div class="icon">
                <img :src="getImageUrl(platform.platform)" @error="missingImage" />
              </div>
              <div class="icon-name">{{ platform.platform }}</div>
            </div>
          </div>
        </div>
      </div>
      <div v-for="(platform, i) of socialPlatforms" :key="i">
        <div class="platform-details" v-if="platform?.social_posts?.length">
          <div class="platform-title">
            <h3>{{ platform.platform }} post ideas</h3>
            <div class="info-wrapper">
              <InfoComponent :top="true" :title="'Tips for ' + platform.platform">
                <template #body>{{ platform.description }}</template>
              </InfoComponent>
            </div>
          </div>

          <div class="grid">
            <ContentCard
              v-for="(item, i) of platform.social_posts"
              :key="i"
              :content="item"
              :isEditing="isEditing"
              :pinItem="pinItem"
              section="social"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <section v-if="inactive.length && !globalLoader">
    <page-blocking-loader
      title="Updating your vision"
      message="Please hang tight for just a moment; we're almost ready to impress you!"
    />
  </section>
  <regeneration-popup v-if="showWarning" @proceed="regenerate()" @close-popup="() => (showWarning = false)" />
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, onUpdated, ref } from 'vue'
import InfoComponent from '../info-component/InfoComponent.vue'
import ContentCard from '../ui/card/ContentCard.vue'
import { useSocialStore } from '../../stores/social-media'
import { useRoute } from 'vue-router'
import { storeToRefs } from 'pinia'
import RegenerationPopup from '../ui/regeneration-popup/RegenerationPopup.vue'
import EditButtonComponent from '@components/ui/edit-button/EditButtonComponent.vue'
import RegenerateButton from '@components/ui/regenerate-button/RegenerateButton.vue'
import PageBlockingLoader from '../spinner/PageBlockingLoader.vue'
import { useCompanyStore } from '../../stores/company'

const isEditing = ref(false)
const showWarning = ref<boolean>(false)
const store = useSocialStore()
const companyStore = useCompanyStore()
const { socialPlatforms, unpinnedSocials, inactive } = storeToRefs(store)
const emit = defineEmits(['disable', 'disable-initial'])
const { globalLoader } = defineProps({
  globalLoader: { type: Boolean, default: false }
})

const route = useRoute()
const { id } = route.params
let interval: any
const pinItem = (id: number) => {
  store.pinOne(id)
}

const regenerate = async () => {
  showWarning.value = false
  await store.regenerate(+id)
  await companyStore.getCompany(+id)
}

const toggleEditing = () => {
  if (isEditing.value) {
    store.updateSocialPosts(+id)
  }
  isEditing.value = !isEditing.value
}

const missingImage = (e) => {
  const url = new URL(`../../assets/icons/placeholder.svg`, import.meta.url).href
  e.target.src = url
}

const getImageUrl = (name: string) => {
  return new URL(`../../assets/icons/${name.toLowerCase()}.svg`, import.meta.url).href
}

const loadContent = async () => {
  await store.getSocialPosts(+id)
  if (inactive.value.length <= 0 && socialPlatforms.value.length) {
    clearInterval(interval)
    interval = null
    emit('disable')
  }
  emit('disable-initial')
}

onMounted(async () => {
  await loadContent()

  if (!socialPlatforms.value.length || inactive.value.length > 0) {
    if (!interval) interval = setInterval(loadContent, 3000)
  }
})

onUpdated(() => {
  if (!socialPlatforms.value.length || inactive.value.length > 0) {
    if (!interval) interval = setInterval(loadContent, 3000)
  }
})

onUnmounted(async () => {
  clearInterval(interval)
  await store.clearStore()
})
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

section {
  background: $grey-2;
  padding: 60px 0;
  margin-top: 40px;
}

.top {
  margin-bottom: 4.5rem;
  @include sm {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .buttons {
    .reg-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
      background-color: transparent;

      &:hover {
        background-color: $black;
      }

      img {
        width: 1.8rem;
        height: 1.666rem;
      }
    }

    button {
      width: 100%;

      @include sm {
        width: auto;
      }
    }

    @include sm {
      display: flex;
      gap: 1rem;
    }
  }
}

.platforms {
  margin-bottom: 4.5rem;
  @include sm {
    display: flex;
    justify-content: space-between;
    text-align: center;
  }

  .platform-container {
    display: flex;
    gap: 1.5rem;
    flex-wrap: wrap;

    .platform {
      border-radius: 30px;
      overflow: hidden;
      background-color: white;
      flex: 1;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem 35px;

        @include sm {
          padding: 2rem 70px;
        }

        img {
          height: 40px;
          width: 40px;
          @include sm {
            height: 80px;
            width: 80px;
          }
        }
      }

      .icon-name {
        padding: 0.75rem 1.25px;
        background-color: $grey-bg;
        text-align: center;
        @include sm {
          padding: 1.5rem 2.5rem;
        }
      }
    }
  }
}

.platform-details {
  margin-bottom: 20px;

  .platform-title {
    @include sm {
      display: flex;
      justify-content: space-between;
      justify-content: space-between;

      div {
        flex: 1;
      }
      h3 {
        flex: 1;
      }
    }
  }

  .info-wrapper {
    margin-bottom: 40px;
  }

  .grid {
    margin-bottom: 4.75rem;
  }
}
</style>
