<template>
  <div>
    <modal-component @close="() => emit('close')">
      <template v-slot:title>
        <p class="lead-text">Upgrade to download the PDF summary and access our full feature set</p>
      </template>
      <template v-slot:image>
        <div class="header-image">
          <img :src="benefits?.image?.data.attributes.url" alt="" srcset="" />
        </div>
      </template>
      <template v-slot:body>
        <div class="benefits">
          <p>
            {{ benefits?.Description }}
          </p>
          <div>
            <ul>
              <li v-for="(benefit, i) of benefits?.Benefits" :key="i">{{ benefit.text }}</li>
            </ul>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="close">
          <button @click="upgradeNow()">Upgrade now</button>
          <button class="secondary" @click="() => emit('close')">I'll do this later</button>
        </div>
      </template>
    </modal-component>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { getPaidBenefits } from '../../services/strapi-service';
import ModalComponent from '@components/ui/modal-component/ModalComponent.vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();
const benefits = ref(null);
const emit = defineEmits(['close']);
const { id } = route.params;

const upgradeNow = () => {
  localStorage.setItem('returnUrl', window.location.pathname);
  localStorage.setItem('upgradeId', id as string);
  router.push({ name: 'plans' });
};

onMounted(async () => {
  const res = await getPaidBenefits();
  benefits.value = res;
});
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.benefits {
  @include sm {
    padding: 0 2.5rem;
  }

  ul {
    margin-top: 1rem;
    @include sm {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 1rem;
    }
    li {
      font-size: 1rem;
      list-style-type: disc;
      margin-left: 1rem;
    }
  }
}

.close {
  width: 100%;
  display: flex;
  gap: 1.25rem;
  flex-direction: column;

  button {
    margin: 0;
    width: 100%;
  }
  @include sm {
    flex-direction: row;
    justify-content: center;
  }
}
</style>
