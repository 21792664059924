<template>
  <policies-component :data="termsAndConditions" title="Terms and conditions" />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { getTerms, type TermsAndConditions } from '../services/strapi-service';
import { useHead } from '@unhead/vue';
import { constructHead } from '../utils/constructHead';
import PoliciesComponent from '@components/ui/policies/PoliciesComponent.vue';

const termsAndConditions = ref<TermsAndConditions | null>(null);

onBeforeMount(async () => {
  const res = await getTerms();
  termsAndConditions.value = res;

  if (res.seo) {
    useHead(computed(() => constructHead(res.seo!)));
  }
});
</script>

<style lang="scss" scoped></style>
