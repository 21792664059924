<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
// Temporary this will be removed once site is live

const router = useRouter()
const password = ref('')
const unlockSite = () => {
  if (password.value !== '!2S£c1_8') {
    return
  }
  localStorage.setItem('secret', password.value)
  router.push('/')
}
</script>

<template>
  <main>
    <h2>🔒 Please enter the secret 🔒</h2>
    <div class="input-wrapper">
      <input type="password" v-model="password" />
      <button @click="unlockSite">Unlock</button>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.input-wrapper {
  max-width: 400px;
  margin: 0 auto;
}
h2 {
  margin-bottom: 0.5rem;
}
</style>
