<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { type personaPayload } from '../../stores/persona';
import { useRouter } from 'vue-router';
import LoginComponent from '@components/auth/login/LoginComponent.vue';
import RegisterComponent from '@components/auth/RegisterComponent.vue';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '../../stores/auth';

const router = useRouter();
const isLoading = ref(false);
const { showLogin, showRegister } = storeToRefs(useAuthStore());
const store = useAuthStore();

const handleSubmit = async ({ summary }: personaPayload) => {
  try {
    router.push({ name: 'target-audience', query: { summary } });
  } catch (error) {
    isLoading.value = false;
  }
};

onMounted(() => {
  store.toggleLogin(true);
});
</script>

<template>
  <main>
    <register-component v-if="showRegister" />
    <login-component v-else />
  </main>
</template>

<style lang="scss" scoped>
main {
  margin-top: 44px;
}
</style>
