<template>
  <footer>
    <BreadCrumbs />
    <div v-if="!user" class="full-width">
      <UpgradeComponent />
    </div>
    <div class="footer">
      <div class="left">
        <h3>L<span>ai</span>ya</h3>
        <p>
          5a Bartlett Street, <br />
          Bath, England, BA1 2QZ <br />
          Registered number: 15325662<br />
          <a href="mailto:hello@laiya.co.uk">hello@laiya.co.uk</a>
        </p>
      </div>
      <div class="right">
        <div class="menu">
          <router-link active-class="active" to="/plans">Pricing</router-link>
          <router-link active-class="active" to="/learning-centre">Learning Centre</router-link>
          <router-link active-class="active" to="/faqs">FAQs</router-link>
          <router-link active-class="active" v-if="user" to="/my-account">Account</router-link>
        </div>
        <button
          class="secondary"
          @click="
            () => {
              notifyMe = true;
              resetForm();
            }
          "
        >
          Sign up to our newsletter
        </button>
        <div class="social-icons">
          <a
            :href="icon.link"
            target="_blank"
            rel="noopener noreferrer"
            v-for="(icon, i) in socialIcons"
            :key="i"
            class="icon"
          ><img :src="getImageUrl(icon.icon)" :alt="icon.icon"
          /></a>
        </div>
        <FooterMenuComponent />
      </div>
      <!-- POSSIBLE COME FROM THE CMS!!! -->

      <p class="copy-right small">
        © {{ new Date().getFullYear() }} Laiya Limited. All Rights Reserved. Company No. 15325662
      </p>
    </div>
    <modal-component v-if="notifyMe" @save="saveDetails()" @close="() => (notifyMe = false)">
      <template v-slot:title>
        <h3>Sign up to our newsletter</h3>
      </template>
      <template v-slot:body>
        <form>
          <div class="input-flex">
            <div class="input-wrapper">
              <label>First name</label>
              <input
                autocomplete="off"
                type="text"
                v-model="name"
                :class="{
                  error: nameErrors?.length && name,
                  success: !nameErrors?.length && name
                }"
              />
              <div class="error" v-if="nameErrors?.length && name">
                <ul>
                  <li v-for="(error, i) of nameErrors" :key="i">
                    {{ error }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="input-wrapper">
              <label>Last name</label>
              <input
                autocomplete="off"
                type="text"
                v-model="surname"
                :class="{
                  error: surnameErrors?.length && surname,
                  success: !surnameErrors?.length && surname
                }"
              />
              <div class="error" v-if="surnameErrors?.length && surname">
                <ul>
                  <li v-for="(error, i) of surnameErrors" :key="i">
                    {{ error }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="input-wrapper">
            <label>Email</label>
            <input
              autocomplete="off"
              type="email"
              v-model="email"
              :class="{
                error: emailErrors?.length && email,
                success: !emailErrors?.length && email
              }"
            />
            <div class="error" v-if="emailErrors?.length && email">
              <ul>
                <li v-for="(error, i) of emailErrors" :key="i">
                  {{ error }}
                </li>
              </ul>
            </div>
          </div>
        </form>
      </template>
      <template v-if="isLoading" v-slot:footer>
        <button :disabled="isLoading" class="secondary full">Cancel</button>
        <button :disabled="isLoading" class="full">
          <span v-if="!isLoading">Confirm</span>
          <button-loader v-else />
        </button>
      </template>
    </modal-component>
  </footer>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useAuthStore } from '../../stores/auth'
import FooterMenuComponent from '../navigation/footer/FooterMenuComponent.vue'
import UpgradeComponent from '../payments/upgrade-component/UpgradeComponent.vue'
import BreadCrumbs from './BreadCrumbs.vue'
import { useChallengeV3 } from 'vue-recaptcha'
import { object, string } from 'yup'
import { useField, useForm } from 'vee-validate'
import { addToMailList } from '../../services/mailchimp-service'
import ModalComponent from '../ui/modal-component/ModalComponent.vue'
import { useToastStore } from '../../stores/toast'
import ButtonLoader from '../ui/button-loader/ButtonLoader.vue'

const { alert } = useToastStore()
const { execute } = useChallengeV3('submit')
const notifyMe = ref(false)
const isLoading = ref(false)
const message = ref('')

const schema = object({
  name: string().required(),
  surname: string().required(),
  email: string().email('Please enter a valid email address').required()
})

const { values, meta, resetForm } = useForm<{
  name: string;
  surname: string;
  email: string;
}>({
  validationSchema: schema
})

const { user } = storeToRefs(useAuthStore())

const getImageUrl = (name) => {
  return new URL(`../../assets/icons/${name}.svg`, import.meta.url).href
}
// FROM THE CMS
const socialIcons = [
  {
    icon: 'linkedin',
    link: 'https://www.linkedin.com/company/laiya/'
  },
  {
    icon: 'youtube',
    link: 'https://www.youtube.com/channel/UC4AKT3RNV_GAvMLyq-Dkjng'
  }
]

const { value: name, errors: nameErrors } = useField(() => 'name')
const { value: surname, errors: surnameErrors } = useField(() => 'surname')
const { value: email, errors: emailErrors, resetField } = useField(() => 'email')

const emailCopy = ref('')

const saveDetails = async () => {
  const token = await execute()
  if (!meta.value.valid) return
  isLoading.value = true
  emailCopy.value = email.value as string

  try {
    const res = await addToMailList(values, token)
    message.value = res.success
    notifyMe.value = false
    isLoading.value = false
    alert(message.value, 'success')
    resetForm()
  } catch (error) {
    message.value = error.response.data.error
    resetField({
      errors: ['The email address is already subscribed to the newsletter or invalid'],
      value: emailCopy.value,
      touched: true
    })
    alert(error.response.data.error, 'error')
  }

  isLoading.value = false
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.footer {
  color: white;
  padding: 1.5rem 1rem 4rem;
  position: relative;

  h3 {
    font-size: 2rem;

    span {
      color: $yellow;
    }
  }

  p {
    margin: 1.5rem 0;

    a {
      color: white;
    }
  }

  @include sm {
    display: flex;
    justify-content: space-between;
    padding: 2rem 0rem 6.125rem;
  }

  .left {
    margin-bottom: 2.5rem;

    p {
      font-size: 1rem;
      max-width: 350px;
    }

    @include sm {
      width: 40%;
    }
  }

  .right {
    @include sm {
      width: 50%;
      margin-left: auto;

      .menu {
        flex-direction: row;
        justify-content: end;

        a {
          color: white;
          padding: 0 1.688rem;
          background-color: transparent;
          border-radius: 0;

          &:nth-child(2n) {
            background-color: transparent;
          }
        }
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: column;

    a {
      color: white;
      padding: 2rem;
      background-color: $charcoal;

      &:nth-child(2n) {
        background-color: $midnight;
      }

      &:first-child {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
      }

      &:last-child {
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
      }

      &.active {
        font-weight: 600;
      }

      &:hover {
        font-weight: 600;
      }
    }
  }

  button {
    width: 100%;
    margin-top: 4rem;
    margin-bottom: 2rem;
    background-color: white;
    border: 2px solid transparent;

    &:hover {
      color: white;
      background-color: transparent;
      border: 2px solid white;
    }

    @include sm {
      width: fit-content;
      margin-left: auto;
      margin-bottom: 1.5rem;
      margin-top: 2rem;

      margin-right: 0;
    }
  }
}

.social-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    padding: 0.5rem;
    background: white;
    border-radius: 50%;
    cursor: pointer;

    img {
      width: 40px;
      height: 40px;
      display: block;
    }

    &:hover {
      background: $yellow;
    }
  }

  @include sm {
    justify-content: end;
    gap: 1rem;
  }
}

.full-width {
  display: block;
  background: $mid-black;
  background: linear-gradient(90deg, $mid-black 0%, $mid-black 50%, $light-black 51%, $light-black 100%);
}

.copy-right {
  @include sm {
    position: absolute;
    bottom: 10px;
  }
}

form {
  .input-flex {
    display: flex;
    flex-direction: column;
    gap: 24px;
    @include lg {
      flex-direction: row;
    }

    margin-bottom: 1rem;
  }

  .error {
    ul,
    li {
      list-style-type: none;
    }
  }
}
</style>
