<template>
  <section class="layout-section">
    <div class="layout-image">
      <img class="layout-mobile" src="@assets/images/layout-example-mobile.png" alt="" />
      <img class="layout-web" src="@assets/images/layout-example-web.png" alt="" />
    </div>
    <div class="sections">
      <div class="site-section" v-for="(section, i) in sections" :key="i">
        <h3>{{ section.title }}</h3>
        <p>{{ section.content }}</p>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { getInsights } from '../../services/strapi-service';

const sections = ref<{ title: string; content: string }[]>([]);

onMounted(async () => {
  sections.value = await getInsights();
});
</script>

<style lang="scss">
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

h3 {
  margin-bottom: 1rem;
}

.layout-section {
  margin-top: 60px;
  @include laptop {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: auto 1fr;
    column-gap: 9.118rem;
    position: relative;
  }

  .layout-image {
    width: 100vw;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 2.5rem;

    .layout-web {
      display: none;
    }
    .layout-mobile {
      display: block;
    }

    img {
      width: 100%;
    }

    @include laptop {
      width: 101%;
      margin-bottom: 0;
      order: 1;
      .layout-mobile {
        display: none;
      }
      .layout-web {
        display: block;
        width: 150%;
      }
    }

    @include xxxl {
      .layout-web {
        width: 220%;
      }
    }
    @include four-k {
      .layout-web {
        width: 250%;
      }
    }
  }

  .sections {
    @include laptop {
      display: flex;
      flex-direction: column;
    }
    .site-section {
      @include laptop {
        padding: 2.5rem 3rem;
      }
      padding: 1.75rem;
      background-color: $mid-grey;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &:nth-child(2n) {
        background-color: $dark-grey;
      }
      &:first-child {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
      }
      &:last-child {
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
      }
    }
  }
}
</style>
