<template>
  <div class="section bottom-margin">
    <div class="title top-borders" :class="activeSection.name">
      <div>
        <h3>{{ activeSection.title }}</h3>
        <p>
          {{ activeSection?.content }}
        </p>
      </div>

      <div class="link">
        <a :href="currentUXLink"> Guidance <img src="@assets/icons/chevron-right.svg" alt="" /></a>
      </div>
    </div>
    <div class="right-wrapper">
      <div class="warning" :class="activeSection.name">
        <p class="quote">{{ activeSection?.warning }}</p>
      </div>
      <div class="buttons bottom-borders" :class="activeSection.name">
        <RegenerateButton @regenerate="() => (showWarning = true)" />
        <button class="secondary" @click="scrollToRefine()">Refine</button>
        <button class="secondary" v-if="company?.has_payment" @click="goNext()">
          {{ activeSection?.name !== 'messaging' ? 'Next' : 'Back to projects' }}
        </button>
      </div>
    </div>
  </div>
  <regeneration-popup v-if="showWarning" @proceed="regenerateSection()" @close-popup="() => (showWarning = false)" />
</template>

<script setup lang="ts">
import { onMounted, ref, watch, defineEmits } from 'vue'
import { useCompanyStore } from '@/stores/company'
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { useMessagingStore } from '@stores/messaging'
import { useSocialStore } from '@stores/social-media'
import { useBlogContentStore } from '@stores/blog-content'
import { getUxLinks, type UXLink } from '@/services/strapi-service'
import RegenerateButton from '@components/ui/regenerate-button/RegenerateButton.vue'
import RegenerationPopup from '@components/ui/regeneration-popup/RegenerationPopup.vue'
import { getSectionSummary } from '../../services/company-service'

// TODO: get from CMS
const sections: Section[] = [
  {
    title: 'Audience profiling',
    name: 'personas',
    content:
      'Based on your answers we have created user profiles of your three top users. This information can be used to create a website tailored to your users and refine your marketing material.',
    warning: 'We highly advise testing these assumptions with real users.'
  },
  {
    title: 'Website structure',
    name: 'architecture',
    content:
      'Remember to ensure that the navigation is intuitive and user-friendly, allowing visitors to easily find the information they\'re looking for. Additionally, consider using clear CTAs throughout the site to guide users towards desired actions, such as booking consultations or viewing your portfolio.',
    warning:
      'This structure should provide a solid foundation for your website, tailored to your specific business and target audience.'
  },
  {
    title: 'Colour palette and styling',
    name: 'colours',
    content:
      'Remember to use these colors thoughtfully across the website. For instance, you might use softer tones like blush pink and sage green for background elements, with accents of dusty blue and mauve for buttons or highlights. Charcoal gray can be used for text, and creamy white for backgrounds to ensure readability.',
    warning: 'A well crafted colour palette contributes to a visually appealing and brand-consistent website experience'
  },
  {
    title: 'Content and messaging',
    name: 'messaging',
    content:
      'Content and messaging are key to how you reach your target audience. The message you convey should align with their motivations, needs or pain points. Try to ensure that you take a consistent approach to your social media, marketing and website content to build your brand identity.',
    warning: 'Content and messaging are key to how you reach your target audience.'
  }
]
const route = useRoute()
const router = useRouter()
const currentSection = route.name
const activeSection = ref(sections.find((section) => section.name === currentSection))
const { id } = route.params
const store = useCompanyStore()
const { user } = storeToRefs(useAuthStore())
const { canViewFullProject, company } = storeToRefs(store)
const { getSubscription } = useAuthStore()
const isPayingModalOpen = ref(false)
const emit = defineEmits(['refresh', 'loading'])
const messagingStore = useMessagingStore()
const socialStore = useSocialStore()
const uxLinks = ref<UXLink[]>(null)
const summary = ref<string | null>(null)
const currentUXLink = ref<string>('/')
const showWarning = ref(false)
const postStore = useBlogContentStore()

watch(canViewFullProject, async (newValue, oldValue) => {
  if (newValue === false) {
    isPayingModalOpen.value = true
  }
})

const goNext = () => {
  if (activeSection.value?.name !== 'messaging') {
    const i = sections.findIndex((item) => item.name === currentSection)
    const nextSection = sections[i + 1]

    router.push({ name: nextSection.name, params: { id: store?.myCompany?.id } })
  } else {
    router.push({ name: 'projects', params: { id: user.value?.id } })
  }
}

const regenerateSection = async () => {
  showWarning.value = false
  try {
    if (activeSection.value.name === 'messaging') {
      await Promise.all([
        messagingStore.regenerate(+id),
        postStore.regenerateArticles(+id),
        socialStore.regenerate(+id)
      ]).then(function(values) {
      })
    } else {
      await store.regenerateSection(+id, activeSection.value.name)
    }
    await store.getCompany(+id)
    emit('loading')
    emit('refresh')
  } catch (error) {
    console.log(error)
  }
}

const setUxLink = () => {
  if (!uxLinks.value) {
    currentUXLink.value = '/'
  } else {
    const currentLink = uxLinks.value.find((item) => item.section === activeSection.value.name)
    currentUXLink.value = currentLink?.link
  }
}

const scrollToRefine = () => {
  const el = document.getElementById('refine-section')
  el.scrollIntoView({ behavior: 'smooth' })
}

onMounted(async () => {
  summary.value = await getSectionSummary(+id, activeSection?.value?.name.toUpperCase())

  const res = await getUxLinks()
  uxLinks.value = res
  setUxLink()
})

type Section = {
  title: string;
  name: string;
  content: string;
  warning: string;
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.bottom-margin {
  margin-bottom: 40px;
}

.top-section {
  width: 100%;
  margin-bottom: 1.875rem;

  .name-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 1.25rem;
    gap: 1.25rem;
    margin-right: 1.25rem;

    .project-name {
      display: flex;
      align-items: center;
    }

    .pencil {
      padding: 20px 30px;
      border-radius: 220px;
      border: 1px solid $black;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url('@assets/icons/pencil-black.svg');
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 64px;
      width: 84px;

      &:hover {
        background-color: $black;
        background-image: url('@assets/icons/pencil.svg');
      }
    }
  }

  .regenerations {
    display: flex;
    align-items: stretch;
    margin-bottom: 1rem;

    @include xl {
      .regenerations {
        grid-row: 2;
      }
    }

    &.disabled {
      .left {
        background-color: $btn-disabled;
      }

      .right {
        background-color: $dark-grey;
      }
    }

    &.danger {
      .left {
        background-color: $dark-red;
      }

      .right {
        background-color: $light-red;
      }
    }

    &.warning {
      .left {
        background-color: $dark-orange;
      }

      .right {
        background-color: $light-orange;
      }
    }

    &.success {
      .left {
        background-color: $s-darker-green;
      }

      .right {
        background-color: $s-light-green;
      }
    }

    .left {
      h3 {
        margin: 0;
      }

      padding: 1.5rem 1.5rem 1.5rem 2.25rem;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      @include sm {
        padding: 1.25rem;
        height: 71px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      gap: 0.5rem;

      padding: 1.5rem 2.25rem 1.5rem 1.5rem;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      flex: 1;

      @include sm {
        padding: 1.25rem;
        height: 71px;
      }
    }
  }

  .buttons {
    button {
      width: fit-content;
    }

    @include xl {
      .buttons {
        grid-row: 2;

        button {
          font-size: 14px;
          margin: 0;
        }
      }
    }
  }

  @include xl {
    h2 {
      &.project-name {
        display: block;
      }
    }
  }
}

.summary {
  margin-bottom: 1.875rem;
}

.section {
  @include xl {
    display: flex;
    align-items: stretch;

    .title {
      width: 50%;

      p {
        font-size: 1rem;

        @include xl {
          font-size: 1rem;
        }
      }

      &.top-borders {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border-top-right-radius: unset;
      }

      display: flex;
      flex-direction: column;
      justify-content: space-between;


    }
    .right-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
    }
    .warning {
      border-top-right-radius: 30px;
    }

    .buttons {
      border-bottom-left-radius: unset;
      flex: 1;
    }
  }

  .title {
    padding: 1.25rem;

    @include sm {
      padding: 1.25rem 3rem;
    }

    @include xl {
      padding: 48px;
    }

    &.personas {
      background-color: $mid-green;
    }

    &.architecture {
      background-color: $mid-cyan;
    }

    &.colours {
      background-color: $mid-pink;
    }

    &.messaging {
      background-color: $mid-purple;
    }

    a {
      display: flex;
      align-items: center;

      img {
        height: 1rem;
        width: 1rem;
      }
    }
  }

  .warning {
    padding: 1.25rem;

    @include sm {
      padding: 1.25rem 3rem;
    }

    @include xl {
      padding: 48px;
    }

    &.personas {
      background-color: $dark-green;
    }

    &.architecture {
      background-color: $dark-cyan;
    }

    &.colours {
      background-color: $dark-pink;
    }

    &.messaging {
      background-color: $dark-purple;
    }

    p {
      font-size: 1.25rem;

      @include xl {
        font-size: 1.875rem;
      }
      font-weight: 300;
      line-height: 140%;
    }
  }

  .buttons {
    padding: 1.25rem;

    @include sm {
      padding: 1.25rem 3rem;
    }

    @include xl {
      padding: 48px;
    }

    &.personas {
      background-color: $light-green;
    }

    &.architecture {
      background-color: $light-cyan;
    }

    &.colours {
      background-color: $light-pink;
    }

    &.messaging {
      background-color: $light-purple;
    }

    @include sm {
      display: flex;
      gap: 1rem;
      button {
        width: fit-content;
      }
    }

    .reg-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      img {
        width: 1.8rem;
        height: 1.666rem;
      }
    }

    button {
      width: 100%;
      height: 44px;
      @include sm {
        margin: 0;
        width: fit-content;
        height: 67px;
      }

      &.transparent {
        background: transparent;
        color: $black;
      }
    }
  }
}

.navigation {
  @include xl {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    a {
      width: 100%;

      .nav-item {
        width: 100%;
      }
    }
  }
  @include xl {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 1.5rem;
  }

  .section {
    flex: 1;

    &.router-link-active,
    &:hover {
      &.personas,
      &.personas-hover {
        .nav-item {
          .left {
            background-color: $mid-green;
          }

          .right {
            background-color: $dark-green;

            p {
              font-weight: 600;
            }
          }
        }
      }

      &.architecture,
      &.architecture-hover {
        .nav-item {
          .left {
            background-color: $mid-cyan;
          }

          .right {
            background-color: $dark-cyan;

            p {
              font-weight: 600;
            }
          }
        }
      }

      &.colours,
      &.colours-hover {
        .nav-item {
          .left {
            background-color: $mid-pink;
          }

          .right {
            background-color: $dark-pink;

            p {
              font-weight: 600;
            }
          }
        }
      }

      &.messaging,
      &.messaging-hover {
        .nav-item {
          .left {
            background-color: $dark-purple;
          }

          .right {
            background-color: $light-purple;

            p {
              font-weight: 600;
            }
          }
        }
      }
    }

    .nav-item {
      display: flex;
      margin-bottom: 1.25rem;

      @include xl {
        flex-direction: column;
      }

      .left {
        padding: 0.5rem;
        display: flex;
        align-items: center;
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        background-color: $grey-2;

        @include sm {
          padding: 2rem;
        }

        @include xl {
          border-top-left-radius: 30px;
          border-bottom-left-radius: unset;
          border-top-right-radius: 30px;
        }

        img {
          width: 3.75rem;
          height: 3.75rem;

          @include xl {
            display: block;
            margin: 0 auto;
            width: 7.813rem;
            height: 7.813rem;
          }
        }
      }

      .right {
        padding: 2rem;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        background-color: $dark-grey;
        flex: 1;
        display: flex;
        align-items: center;
        text-align: center;

        p {
          font-size: 18px;
        }

        @include sm {
          padding: 2rem;
        }

        @include xl {
          border-top-right-radius: unset;
          border-bottom-right-radius: 30px;
          border-bottom-left-radius: 30px;
          p {
            text-align: center;
            width: 100%;
            font-size: 20px;
          }
        }
      }
    }
  }
}

.flex {
  @include xl {
    display: flex;
    gap: 1.25rem;
  }
}

form {
  width: 50%;
  margin: 0 auto;
  min-width: 500px;

  #name {
    width: 100%;
  }
}
</style>
