<template>
  <main v-if="!isLoading">
    <div class="heading">
      <div class="heading-content">
        <h2>My projects</h2>
        <span class="circle"></span>
        <div class="projects">
          <h4>{{ store.companies.length }}</h4>
          <p>of {{ user.max_projects ?? 1 }} projects created</p>
        </div>
      </div>

      <div>
        <button @click="byMoreProjects()" class="secondary">Buy more projects</button>
      </div>
    </div>

    <div class="projects-wrapper">
      <div v-for="(project, i) in store.companies" :key="i" class="project">
        <div class="top top-borders">
          <div class="regenerations" :class="regenState(project)"></div>
          <p>
            Regenerations left:
            <span class="semi-bold">{{ project.regenerations < 10 ? '0' : '' }}{{ project.regenerations }} </span>
          </p>
          <TooltipComponent icon="info" :is-black="false">
            <template #title>Regenerations</template>
            <template #body
            >You have {{ toWords(project.regenerations) }} regenerations available for your project. Simply click the
              regeneration button to refresh the content. If you exhaust your regenerations, don't worry, you can easily
              purchase more.
            </template>
          </TooltipComponent>
        </div>
        <div class="center">

          <router-link class="small buy" :to="'/regeneration-products?id=' + user.id + '&project=' + project.id">
            <p v-if="project.has_payment">
              Buy regenerations <img class="download" src="@assets/icons/right-arrow.svg" /></p
            ></router-link>

          <div>
            <p class="small">{{ formatDate(project.created_at) }}</p>
            <div class="name">
              <h3>{{ project.name }}</h3>
              <div
                v-if="project.has_payment"
                @click="() => selectedCompanyDownload(project)"
                class="download download-icon"
              ></div>
            </div>
          </div>
        </div>
        <div class="bottom bottom-borders">
          <router-link :to="`/personas/${project.id}`">
            <button>Go to project</button>
          </router-link>
        </div>
      </div>
      <!-- Hide if max projects reached -->
      <router-link to="/create-project" v-if="store.companies.length < user.max_projects">
        <div class="project empty">
          <div class="circle"><img src="@assets/icons/cross.svg" alt="" srcset="" /></div>
        </div>
      </router-link>
    </div>
  </main>
  <content-loader v-if="isLoading" />

  <ModalComponent v-if="isDownloadingModalOpen" @save="startDownload()" @close="() => (isDownloadingModalOpen = false)">
    <template v-slot:title>
      <h3>Downloading project</h3>
    </template>

    <template v-slot:body>
      <p class="modal-text">
        Thank you for your patience! As soon as your project is ready, we'll send it straight to your email. This
        process usually takes a few minutes.
      </p>
    </template>
  </ModalComponent>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { downloadProject } from '@/services/company-service'
import ContentLoader from '@components/ui/content-loader/ContentLoader.vue'
import { onMounted, ref } from 'vue'
import { Company, useCompanyStore } from '@/stores/company'
import ModalComponent from '@/components/ui/modal-component/ModalComponent.vue'
import TooltipComponent from '../components/ui/tooltip/TooltipComponent.vue'
import { useAuthStore } from '../stores/auth'
import { storeToRefs } from 'pinia'
import { toWords } from 'number-to-words'

const route = useRoute()
const router = useRouter()
const store = useCompanyStore()
const { companies } = storeToRefs(useCompanyStore())
const authStore = useAuthStore()
const { user } = storeToRefs(authStore)
const { id } = route.params
const isLoading = ref(false)
const isDownloadingModalOpen = ref(false)
const selectedCompany = ref<Company>(null)


const regenState = (company: Company) => {
  if (company.regenerations === 0) {
    return 'disabled'
  } else if (company.regenerations >= 1 && company.regenerations < 3) {
    return 'danger'
  } else if (company.regenerations >= 3 && company.regenerations <= 4) {
    return 'warning'
  } else {
    return 'success'
  }
}

const startDownload = async () => {
  isDownloadingModalOpen.value = false
  await downloadProject(selectedCompany.value.id, selectedCompany.value.name)
}

const selectedCompanyDownload = (project) => {
  selectedCompany.value = project
  isDownloadingModalOpen.value = true
}

const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const formatDate = (createdAt: string) => {
  const date = new Date(createdAt)

  // Get the day, month, and year
  const day = date.getDate()
  const monthIndex = date.getMonth()
  const year = date.getFullYear()

  // Format the date
  return `${day} ${monthNames[monthIndex]} ${year}`
}

const byMoreProjects = () => {
  localStorage.removeItem('upgradeId')
  router.push('/plans')
}

onMounted(async () => {
  isLoading.value = true
  await store.getAllCompanies(+id)
  await authStore.refreshUser()
  isLoading.value = false
})
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.heading {
  margin-top: 44px;
  margin-bottom: 3.75rem;
  @include sm {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .heading-content {
    @include sm {
      display: flex;
      align-items: center;

      .circle {
        border-radius: 50%;
        background-color: $black;
        height: 8px;
        width: 8px;
        margin: 1rem;
      }
    }
  }

  h2 {
    text-align: center;
    margin-right: auto;
    margin-bottom: 1rem;
    @include sm {
      margin-bottom: 0;
    }
  }

  button {
    margin: 0;
  }

  .projects {
    display: flex;
    border-radius: 15px;
    overflow: hidden;
    width: fit-content;
    height: fit-content;
    margin-bottom: 1rem;
    align-items: baseline;

    @include sm {
      margin-right: 1.5rem;
      margin-bottom: 0;
    }

    h4 {
      margin-bottom: 0;
      font-size: 24px;
      font-weight: 700;
      margin-right: 0.5rem;
    }
  }
}

.projects-wrapper {
  @include lg {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 1.5rem;
  }
  @include laptop {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  margin-bottom: 6.875rem;

  .project {
    width: 100%;
    border-radius: 30px;
    background-color: $black;
    color: white;
    margin-bottom: 1.5rem;

    .top {
      padding: 1.5rem 2.5rem;
      text-align: left;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      background-color: $light-black;
      color: white;

      p {
        margin-left: 8px;
        margin-right: auto;
      }

      .semi-bold {
        font-weight: 600;
      }

      .hov-wrapper {
        width: 24px;
        height: 24px;
      }
    }

    .center,
    .bottom {
      padding: 2rem 2.5rem;
      text-align: left;
      width: 100%;
    }

    .bottom {
      background-color: $mid-black;

      button {
        display: block;
        margin: 0 auto;
        background: transparent;
        border: 2px solid white;
        width: 100%;

        &:hover {
          color: $black;
          background-color: $mid-green;
          border: 2px solid $mid-green;
        }
      }
    }

    .center {
      height: 12.5rem;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      a {
        color: white;
      }

      .buy,
      .name {
        display: flex;
        align-items: center;
        gap: 2rem;

        h3 {
          margin-top: 8px;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 200px;
          padding: 0.2rem 0;
          @include sm {
            max-width: 400px;
          }

          @include lg {
            max-width: 300px;
          }
        }
      }

      .buy {
        p {
          display: flex;
          gap: 0.5rem;
          align-items: center;

          &:hover {
            color: $mid-green;
          }
        }
      }

      .name {
        justify-content: space-between;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .regenerations {
      height: 1rem;
      width: 1rem;
      border-radius: 50%;

      &.disabled {
        background-color: $dark-grey;
      }

      &.danger {
        background-color: $light-red;
      }

      &.warning {
        background-color: $light-orange;
      }

      &.success {
        background-color: $s-light-green;
      }
    }

    .download {
      cursor: pointer;
    }

    .download-icon {
      height: 27px;
      width: 27px;
      background-image: url('@assets/icons/download-yellow.svg');
      background-size: 27px 27px;
      background-position: 50% 50%;
      background-repeat: no-repeat;

      &:hover {
        background-image: url('@assets/icons/download-white.svg');
      }
    }

    h3 {
      color: white;
      margin-bottom: 0;
    }

    &.empty {
      background-color: $grey-bg;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 408px;
      border-radius: 30px;
      border: 1px dashed $grey-border;
      cursor: pointer;

      &:hover {
        .circle {
          background-color: $yellow;
        }
      }

      .circle {
        border: 1px solid $black;
        border-radius: 50%;
        width: 4rem;
        height: 4rem;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }
}

.modal-title {
  text-align: center;
}

.modal-text {
  margin-top: 1rem;
  margin-bottom: 4rem;
  text-align: center;
}
</style>
