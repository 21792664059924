<template>
  <div>
    <div class="bottom-section">
      <user-details-component />
      <user-password-component />
    </div>
    <div class="delete-account">
      <button @click="() => store.logout()">Log out</button>
      <button :class="!isDeleting ? 'secondary' : ''" @click="() => (showDeleteAccount = true)" :disabled="isDeleting">
        <span v-if="!isDeleting">Delete account</span>
        <div class="loader-button" v-else><button-loader></button-loader></div>
      </button>
    </div>
    <modal-component v-if="showDeleteAccount" @save="deleteAccount()" @close="() => (showDeleteAccount = false)">
      <template v-slot:title>
        <h3>Deleting account</h3>
      </template>
      <template v-slot:body>
        <p>Are you sure you want to delete your account? Once deleted it cannot be recovered.</p>
      </template>
    </modal-component>
  </div>
</template>

<script setup lang="ts">
import UserDetailsComponent from '../UserDetailsComponent.vue';
import UserPasswordComponent from '../UserPasswordComponent.vue';
import ModalComponent from '../../ui/modal-component/ModalComponent.vue';
import { ref } from 'vue';
import { useAuthStore } from '../../../stores/auth';
import { storeToRefs } from 'pinia';
import ButtonLoader from '../../ui/button-loader/ButtonLoader.vue';

const store = useAuthStore();
const { user } = storeToRefs(store);

const showDeleteAccount = ref<boolean>(false);
const isDeleting = ref<boolean>(false);

const deleteAccount = async () => {
  isDeleting.value = true;
  await store.deleteUserAccount();
  showDeleteAccount.value = false;
  isDeleting.value = false;
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.bottom-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @include lg {
    flex-direction: row;
    justify-content: space-between;
  }

  .top {
    padding: 2rem 2.5rem;
    width: 100%;
    h3 {
      margin: 0;
    }
  }

  .bottom {
    background-color: white;
    padding: 2rem 2.5rem;
  }
}

.delete-account {
  margin-top: 2.5rem;
  width: fit-content;
  margin-left: auto;
  display: flex;
  gap: 2rem;
}

.loader-button {
  min-width: 6rem;
}
</style>
