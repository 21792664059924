<template>
  <main>
    <div>
      <div class="top-section">
        <div class="name-wrapper">
          <div>
            <h2 class="project-name">{{ company?.name }}</h2>
            <p class="summary"><strong>About your organisation:</strong> {{ company?.summary }}</p>
          </div>

          <div @click="() => (showNameModal = true)" class="pencil"></div>
        </div>
        <div class="flex">
          <div class="regenerations" :class="regenState()">
            <div class="left">
              <h3>{{ company?.regenerations < 10 ? 0 : '' }}{{ company?.regenerations }}</h3>
            </div>
            <div class="right">
              <p>Regenerations left</p>
              <TooltipComponent>
                <template #title>Regenerations</template>
                <template #body
                  >You have {{ company?.regenerations ? toWords(company?.regenerations) : '' }} regenerations available
                  for your project. Simply click the regeneration button to refresh the content. If you exhaust your
                  regenerations, don't worry, you can easily purchase more.
                </template>
              </TooltipComponent>
            </div>
          </div>
          <div class="buttons">
            <button
              v-if="company?.regenerations < 2 && company?.has_payment"
              class="secondary download"
              @click="buyRegenerations()"
            >
              Buy regenerations
            </button>
          </div>
          <div class="buttons">
            <button @click="() => showDownloadPopup()" class="secondary download">Download Project PDF</button>
          </div>
        </div>
      </div>

      <div class="section-name">
        <img :id="activeSection?.name + '_image'" :src="getImageUrl(activeSection?.name, true)" />
        <h2>{{ activeSection?.title }}</h2>

        <button v-if="company?.has_payment" @click="() => goNext()">
          {{ activeSection?.name !== 'messaging' ? 'Next' : 'Back to projects' }}
        </button>
      </div>
    </div>

    <div class="navigation">
      <router-link
        custom
        v-slot="{ navigate, href, isActive }"
        v-for="(item, i) in sections"
        :key="i"
        :to="{ name: item.name, params: { id: id } }"
      >
        <a
          class="section"
          :class="[
            isActive ? item.name + ' router-link-active' : '',
            item.name + '-hover',
            company?.has_payment ? 'paid' : 'free'
          ]"
          :href="href"
          @click.prevent="handleClick(item.name, navigate)"
        >
          <div class="nav-item">
            <div class="left">
              <img :id="item.name + '_image'" :src="getImageUrl(item.name, isActive)" />
            </div>
          </div>
        </a>
      </router-link>
    </div>
  </main>

  <ModalComponent @close="() => (showNameModal = false)" @save="onSubmit" v-if="showNameModal">
    <template v-slot:title><h3>New project name</h3></template>
    <template v-slot:body>
      <form @submit="onSubmit">
        <div class="input-wrapper">
          <label>New project name</label>
          <input type="text" name="name" id="name" v-bind="name" placeholder="Project name" class="large" />
        </div>
      </form>
    </template>
  </ModalComponent>
  <ModalComponent v-if="isDownloadingModalOpen" @save="download()" @close="() => (isDownloadingModalOpen = false)">
    <template v-slot:title>
      <h3>Downloading project</h3>
    </template>

    <template v-slot:body>
      <p class="modal-text">
        Thank you for your patience! As soon as your project is ready, we'll send it straight to your email. This
        process usually takes a few minutes.
      </p>
    </template>
  </ModalComponent>
  <BenefitsPopUp v-if="showUpgradePopUp" @close="() => (showUpgradePopUp = false)"></BenefitsPopUp>
</template>

<script setup lang="ts">
import { onMounted, ref, watch, defineEmits } from 'vue';
import { useCompanyStore } from '@/stores/company';
import { useRoute, useRouter } from 'vue-router';
import { downloadProject, getSectionSummary } from '@/services/company-service';
import TooltipComponent from '@/components/ui/tooltip/TooltipComponent.vue';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import ModalComponent from '../../ui/modal-component/ModalComponent.vue';
import { useForm, useIsFormValid } from 'vee-validate';
import { object, string } from 'yup';
import { getUxLinks, type UXLink } from '@/services/strapi-service';
import { toWords } from 'number-to-words';
import BenefitsPopUp from '../../benefits-popup/BenefitsPopUp.vue';

// TODO: get from CMS
const sections: Section[] = [
  {
    title: 'Audience profiling',
    name: 'personas',
    content:
      'Based on your answers we have created user profiles of your three top users. This information can be used to create a website tailored to your users and refine your marketing material.',
    warning: 'We highly advise testing these assumptions with real users.'
  },
  {
    title: 'Website structure',
    name: 'architecture',
    content:
      "Remember to ensure that the navigation is intuitive and user-friendly, allowing visitors to easily find the information they're looking for. Additionally, consider using clear CTAs throughout the site to guide users towards desired actions, such as booking consultations or viewing your portfolio.",
    warning:
      'This structure should provide a solid foundation for your website, tailored to your specific business and target audience.'
  },
  {
    title: 'Colour palette and styling',
    name: 'colours',
    content:
      'Remember to use these colors thoughtfully across the website. For instance, you might use softer tones like blush pink and sage green for background elements, with accents of dusty blue and mauve for buttons or highlights. Charcoal gray can be used for text, and creamy white for backgrounds to ensure readability.',
    warning: 'A well crafted colour palette contributes to a visually appealing and brand-consistent website experience'
  },
  {
    title: 'Content and messaging',
    name: 'messaging',
    content:
      'Content and messaging are key to how you reach your target audience. The message you convey should align with their motivations, needs or pain points. Try to ensure that you take a consistent approach to your social media, marketing and website content to build your brand identity.',
    warning: 'Content and messaging are key to how you reach your target audience.'
  }
];
const route = useRoute();
const router = useRouter();
const currentSection = route.name;
const activeSection = ref(sections.find((section) => section.name === currentSection));
const { id } = route.params;
const store = useCompanyStore();
const { user } = storeToRefs(useAuthStore());
const { canViewFullProject, company } = storeToRefs(store);
const { getSubscription } = useAuthStore();
const isDownloadingModalOpen = ref(false);
const isPayingModalOpen = ref(false);
const emit = defineEmits(['refresh', 'loading']);

const showNameModal = ref(false);

const uxLinks = ref<UXLink[]>(null);
const summary = ref<string | null>(null);
const currentUXLink = ref<string>('/');
const showUpgradePopUp = ref(false);

const buyRegenerations = () => {
  if (!user.value || !company.value) return;
  router.push(`/regeneration-products?id=${user.value.id}&project=${company.value.id}`);
};

const schema = object({
  name: string().required()
});

const { values, defineInputBinds, handleSubmit, errors } = useForm({
  validationSchema: schema
});

const name = defineInputBinds('name');
const isFormValid = useIsFormValid();

const onSubmit = handleSubmit(async () => {
  if (!isFormValid) {
    return;
  }
  await store.changeName(+id, values.name);
  showNameModal.value = false;
});

const showDownloadPopup = () => {
  if (company.value?.has_payment) {
    isDownloadingModalOpen.value = true;
  } else {
    showUpgradePopUp.value = true;
  }
};

const goNext = () => {
  if (activeSection.value?.name !== 'messaging') {
    const i = sections.findIndex((item) => item.name === currentSection);
    const nextSection = sections[i + 1];

    router.push({ name: nextSection.name, params: { id: store?.myCompany?.id } });
  } else {
    router.push({ name: 'projects', params: { id: user.value?.id } });
  }
};

const download = async () => {
  await downloadProject(+id, store.myCompany?.name);
  isDownloadingModalOpen.value = false;
};
const handleClick = (item, navigate) => {
  if (!company.value?.has_payment) return;
  navigate();
};

watch(canViewFullProject, async (newValue, oldValue) => {
  if (newValue === false) {
    isPayingModalOpen.value = true;
  }
});

const getImageUrl = (name: string, isActive: boolean) => {
  return new URL(`../../../assets/icons/${name}${isActive ? '_active.svg' : '.svg'}`, import.meta.url).href;
};

const regenState = () => {
  if (company.value?.regenerations === 0) {
    return 'disabled';
  } else if (company.value?.regenerations >= 1 && company.value?.regenerations < 3) {
    return 'danger';
  } else if (company.value?.regenerations >= 3 && company.value?.regenerations <= 4) {
    return 'warning';
  } else {
    return 'success';
  }
};

const setUxLink = () => {
  if (!uxLinks.value) {
    currentUXLink.value = '/';
  } else {
    const currentLink = uxLinks.value.find((item) => item.section === activeSection.value.name);
    currentUXLink.value = currentLink?.link;
  }
};

onMounted(async () => {
  await store.getCompany(+id);
  await store.checkCanViewFullProject(+id);
  summary.value = await getSectionSummary(+id, activeSection.value.name.toUpperCase());

  const res = await getUxLinks();
  uxLinks.value = res;
  setUxLink();
});

type Section = {
  title: string;
  name: string;
  content: string;
  warning: string;
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

main {
  margin-top: 4px;

  @include sm {
    margin-top: 1rem;
  }
  @include xl {
    margin-top: 0;
    display: flex;
    flex-direction: column-reverse;
  }
}

.bottom-margin {
  margin-bottom: 6.875rem;
}

.summary {
  margin-top: 8px;
  font-size: 18px;
  @include sm {
    margin-top: 12px;
  }

  @include xl {
    font-size: 20px;
  }
}

.top-section {
  width: 100%;

  .name-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;
    gap: 24px;
    margin-right: 1.25rem;

    .project-name {
      display: flex;
      align-items: center;
      font-size: 32px;

      @include sm {
        font-size: 38px;
      }

      @include xl {
        font-size: 28px;
      }
    }

    .pencil {
      padding: 20px 30px;
      border-radius: 220px;
      border: 1px solid $black;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-image: url('@assets/icons/pencil-black.svg');
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      height: 44px;
      width: 100%;

      &:hover {
        background-color: $black;
        background-image: url('@assets/icons/pencil.svg');
      }
    }

    @include sm {
      flex-direction: row;

      .pencil {
        height: 64px;
        width: 84px;
      }
    }

    @include xl {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }
  }

  .regenerations {
    display: flex;
    align-items: stretch;
    margin-bottom: 1rem;

    @include xl {
      .regenerations {
        grid-row: 2;
      }
    }

    &.disabled {
      .left {
        background-color: $btn-disabled;
      }

      .right {
        background-color: $dark-grey;
      }
    }

    &.danger {
      .left {
        background-color: $dark-red;
      }

      .right {
        background-color: $light-red;
      }
    }

    &.warning {
      .left {
        background-color: $dark-orange;
      }

      .right {
        background-color: $light-orange;
      }
    }

    &.success {
      .left {
        background-color: $s-darker-green;
      }

      .right {
        background-color: $s-light-green;
      }
    }

    .left {
      h3 {
        margin: 0;
      }

      padding: 1.5rem 1.5rem 1.5rem 2.25rem;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      @include sm {
        padding: 1.25rem;
        height: 71px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      gap: 0.5rem;

      padding: 1.5rem 2.25rem 1.5rem 1.5rem;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      flex: 1;

      @include sm {
        padding: 1.25rem;
        height: 71px;
      }
    }
  }

  .buttons {
    .download {
      height: 44px;
      width: 100%;

      @include sm {
        height: 64px;
      }
    }

    button {
      width: fit-content;
    }

    @include xl {
      .buttons {
        grid-row: 2;

        button {
          font-size: 14px;
          margin: 0;
        }
      }
    }
  }

  @include xl {
    h2 {
      &.project-name {
        display: block;
      }
    }
  }
}

.section {
  .nav-item {
    .left {
      img {
        height: 40px;
        width: 40px;
      }
    }
  }
  @include xl {
    display: flex;
    align-items: stretch;

    .title {
      width: 50%;
      p {
        font-size: 1rem;

        @include xl {
          font-size: 1rem;
        }
      }

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      a {
        display: flex;
        align-items: center;
        img {
          height: 1rem;
          width: 1rem;
        }
      }
    }
    .right-wrapper {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
    }
    .warning {
      border-top-right-radius: 30px;
    }

    .buttons {
      border-bottom-left-radius: unset;
      flex: 1;
    }
  }

  .title {
    padding: 1.25rem;

    @include sm {
      padding: 1.25rem 3rem;
    }

    @include xl {
      padding: 48px;
    }

    &.personas {
      background-color: $mid-green;
    }

    &.architecture {
      background-color: $mid-cyan;
    }

    &.colours {
      background-color: $mid-pink;
    }

    &.messaging {
      background-color: $mid-purple;
    }
  }

  .warning {
    padding: 1.25rem;

    @include sm {
      padding: 1.25rem 3rem;
    }

    @include xl {
      padding: 48px;
    }

    &.personas {
      background-color: $dark-green;
    }

    &.architecture {
      background-color: $dark-cyan;
    }

    &.colours {
      background-color: $dark-pink;
    }

    &.messaging {
      background-color: $dark-purple;
    }

    p {
      font-size: 1.25rem;

      @include xl {
        font-size: 1.875rem;
      }
      font-weight: 300;
      line-height: 140%;
    }
  }

  .buttons {
    padding: 1.25rem;

    @include sm {
      padding: 1.25rem 3rem;
    }

    @include xl {
      padding: 48px;
    }

    &.personas {
      background-color: $light-green;
    }

    &.architecture {
      background-color: $light-cyan;
    }

    &.colours {
      background-color: $light-pink;
    }

    &.messaging {
      background-color: $light-purple;
    }

    @include xl {
      display: flex;
      gap: 1rem;
      button {
        width: fit-content;
      }
    }

    .reg-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.5rem;

      img {
        width: 1.8rem;
        height: 1.666rem;
      }
    }

    button {
      width: fit-content;

      @include xl {
        margin: 0;
      }

      &.transparent {
        background: transparent;
        color: $black;
      }
    }
  }
}

.navigation {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
  width: 100%;

  @include sm {
    width: 380px;
    margin: 0 auto;
  }

  @include xl {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 1.5rem;

    a {
      width: 100%;

      .nav-item {
        width: 100%;
      }
    }
  }

  .section {
    &.router-link-active,
    &:hover {
      &.personas,
      &.personas-hover {
        .nav-item {
          .left {
            background-color: $mid-green;
          }

          .right {
            background-color: $dark-green;

            p {
              font-weight: 600;
            }
          }
        }
      }

      &.architecture,
      &.architecture-hover {
        &:not(.free) {
          .nav-item {
            .left {
              background-color: $mid-cyan;
            }

            .right {
              background-color: $dark-cyan;

              p {
                font-weight: 600;
              }
            }
          }
        }
      }

      &.colours,
      &.colours-hover {
        &:not(.free) {
          .nav-item {
            .left {
              background-color: $mid-pink;
            }

            .right {
              background-color: $dark-pink;

              p {
                font-weight: 600;
              }
            }
          }
        }
      }

      &.messaging,
      &.messaging-hover {
        &:not(.free) {
          .nav-item {
            .left {
              background-color: $dark-purple;
            }

            .right {
              background-color: $light-purple;

              p {
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .nav-item {
      display: flex;
      margin-bottom: 1.25rem;

      @include xl {
        flex-direction: column;
      }

      .left {
        padding: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        background-color: $grey-2;
        width: 67px;
        height: 67px;

        @include sm {
          width: 100px;
          height: 100px;
        }

        @include xl {
          width: 80px;
          height: 80px;
          border-radius: 15px;
        }

        img {
          width: 45px;
          height: 45px;

          @include xl {
            display: block;
            margin: 0 auto;
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
}

.section-name {
  display: none;
  align-items: center;
  gap: 20px;

  h2 {
    font-size: 42px;
  }

  img {
    width: 80px;
    height: 80px;
  }
  button {
    display: block;
    margin-left: auto;
  }
  @include xl {
    display: flex;
  }
}

.flex {
  @include sm {
    display: flex;
    gap: 1.25rem;
  }
}

form {
  width: 100%;
  margin: 0 auto;
  #name {
    width: 100%;
  }
}
</style>
