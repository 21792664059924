<template>
  <section class="persona-wrapper full-width">
    <page-blocking-loader
      v-if="!persona.isActive && !globalLoader"
      title="Updating your vision"
      message="Please hang tight for just a moment; we're almost ready to impress you!"
    />
    <div class="body">
      <div class="flex">
        <div class="head">
          <div
            class="profile-image"
            :style="{ backgroundImage: `url(${persona.profile_image})`, width: width }"
            :class="persona.pinned ? 'opacity-50' : ''"
          >
            <pin-icon-component @pin="pinPersona(persona.id)" :enabled="!isEditing" :pinned="persona.pinned"
                                :hasBG="true" />
          </div>
          <div class="basic-info">
            <div class="pinned-message" v-if="persona.pinned">
              <div class="pinned-message-icon"></div>
              <p></p>
            </div>

            <h3 :class="persona.pinned ? 'opacity-50' : ''">{{ persona.nickname }}</h3>
            <p :class="persona.pinned ? 'opacity-50' : ''" class="lead-text">
              {{ persona.name }} {{ persona.surname }}
            </p>
            <ul :class="persona.pinned ? 'opacity-50' : ''">
              <li><span class="bold">Age: </span>{{ persona.age }}</li>
              <li><span class="bold">Location: </span>{{ persona.location }}</li>
              <li><span class="bold">Occupation: </span>{{ persona.occupation }}</li>
            </ul>
          </div>
        </div>
        <div class="buttons">
          <EditButtonComponent :isEditing="isEditing" @edit="edit()" :disabled="persona.pinned" />
          <tooltip-component class="tooltips">
            <template #title>Edit</template>
            <template #body>Select Edit to adjust the text for motivations, pain points, and opportunities.</template>
          </tooltip-component>
          <RegenerateButton :enabled="!isEditing && !persona.pinned" @regenerate="() => (showWarning = true)" />
          <tooltip-component class="tooltips">
            <template #title>Regenerate</template>
            <template #body
            >Selecting Regenerate will create a new version of this page. It will use a regeneration credit.
            </template>
          </tooltip-component>
          <PinButtonComponent @pin="pinPersona(persona.id)" :enabled="!isEditing" :pinned="persona.pinned" />
        </div>
      </div>
      <div class="content-wrapper">
        <PersonaContentComponent
          title="Motivations"
          :content="persona.motivations"
          :isEditing="isEditing"
          :background="background()"
          id="person-content"
          :isPinned="persona.pinned"
        />
        <PersonaContentComponent
          title="Pain points"
          :content="persona['pain-points']"
          :isEditing="isEditing"
          :background="background()"
          :isPinned="persona.pinned"
        />
        <PersonaContentComponent
          title="Opportunities"
          :content="persona.opportunities"
          :isEditing="isEditing"
          :background="background()"
          :isPinned="persona.pinned"
        />
      </div>
    </div>
  </section>
  <regeneration-popup
    v-if="showWarning"
    @proceed="regeneratePersona(persona.id)"
    @close-popup="() => (showWarning = false)"
  />
</template>

<script setup lang="ts">
import { defineProps, onMounted, ref } from 'vue'
import { usePersonaStore } from '@/stores/persona'
import PersonaContentComponent from '@components/personas/persona-content/PersonaContentComponent.vue'
import RegenerateButton from '@components/ui/regenerate-button/RegenerateButton.vue'
import EditButtonComponent from '@components/ui/edit-button/EditButtonComponent.vue'
import PinButtonComponent from '@components/ui/pin-button/PinButtonComponent.vue'
import PinIconComponent from '@components/ui/pin-icon/PinIconComponent.vue'
import PageBlockingLoader from '@components/spinner/PageBlockingLoader.vue'
import RegenerationPopup from '@components/ui/regeneration-popup/RegenerationPopup.vue'
import { useCompanyStore } from '../../stores/company'
import { useRoute } from 'vue-router'
import TooltipComponent from '../ui/tooltip/TooltipComponent.vue'

const route = useRoute()
const { id } = route.params
const store = usePersonaStore()
const companyStore = useCompanyStore()
const isEditing = ref<boolean>(false)
const showWarning = ref<boolean>(false)
const width = ref<string | undefined>('464px')

const { persona, index } = defineProps({
  persona: {
    type: Object,
    required: true
  },
  index: Number,
  globalLoader: {
    default: false,
    type: Boolean
  }
})

width.value = document.getElementById('person-content')?.offsetWidth

function observeWidth() {
  const el = document.getElementById('person-content')
  const resizeObserver = new ResizeObserver(function() {
    if (el!.offsetWidth < 500) {
      width.value = el!.offsetWidth === 0 ? '464' : el!.offsetWidth + 'px'
    } else {
      width.value = '30%'
    }
  })

  resizeObserver.observe(el!)
}

onMounted(() => {
  observeWidth()
})

const pinPersona = async (personaId: number) => {
  await store.pinSinglePersona(personaId)
}

const background = () => {
  if (index % 2 === 0) {
    return 'white'
  } else {
    return 'grey'
  }
}

const regeneratePersona = async (personaId: number) => {
  showWarning.value = false
  await store.regenerateSinglePersona(personaId)
  await companyStore.getCompany(+id)
}

const edit = async () => {
  if (isEditing.value) {
    await store.updateSinglePersona(persona as Persona)
  }
  isEditing.value = !isEditing.value
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.persona-wrapper {
  padding: 20px 1rem;

  @include sm {
    padding: 40px 0;
  }

  @include xl {
    padding: 60px 0;
  }

  .head {
    display: flex;
    background-color: $black;
    border-radius: 30px;
    color: white;
    overflow: hidden;

    .profile-image {
      width: 38%;
      overflow: hidden;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      padding: 0.25rem;

      @include sm {
        width: 30%;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
      }

      &.opacity-50 {
        opacity: 0.5;
      }
    }

    @include sm {
      background-color: transparent;
      color: $black;
    }

    .basic-info {
      padding: 1rem;

      .pinned-message {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        margin-bottom: 24px;


        p {
          padding-right: 1rem;

          &:after {
            content: 'This item has been pinned. Click icon on the top left corner to unpin.';

            @include sm {
              content: 'This item has been pinned. Click icon on the top right corner to unpin.';
            }
          }

        }

        .pinned-message-icon {
          width: 90px;
          height: 50px;
          background-image: url("@assets/icons/info.svg");
          background-size: 24px;
          background-repeat: no-repeat;
          background-position: 50% 10%;
          @include sm {
            width: 50px;
            background-image: url("@assets/icons/info-black.svg");
          }
        }
      }

      h3 {
        margin-bottom: 10px;
      }

      .lead-text {
        margin-bottom: 10px;
        font-weight: 600;
      }

      li {
        font-size: 1rem;
      }
    }
  }

  .buttons {
    height: fit-content;
    @include sm {
      display: flex;
      column-gap: 12px;
      align-items: center;
    }

    .tooltips {
      display: none;

      @include sm {
        display: block;
      }
    }
  }

  .body {
    .content-wrapper {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }

    @include lg {
      .content-wrapper {
        flex-direction: row;
      }
    }
  }

  .flex {
    @include xl {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.313rem;

      .head {
        flex: 1;
        gap: 2rem;

        .profile-image {
          min-height: 20rem;
        }

        .basic-info {
          flex: 1;

          h3 {
            font-size: 42px;
          }
        }
      }
    }
  }

  .loader-wrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 1.25rem;
    display: flex;
    align-items: center;

    @include sm {
      padding: 6.875rem 0;
    }
  }
}

.opacity-50 {
  color: $btn-disabled-text;
}
</style>
