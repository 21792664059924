<template>
  <div class="colour-wrapper bottom-borders">
    <h3 class="top-borders">
      <span v-if="!isEditing">{{ colour.name }}</span>
      <input v-else type="text" v-model="colour.name" />
      <PinIconComponent
        v-if="!isEditing"
        :pinned="!!colour.pinned"
        :enabled="!isEditing"
        @pin="togglePin()"
        :isYellow="true"
        style="display: block"
      />
      <span v-else class="pin-icon">
        <img src="@assets/icons/pencil.svg" />
      </span>
    </h3>
    <div
      class="colour"
      :style="{ backgroundColor: colour.hex }"
      :class="!isLogoSection ? '' : 'bottom-borders logo-section'"
    >
      <div class="left" :style="`background-color: ${!isLight ? 'rgba(255,255,255,0.4)' : 'rgba(18, 18, 18, 0.4)'};`">
        <p :class="!isLight ? 'white' : 'black'">{{ colour.hex }}</p>
        <div v-if="!isEditing" @click="copyToClipboard()">
          <img v-if="isLight" src="@assets/icons/copy.svg" /> <img v-else src="@assets/icons/copy-black.svg" />
        </div>
        <div v-else>
          <img class="pencil" v-if="isLight" src="@assets/icons/pencil-white.svg" />
          <img class="pencil" v-else src="@assets/icons/pencil-black.svg" />
        </div>
        <color-input
          @mounted="changeDefaultMethod"
          v-if="isEditing"
          v-model="selectedColour"
          format="hex string"
          ref="colourPicker"
          position="top"
        />
      </div>
      <div v-if="!isLogoSection" :style="`color: ${!isLight ? 'white' : '#121212'};`">
        <p class="lead-text" :class="isLight ? 'white' : 'black'">
          AA | AAA
          <TooltipComponent :icon="isLight ? 'info-black' : 'info-white'">
            <template #title>Accessibility levels</template>
            <template #body
              ><p>
                AA and AAA accessibility levels indicate the extent to which a website meets accessibility standards,
                with AA being the standard level and AAA representing a higher, more comprehensive level.
              </p>
            </template>
          </TooltipComponent>
        </p>
        <p :class="isLight ? 'white' : 'black'">AA | AAA</p>
      </div>
    </div>
    <div v-if="!isLogoSection" class="how-to-use">
      <p v-if="!isEditing" class="">{{ colour['how-to-use'] }}</p>
      <textarea
        v-else
        v-model="colour['how-to-use']"
        oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
      ></textarea>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, defineProps, ref, watch } from 'vue';
import Color from 'color';
import copy from 'copy-to-clipboard';
import { useColourStore } from '../../stores/colours';
import { watchDebounced } from '@vueuse/core';
import { useToastStore } from '@/stores/toast';
import PinIconComponent from '@components/ui/pin-icon/PinIconComponent.vue';
import TooltipComponent from '../ui/tooltip/TooltipComponent.vue';
import ColorInput from 'vue-color-input';

const { colour } = defineProps({
  colour: {
    type: Object,
    required: true
  },
  isEditing: {
    type: Boolean,
    required: true
  },
  isLogoSection: {
    type: Boolean,
    required: false,
    default: false
  }
});

const reactiveColour = computed(() => colour);

const store = useColourStore();
const { alert } = useToastStore();
const selectedColour = ref(colour.hex);
const newColour = ref(colour);
const isLight = ref(Color(colour.hex).isLight());
const colourPicker = ref(null);

const copyToClipboard = () => {
  copy(colour.hex);
  alert(colour.hex + ' has been copied', 'success');
};

watchDebounced(
  selectedColour,
  async (newValue, oldValue) => {
    isLight.value = Color(newValue).isLight();
    colour.hex = newValue;
  },
  { debounce: 500, maxWait: 1000 }
);

const togglePin = async () => {
  await store.toggleColourPin(colour.id);
};

const changeDefaultMethod = () => {
  colourPicker.value.$refs.picker.$data.textInputsFormat = 'name';
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.colour-wrapper {
  border-radius: 30px;
  background-color: white;
  height: max-content;
  margin-bottom: 2rem;
  @include lg {
    margin-bottom: 0;
  }
  h3 {
    background-color: $black;
    width: 100%;
    margin: 0;
    padding: 2rem 42px;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pin-icon {
      cursor: pointer;
    }

    input {
      color: white;
      border: 2px solid #008ae0;
      border-radius: 15px;
      padding-left: 1rem;
      font-weight: 700;
      font-size: 1.875rem;
      margin-right: 1rem;

      &:focus-visible {
        outline: none;
      }
    }
  }

  .colour {
    padding: 2rem 42px;
    display: flex;
    justify-content: space-between;
    height: 200px;

    &.logo-section {
      justify-content: center;
      align-items: flex-end;
    }

    .left {
      display: flex;
      padding: 1rem;
      border-radius: 15px;
      align-items: center;
      gap: 1rem;
      height: fit-content;
      position: relative;

      .color-input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        :deep(.box) {
          height: 100% !important;
          width: 100%;
          opacity: 0;
        }

        :deep(.picker-popup) {
          left: -4px !important;
          padding: 1rem;
          border-radius: 30px;
          background-color: $grey-bg;

          .saturation-area {
            border-radius: 15px;

            .slider-canvas {
              border-radius: 15px;
            }
          }

          .text-inputs-area {
            text-transform: uppercase;

            .text-format-arrows {
              display: none;
            }

            .text-inputs-wrapper {
              justify-content: start;

              .text-input-container {
                label {
                  display: none;
                }

                display: flex;
                flex: 1;

                input {
                  padding: 1rem;
                  font-size: 1rem;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 160%;
                  background-color: $grey-bg;
                  border: none;
                  border-radius: 15px;
                }

                #text-input-hex {
                  width: 100%;
                  display: block;
                  flex: 1;
                  text-align: left;
                }
              }
            }
          }
        }
      }

      &.white {
        background-color: white;
      }

      &.black {
        background-color: $black;
      }

      img {
        cursor: pointer;
      }
    }

    p {
      &.white {
        color: $black;
      }

      &.black {
        color: white;
      }

      &.lead-text {
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }
    }

    .pencil {
      width: 1.5rem;
      height: 1.5rem;
    }

    .colour-picker {
      background-color: white;
      opacity: 0;
      height: 0;
      width: 0;
    }
  }

  .how-to-use {
    padding: 2rem 42px;

    textarea {
      padding: 1rem;
      background-color: $mid-grey;
      border-radius: 15px;
      margin-bottom: 12px;
      width: 100%;
      height: 180px;
      resize: none;
    }
  }
}
</style>
