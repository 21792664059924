<template>
  <main>
    <SectionNavigatorPaid @refresh="refreshPage()" @loading="toggleGlobalLoader()" />
    <section v-if="!initialLoader">
      <div v-if="companyPersonas.length">
        <div class="personas">
          <PersonaComponent
            v-for="(persona, i) in companyPersonas"
            :key="persona.name"
            :persona="persona"
            :index="i"
            :global-loader="pollingLoader"
          />
          <page-blocking-loader
            v-if="pollingLoader"
            title="Updating your vision"
            message="Please hang tight for just a moment; we're almost ready to impress you!"
          />
          <!-- GLOBAL LOADER -->
        </div>
        <SelectArtStyleComponent @refresh="refreshPage()" @loading="toggleGlobalLoader()" />
        <bottom-section-component @refresh="refreshPage()" @loading="toggleGlobalLoader()" />

        <RefineResultComponent section="PERSONA" />
        <div class="spacer" v-if="!company?.has_payment"></div>
        <Teleport to=".upgrade-component-teleport">
          <div v-if="!company?.has_payment" class="upgrade full-width">
            <div class="gradient">
              <div class="character-wrapper">
                <div class="top-wrapper">
                  <div class="top-section top-borders">
                    <h2>Upgrade now</h2>
                    <p class="lead-text">
                      Upgrade now for access to our full feature set. Enhance your experience and knowledge with premium
                      features to enhance your website.
                    </p>
                  </div>
                  <div class="button bottom-borders">
                    <button @click="upgradeNow()">See deals</button>
                  </div>
                </div>
                <div class="character">
                  <img src="@assets/images/character.svg" />
                </div>
              </div>
            </div>
          </div>
        </Teleport>
      </div>
      <page-blocking-loader v-else />
      <benefits-pop-up
        @close="() => (showBenefitsPopUp = false)"
        v-if="showBenefitsPopUp && !pollingLoader && !company?.has_payment"
      ></benefits-pop-up>

      <something-exciting-pop-up
        @close="() => (showSomethingExcitingPopUp = false)"
        v-if="showSomethingExcitingPopUp && !showBenefitsPopUp && !pollingLoader && !company?.has_payment"
      ></something-exciting-pop-up>
    </section>
    <section v-else>
      <content-loader />
    </section>
  </main>
</template>

<script setup lang="ts">
import PersonaComponent from '@components/personas/PersonaComponent.vue'
import SectionNavigator from '@components/navigation/sections/SectionNavigator.vue'
import SectionNavigatorPaid from '@components/navigation/sections/SectionNavigatorPaid.vue'
import RefineResultComponent from '@/components/refine-result/RefineResultComponent.vue'
import SelectArtStyleComponent from '@components/personas/select-art-style/SelectArtStyleComponent.vue'
import { useRoute, useRouter } from 'vue-router'
import { usePersonaStore } from '@/stores/persona'
import { useCompanyStore } from '@/stores/company'
import { onMounted, onUnmounted, onUpdated, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useConfigStore } from '@/stores/config'
import PageBlockingLoader from '@components/spinner/PageBlockingLoader.vue'
import { getPaidBenefits } from '../services/strapi-service'
import BenefitsPopUp from '../components/benefits-popup/BenefitsPopUp.vue'
import SomethingExcitingPopUp from '../components/something-exciting-popup/SomethingExcitingPopUp.vue'
import ContentLoader from '../components/ui/content-loader/ContentLoader.vue'
import BottomSectionComponent from '@components/section-bottom/BottomSectionComponent.vue'
import { useAuthStore } from '@/stores/auth'

const route = useRoute()
const router = useRouter()
const store = usePersonaStore()
const authStore = useAuthStore()
const { personaLimit } = storeToRefs(useConfigStore())
const { companyPersonas } = storeToRefs(usePersonaStore())
const companyStore = useCompanyStore()
const { company } = storeToRefs(companyStore)
const { id } = route.params
const benefits = ref(null)
const pollingLoader = ref(false)
const initialLoader = ref(true)
const showBenefitsPopUp = ref(false)
const showSomethingExcitingPopUp = ref(false)
const timeOutId = ref(0)
const { user } = storeToRefs(authStore)
let interval: any

const upgradeNow = () => {
  localStorage.setItem('returnUrl', window.location.pathname)
  localStorage.setItem('upgradeId', id as string)
  router.push({ name: 'plans' })
}

window.addEventListener('scroll', () => {
  if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
    clearTimeout(timeOutId.value)
    showBenefitsPopUp.value = true
  }
})

const toggleGlobalLoader = () => {
  pollingLoader.value = !pollingLoader.value
}

const refreshPage = async () => {
  await store.getPersonas(+id)
  initialLoader.value = false
  const res = await getPaidBenefits()
  benefits.value = res

  if (
    companyPersonas.value.length &&
    companyPersonas.value.filter((persona) => persona.isActive).length >= personaLimit.value
  ) {
    clearInterval(interval)
    interval = null
    pollingLoader.value = false
    if (localStorage.getItem(`shownSomethingExcitingPopup-${user.value.id}`) !== `shown-${user.value.id}`) {
      showSomethingExcitingPopUp.value = true
      localStorage.setItem(`shownSomethingExcitingPopup-${user.value.id}`, `shown-${user.value.id}`)
    }

    timeOutId.value = setTimeout(() => {
      showBenefitsPopUp.value = true
    }, 60000)
  }
}

onMounted(async () => {
  await refreshPage()

  if (
    !companyPersonas.value.length ||
    (companyPersonas.value.filter((persona) => persona.isActive).length < personaLimit.value && !interval)
  ) {
    if (!interval) interval = setInterval(refreshPage, 3000)
  }
})

onUpdated(() => {
  if (
    !companyPersonas.value.length ||
    (companyPersonas.value.filter((persona) => persona.isActive).length < personaLimit.value && !interval)
  ) {
    if (!interval) interval = setInterval(refreshPage, 3000)
  }
})

onUnmounted(async () => {
  clearInterval(interval)
  await store.clearPersonas()
})
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.spacer {
  height: 50px;

  @include sm {
    height: 400px;
  }
}

main {
  .upgrade {
    position: absolute;
    bottom: 0;
  }

  .top {
    margin: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .buttons {
      display: flex;
      column-gap: 1rem;
    }
  }

  .personas {
    margin-top: 20px;
    position: relative;
    margin-bottom: 60px;

    @include sm {
      margin-top: 40px;
    }

    .global-loader-wrapper {
      position: absolute;
      top: 0;
      height: 100%;
      z-index: 3;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 1.25rem;
      display: flex;
      align-items: center;
      border-radius: 30px;

      @include sm {
        padding: 6.875rem 0;
        border-radius: 120px;
      }
    }
  }

  .personas > :nth-child(odd) {
    background-color: $grey-2;
  }

  button {
    margin: 0;
    width: fit-content;
    padding: 0.5rem 1rem;
  }

  .place-holder-wrapper {
    margin: 110px 0;
  }
}

.gradient {
  z-index: 3;
  background: linear-gradient(180deg, transparent 0%, rgba(249, 249, 249, 0.3) 6%, #f9f9f9 76.12%);

  .character-wrapper {
    width: fit-content;
    padding: 0 1rem;
    padding-top: 22.35rem;

    @include sm {
      display: flex;
      margin: 0 auto;
    }

    .top-wrapper {
      max-width: 49rem;
    }

    .top-section {
      background-color: $dark-green;
      padding: 4rem;
    }

    .button {
      background-color: $mid-green;
      padding: 4rem;

      button {
        margin: 0;
      }
    }

    .character {
      display: none;
      @include md {
        display: block;

        img {
          display: block;
          transform: scale(0.9);
          margin-left: -6rem;
          margin-top: -1rem;
        }
      }
    }
  }
}

.header-image {
  background-color: $black;
  position: relative;

  img {
    width: 100%;
    display: block;
  }
}
</style>
