<template>
  <nav>
    <div class="mobile-header">
      <div class="logo">
        <router-link to="/">
          <h2>L<span class="ai">ai</span>ya</h2>
        </router-link>
      </div>
      <div class="mobile-icon">
        <img v-if="user" class="icon" src="@assets/icons/user.svg" alt="" @click="goToAccount()" />
        <img v-if="!showMenu" class="icon" src="@assets/icons/menu.svg" alt="" @click="toggleMenu" />
        <img v-else class="icon" src="@assets/icons/close-black.svg" alt="" @click="toggleMenu" />
      </div>
    </div>

    <div class="nav" v-if="showMenu">
      <router-link active-class="active" v-if="user" :to="`/projects/${user?.id}`"> My Projects</router-link>

      <router-link active-class="active" to="/plans" v-if="!user"> Pricing</router-link>
      <router-link active-class="active" to="/learning-centre"> Learning Centre</router-link>
      <router-link active-class="active" to="/faqs"> FAQs</router-link>
      <router-link active-class="active" to="/about"> About</router-link>
      <router-link active-class="active" v-if="!user" to="/login">Login</router-link>
      <div v-if="user" @click="goToAccount()" class="account"></div>
    </div>
  </nav>
</template>

<script setup lang="ts">
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { onMounted, ref, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';

const store = useAuthStore();
const router = useRouter();
const { user, billingPortalUrl } = storeToRefs(store);

const showMenu = ref(false);
const minWidth = 1440;

const goToAccount = () => {
  router.push('/my-account');
};

const toggleMenu = (e) => {
  if (window.innerWidth > minWidth) return;
  showMenu.value = !showMenu.value;
  e.stopPropagation();
};

const close = (e) => {
  if (window.innerWidth > minWidth) return;
  if (showMenu.value === true) {
    showMenu.value = false;
  }
};

const checkScreenSize = () => {
  if (window.innerWidth > minWidth) {
    showMenu.value = true;
  } else {
    showMenu.value = false;
  }
};

onMounted(async () => {
  if (window.innerWidth > minWidth) {
    showMenu.value = true;
  }
  document.addEventListener('click', close);
  window.addEventListener('resize', checkScreenSize);
});

onUnmounted(() => {
  document.removeEventListener('click', close);
});
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

nav {
  overflow: hidden;
  margin-top: 1rem;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.06);
  transition: all 0.2s ease-in-out;
  border-radius: 30px;

  @include laptop {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .nav {
    margin-top: 1rem;
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    z-index: 9999;
    background: white;
    padding: 12px 0;
    border-radius: 30px;
    flex: 1;

    a,
    p {
      transition: all 0.2s ease-in-out;
      color: $black;
      padding: 0.688rem 1.75rem;
      font-weight: 400;
      font-size: 0.875rem;
      cursor: pointer;

      &:first-child {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
      }

      &:last-child {
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
      }

      &.active {
        font-weight: 600;
      }

      &:hover {
        font-weight: 600;
      }
    }

    @include laptop {
      display: flex;
      flex-direction: row;
      gap: 2rem;
      position: relative;
      width: auto;
      padding: 2.5rem 48px;
      margin: 0;
      justify-content: end;
      border-radius: 0;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;

      a,
      p {
        padding: 0;
        background: none;
        color: $black;
        border: none;
        background-color: transparent !important;
        font-size: 1rem;
        display: flex;
        align-items: center;
      }
      ul,
      .flex {
        display: flex;
        gap: 1rem;
      }
    }

    .account {
      cursor: pointer;
      height: 24px;
      width: 24px;
      background-image: url('@assets/icons/user-black.svg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position-y: 2px;
      display: none;
      @include laptop {
        display: block;
      }

      &:hover {
        background-image: url('@assets/icons/user-black-fill.svg');
      }
    }
  }

  h2 {
    margin-bottom: 0;
  }

  .mobile-header {
    display: flex;
    justify-content: space-between;
    align-content: center;

    .logo {
      border-top-left-radius: 30px;
      border-bottom-left-radius: 30px;
      padding: 2.5rem 3rem;
      h2 {
        font-size: 32px;
      }
    }

    .mobile-icon {
      align-items: center;
      display: flex;
      padding: 1.5rem 2rem;
      background-color: white;
      flex: 1;
      justify-content: end;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;

      :nth-child(2) {
        margin-left: 1.5rem;
      }

      @include laptop {
        display: none;
      }

      .icon {
        max-width: 24px;
        max-height: 24px;
      }
    }
  }

  .logo {
    background-color: $black;
    padding: 1.5rem 2rem;

    a {
      h2 {
        color: white;
      }
    }
  }

  .ai {
    color: $yellow;
  }
}
</style>
