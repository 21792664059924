import axios from 'axios'
import type { Colour } from '../stores/colours'

const baseUrl = import.meta.env.VITE_API_URL

export const getCompanyColours = async (companyId: number) => {
  const response = await axios.get(`${baseUrl}/colour-scheme/${companyId}`)
  return response.data
}

export const createCompanyColours = async (companyId: number) => {
  const response = await axios.post(`${baseUrl}/colour-scheme/${companyId}`)
  return response.data
}

export const updateCompanyColours = async ({ id, additions }: { id: number; additions: string }) => {
  const response = await axios.put(`${baseUrl}/colour-scheme/${id}`, { additions })
  return response.data
}

export const pinColour = async (id: number) => {
  const response = await axios.put(`${baseUrl}/colour-scheme/pin/${id}`)
  return response.data
}

export const editCompanyColours = async (payload: Colour[]) => {
  const response = await axios.put(`${baseUrl}/colour-scheme/edit`, { payload })
  return response.data
}
