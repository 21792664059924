<template>
  <section class="example-section">
    <div class="text">
      <h2>Download a free example</h2>
      <p class="lead-text">
        Curious about what Laiya can do for you? Get a taste of our powerful AI technology with a free example PDF
        download.
      </p>
      <button @click="downloadProject()" class="web">Download now</button>
    </div>

    <div class="example">
      <img src="@assets/images/example-download.png" alt="play button" />
    </div>
    <button @click="downloadProject()" class="mobile">Download now</button>
  </section>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';

const router = useRouter();
const downloadProject = () => {
  router.push('/free-project');
};
</script>

<style lang="scss">
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.example-section {
  margin-bottom: 60px;
  h2 {
    margin-bottom: 0.75rem;
    @include laptop {
      margin-top: 40px;
    }
  }
  @include laptop {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr;
    gap: 1rem;

    .text {
      padding-right: 4rem;
    }
  }
  button {
    &.web {
      display: none;
      margin: 3rem 0;

      @include laptop {
        display: block;
      }
    }

    &.mobile {
      display: block;
      width: 100%;
      margin: 3rem 0;

      @include laptop {
        display: none;
      }
    }
  }
  .example {
    background-color: $black;
    border-radius: 30px;
    @include laptop {
      padding: 6.125rem;
    }
    padding: 1.125rem 3.064rem;
    margin-top: 2rem;
    @include laptop {
      margin-top: 0;
    }
    img {
      width: 100%;
    }
  }
}
</style>
