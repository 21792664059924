import axios from 'axios';
import type { Article } from '../stores/blog-content';
import type { Messaging } from '../stores/messaging';

const baseUrl = import.meta.env.VITE_API_URL;

export const getCompanyMessaging = async (companyId: number) => {
  const response = await axios.get(`${baseUrl}/messaging/${companyId}`);
  return response.data;
};

export const pinMessaging = async (id: number) => {
  const response = await axios.put(`${baseUrl}/messaging/pin/${id}`);
  return response.data;
};

export const updateCompanyMessaging = async ({ id, messaging }: { id: number; messaging: Messaging[] }) => {
  const response = await axios.put(`${baseUrl}/messaging/${id}`, { messaging });
  return response.data;
};
export const regenerateCompanyMessaging = async (id: number) => {
  const response = await axios.get(`${baseUrl}/messaging/regenerate/${id}`);
  return response.data;
};

export const getBlogContent = async (id: number) => {
  const response = await axios.get(`${baseUrl}/blog/${id}`);
  return response.data;
};

export const updateAllArticles = async (id: number, data: Article[]) => {
  const response = await axios.put(`${baseUrl}/blog/${id}/update-all`, { articles: data });
  return response.data;
};

export const regenerateAllArticles = async (id: number) => {
  const response = await axios.get(`${baseUrl}/blog/regenerate/${id}`);
  return response.data;
};

export const pinArticle = async (id: number) => {
  const response = await axios.put(`${baseUrl}/blog/pin/${id}`);
  return response.data;
};

export const refineAllContent = async (id: number, additions: string) => {
  const response = await axios.put(`${baseUrl}/content/refine/${id}`, { additions });
  return response.data;
};
