<template>
  <main>
    <h2 class="main-title">FAQs</h2>
    <FAQComponent v-for="faq of FAQs" :faq="faq" :key="faq.id" />
  </main>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { FAQ, getFaqs } from '../services/strapi-service';
import FAQComponent from '@components/faqs/FAQComponent.vue';
import { useHead } from '@unhead/vue';
import { constructHead } from '../utils/constructHead';

const FAQs = ref<FAQ[]>([]);

onBeforeMount(async () => {
  const res = await getFaqs();
  FAQs.value = res.faqs;

  if (res.seo) {
    useHead(computed(() => constructHead(res.seo!)));
  }
});
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.main-title {
  text-align: center;
  width: 100%;
  margin-bottom: 3.75rem;
}
.faq {
  margin: 0 auto;
  margin-bottom: 1.5rem;
  max-width: 39rem;
  .title {
    background-color: $black;
    color: white;
    padding: 2rem 2.5rem;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    display: flex;
    justify-content: space-between;

    img {
      cursor: pointer;
    }
  }

  .content {
    background-color: white;
    padding: 2rem 2.5rem;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
}
</style>
