import axios from 'axios'

const baseUrl = import.meta.env.VITE_API_URL

export const redirectToGoogle = async () => {
  const response = await axios.get(`${baseUrl}/auth/google/redirect`)
  return response.data
}

export const googleCallback = async (code: string) => {
  const response = await axios.get(`${baseUrl}/auth/google/callback`, { params: { code } })
  return response.data
}
