import { defineStore } from 'pinia'
import type { Message } from 'yup'
import { getCompanySocials } from '@/services/social-posts-service'

export const useToastStore = defineStore({
  id: 'toast',
  state: (): StoreInterface => ({
    toastIsOpen: false,
    message: '',
    messageType: 'info'
  }),
  actions: {
    alert(message: string, type: 'info' | 'error' | 'warning' | 'success' = 'info') {
      this.toastIsOpen = true
      this.message = message
      this.messageType = type

      setTimeout(() => {
        this.toastIsOpen = false
        this.message = ''
      }, 3000)
    }
  }
})

interface StoreInterface {
  toastIsOpen: boolean
  message: string
  messageType: 'info' | 'error' | 'warning' | 'success'
}
