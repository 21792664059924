<template>
  <SectionNavigatorPaid @refresh="refreshPage()" @loading="() => (isLoading = true)" />
  <section v-if="!initialLoader">
    <div class="logo-component">
      <LogoComponent :is-editing="isEditing" />
    </div>
    <div class="full-width" v-if="companyColours?.filter((item) => item.type.toLowerCase() !== 'logo').length">
      <div class="colours body">
        <div class="top">
          <div class="title">
            <h2>Your selected colours</h2>
            <p class="lead-text">Our selection is below, you can replace any of these by selecting the picker.</p>
          </div>
          <div class="buttons">
            <EditButtonComponent @edit="toggleSave()" :isEditing="isEditing" />
            <RegenerateButton @regenerate="() => (showWarning = true)" :enabled="!isEditing" />
          </div>
        </div>

        <h3>Primary</h3>
        <div class="colours-sections">
          <ColourComponent v-for="colour in primaryColours" :key="colour.id" :colour="colour" :isEditing="isEditing" />
        </div>
        <h3>Secondary</h3>
        <div class="colours-sections">
          <ColourComponent
            v-for="colour in secondaryColours"
            :key="colour.id"
            :colour="colour"
            :isEditing="isEditing"
          />
        </div>
        <h3>Signifiers</h3>
        <div class="colours-sections">
          <ColourComponent
            v-for="colour in signifierColours"
            :key="colour.id"
            :colour="colour"
            :isEditing="isEditing"
          />
        </div>
      </div>
    </div>
    <div v-else><page-blocking-loader :title="title" :message="message" /></div>
    <bottom-section-component @refresh="refreshPage()" @loading="() => (isLoading = true)" />
    <RefineResultComponent section="COLOURS" />
    <regeneration-popup v-if="showWarning" @proceed="regenerateSection()" @close-popup="() => (showWarning = false)" />
  </section>
  <section v-else><content-loader /></section>
</template>

<script setup lang="ts">
import ColourComponent from '@components/colours/ColourComponent.vue';
import RefineResultComponent from '@/components/refine-result/RefineResultComponent.vue';
import SectionNavigatorPaid from '@components/navigation/sections/SectionNavigatorPaid.vue';
import LogoComponent from '@components/colours/LogoComponent.vue';
import { useColourStore } from '../stores/colours';
import { useRoute } from 'vue-router';
import { onMounted, onUnmounted, onUpdated, ref } from 'vue';
import { useCompanyStore } from '../stores/company';
import { storeToRefs } from 'pinia';
import RegenerationPopup from '../components/ui/regeneration-popup/RegenerationPopup.vue';
import { useToastStore } from '@/stores/toast';
import EditButtonComponent from '@components/ui/edit-button/EditButtonComponent.vue';
import RegenerateButton from '@components/ui/regenerate-button/RegenerateButton.vue';
import PageBlockingLoader from '../components/spinner/PageBlockingLoader.vue';
import ContentLoader from '@components/ui/content-loader/ContentLoader.vue';
import BottomSectionComponent from '../components/section-bottom/BottomSectionComponent.vue';

const store = useColourStore();
const { companyColours, nonLogoColours, primaryColours, secondaryColours, signifierColours } = storeToRefs(
  useColourStore()
);
const companyStore = useCompanyStore();
const route = useRoute();
const { id } = route.params;
const initialLoader = ref<boolean>(true);
const isLoading = ref<boolean>(false);
const isEditing = ref<boolean>(false);
const showWarning = ref<boolean>(false);

const { alert } = useToastStore();
let interval;
const title = ref<string>('Creating your vision');
const message = ref<string>("Please bear with us for a moment; <br/> we're almost ready to dazzle you!");

const refreshPage = async () => {
  await store.getColours(+id);
  initialLoader.value = false;
  if (nonLogoColours.value.length > 3) {
    clearInterval(interval);
    interval = null;
    await companyStore.getCompany(+id);
  }
};
const toggleSave = async () => {
  isEditing.value = !isEditing.value;
  if (isEditing.value) return;
  try {
    await store.editColours();
    alert('Colours saved', 'success');
  } catch (error) {
    alert('Failed to save colours', 'error');
  }
};

const regenerateSection = async () => {
  showWarning.value = false;
  title.value = 'Updating your vision';
  message.value = "Please hang tight for just a moment; we're almost ready to impress you!";
  try {
    await companyStore.regenerateSection(+id, 'colours');
    await refreshPage();
  } catch (error) {
    isLoading.value = false;
  }
};

onMounted(async () => {
  try {
    await refreshPage();
    if (nonLogoColours.value.length < 3) {
      if (!interval) interval = setInterval(refreshPage, 3000);
    }
  } catch (error) {
    isLoading.value = false;
  }
});

onUpdated(() => {
  if (nonLogoColours.value.length < 3) {
    if (!interval) interval = setInterval(refreshPage, 3000);
  }
});

onUnmounted(() => {
  store.clearStore();
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/assets/scss/mixins.scss';

.logo-component {
  margin: 20px;
}

.placeholder-wrapper {
  margin-bottom: 110px;
}

.full-width {
  margin-top: 7.063rem;
  margin-bottom: 40px;
  background-color: $mid-grey;
  padding: 60px 0;

  .top {
    @include sm {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .buttons {
      .reg-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        background-color: transparent;

        &:hover {
          background-color: $black;
        }

        img {
          width: 1.8rem;
          height: 1.666rem;
        }
      }

      button {
        width: 100%;
        margin-top: 0;

        @include sm {
          width: auto;
        }
      }

      @include sm {
        display: flex;
        gap: 1rem;
      }
    }
  }

  h3 {
    margin-top: 3.75rem;
    margin-bottom: 1.875rem;
  }

  .colours-sections {
    @include sm {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.5rem;
    }

    @include lg {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
</style>
