import {
  createCompanyPersonas,
  getCompanyPersonas,
  pinPersona,
  regenerateSinglePersona,
  updateCompanyPersonas,
  updateSinglePersona
} from '@/services/persona-service'
import { defineStore } from 'pinia'
import { useCompanyStore } from './company'

export const usePersonaStore = defineStore({
  id: 'personas',
  state: (): storeInterface => ({ personas: [] }),
  actions: {
    async createPersonas(payload: personaPayload) {
      this.personas = []

      const response = await createCompanyPersonas(payload)
      const companyStore = useCompanyStore()
      const company = response

      await companyStore.setCompany(company)
      return response
    },
    async getPersonas(id: number, refresh: boolean = false) {
      const response = await getCompanyPersonas(id)
      if (refresh || arePersonasDifferent(response.personas, this.personas)) {
        this.personas = response.personas
      }
    },
    async refinePersonas(id: number, additions: string) {
      this.personas = await updateCompanyPersonas({ id, additions })
    },
    async pinSinglePersona(id: number) {
      const { persona } = await pinPersona(id)

      this.personas = this.personas.map((oldPersona) => {
        if (oldPersona.id === persona.id) {
          return { ...oldPersona, pinned: persona.pinned }
        } else {
          return oldPersona
        }
      })
    },
    async regenerateSinglePersona(id: number) {
      const res = await regenerateSinglePersona(id)

      if (arePersonasDifferent(res, this.personas)) {
        this.personas = res
      }
    },
    async updateSinglePersona(persona: Persona) {
      await updateSinglePersona(persona)
    },
    async clearPersonas() {
      this.personas = []
    }
  },
  getters: {
    companyPersonas: (state) => state.personas
  }
})

function arePersonasDifferent(newPersonas: Persona[], currentPersonas: Persona[]): boolean {
  // Check if the lengths of the arrays are different
  if (newPersonas.length !== currentPersonas.length) {
    return true
  }

  // Check if the contents of the arrays are different
  for (let i = 0; i < newPersonas.length; i++) {
    if (newPersonas[i].id !== currentPersonas[i].id || newPersonas[i].isActive !== currentPersonas[i].isActive) {
      return true
    }
  }

  return false
}

type storeInterface = {
  personas: Persona[]
}

export type personaPayload = {
  summary: string
  audience?: string
  usp?: string
  artStyle?: string
}

export type Persona = {
  id: number
  name: string
  surname: string
  nickname: string
  age: number
  gender: string
  location: string
  occupation: string
  motivations: string[]
  'pain-points': string[]
  opportunities: string[]
  profile_image: string
  company_id: number
  pinned: boolean
  isActive: boolean
}
