import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import PersonasView from '../views/PersonasView.vue';
import ColoursView from '../views/ColoursView.vue';
import MessagingView from '../views/MessagingView.vue';
import ArchitectureView from '../views/ArchitectureView.vue';
import ProjectView from '@/views/ProjectView.vue';
import GoogleAuthSuccess from '@/views/auth/GoogleAuthSuccess.vue';
import LoginView from '@/views/auth/LoginView.vue';
import SignupView from '@/views/SignupView.vue';
import NotFoundView from '@/views/NotFoundView.vue';
import LearningCentreView from '@/views/LearningCenterView.vue';
import TargetAudienceView from '@/views/TargetAudienceView.vue';
import LockSiteView from '@/views/LockSiteView.vue';
import PaymentReturnViewVue from '@/views/PaymentReturnView.vue';
import { isLoggedIn } from '@/guards/auth-guard';
import { hasPaid } from '@/guards/has-paid-guard';
import ForgotPasswordView from '@/views/auth/ForgotPasswordView.vue';
import SetNewPasswordView from '@/views/auth/SetNewPasswordView.vue';
import CreateProject from '../views/CreateProjectView.vue';
import PlansView from '../views/PlansView.vue';
import PaymentDetailsVue from '../views/PaymentDetails.vue';
import BlogPostViewVue from '../views/BlogPostView.vue';
import FAQsViewVue from '../views/FAQsView.vue';
import TermsAndConditionsViewVue from '../views/TermsAndConditionsView.vue';
import AccountViewVue from '../views/AccountView.vue';
import RegenerationProductsViewVue from '../views/RegenerationProductsView.vue';
import PaymentSuccessVue from '../views/PaymentSuccess.vue';
import VerifyEmailViewVue from '../views/auth/VerifyEmailView.vue';
import AccountNeedsVerification from '../views/auth/AccountNeedsVerificationView.vue';
import { useAuthStore } from '../stores/auth';
import AboutView from '../views/AboutView.vue';
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue';
import CookiePolicyView from '../views/CookiePolicyView.vue';
import FreeProjectPage from '../views/FreeProjectPage.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomeView,
      meta: {
        type: 'normal',
        crumb: 'Home'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: LoginView,
      meta: {
        type: 'normal',
        crumb: 'Login'
      }
    },
    {
      path: '/plans',
      name: 'plans',
      component: PlansView,
      meta: {
        type: 'normal',
        crumb: 'Plans'
      }
    },
    {
      path: '/regeneration-products',
      name: 'regeneration-products',
      component: RegenerationProductsViewVue,
      meta: {
        type: 'normal',
        crumb: 'Buy regenerations'
      }
    },
    {
      path: '/create-project',
      name: 'create-project',
      component: CreateProject,
      meta: {
        type: 'normal',
        crumb: 'Create Project'
      }
    },
    {
      path: '/learning-centre',
      name: 'learning-centre',
      component: LearningCentreView,
      meta: {
        type: 'normal',
        crumb: 'Learning Centre'
      }
    },
    {
      path: '/learning-centre/:slug',
      name: 'article',
      component: BlogPostViewVue,
      meta: {
        type: 'post',
        crumb: 'Learning Centre'
      }
    },
    {
      path: '/free-project',
      name: 'free-project',
      component: FreeProjectPage,
      meta: {
        type: 'normal',
        crumb: 'Free Project'
      }
    },
    {
      path: '/target-audience',
      name: 'target-audience',
      component: TargetAudienceView,
      meta: {
        type: 'normal',
        crumb: 'Create Project'
      }
    },
    {
      path: '/personas/:id',
      name: 'personas',
      component: PersonasView,
      beforeEnter: [isLoggedIn],
      meta: {
        type: 'project',
        crumb: 'My projects'
      }
    },
    {
      path: '/colours/:id',
      name: 'colours',
      component: ColoursView,
      beforeEnter: [isLoggedIn, hasPaid],
      meta: {
        type: 'project',
        crumb: 'My projects'
      }
    },
    {
      path: '/messaging/:id',
      name: 'messaging',
      component: MessagingView,
      beforeEnter: [isLoggedIn, hasPaid],
      meta: {
        type: 'project',
        crumb: 'My projects'
      }
    },
    {
      path: '/architecture/:id',
      name: 'architecture',
      component: ArchitectureView,
      beforeEnter: [isLoggedIn, hasPaid],
      meta: {
        type: 'project',
        crumb: 'My projects'
      }
    },
    {
      path: '/projects/:id',
      name: 'projects',
      component: ProjectView,
      beforeEnter: isLoggedIn,
      meta: {
        type: 'normal',
        crumb: 'My projects'
      }
    },
    {
      path: '/payment-success',
      name: 'payment-success',
      component: PaymentSuccessVue,
      beforeEnter: isLoggedIn,
      meta: {
        type: 'normal',
        crumb: 'Payment Success'
      }
    },
    {
      path: '/my-account',
      name: 'account',
      component: AccountViewVue,
      beforeEnter: isLoggedIn,
      meta: {
        type: 'normal',
        crumb: 'My account'
      }
    },
    {
      path: '/auth/google/callback',
      name: 'google-callback',
      component: GoogleAuthSuccess
    },
    {
      path: '/locked',
      name: 'locked',
      component: LockSiteView
    },
    {
      path: '/faqs',
      name: 'faqs',
      component: FAQsViewVue,
      meta: {
        type: 'normal',
        crumb: 'FAQs'
      }
    },
    {
      path: '/terms_and_conditions',
      name: 'terms',
      component: TermsAndConditionsViewVue,
      meta: {
        type: 'normal',
        crumb: 'Terms And Conditions'
      }
    },
    {
      path: '/privacy_policy',
      name: 'privacy',
      component: PrivacyPolicyView,
      meta: {
        type: 'normal',
        crumb: 'Privacy Policy'
      }
    },
    {
      path: '/cookie_policy',
      name: 'cookie',
      component: CookiePolicyView,
      meta: {
        type: 'normal',
        crumb: 'Cookie Policy'
      }
    },
    {
      path: '/payment-details',
      name: 'payment-details',
      component: PaymentDetailsVue,
      meta: {
        type: 'normal',
        crumb: 'Payment Details'
      }
    },
    {
      path: '/payment-redirect',
      name: 'payment-redirect',
      component: PaymentReturnViewVue,
      meta: {
        type: 'normal',
        crumb: 'Payment Redirect'
      }
    },
    {
      path: '/forgot-password',
      name: 'forgot-password',
      component: ForgotPasswordView,
      meta: {
        type: 'normal',
        crumb: 'Forgot password'
      }
    },
    {
      path: '/reset-password/:token',
      name: 'reset-password',
      component: SetNewPasswordView,
      meta: {
        type: 'normal',
        crumb: 'Reset password'
      }
    },
    {
      path: '/verify-email/:token',
      name: 'verify-email',
      component: VerifyEmailViewVue,
      meta: {
        type: 'normal',
        crumb: 'Verify Email'
      }
    },
    {
      path: '/verification-message',
      name: 'verification-message',
      component: AccountNeedsVerification,
      meta: {
        type: 'normal',
        crumb: 'Verify Email'
      }
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFoundView,
      meta: {
        type: 'normal',
        crumb: 'Page not found'
      }
    },
    {
      path: '/about',
      name: 'About',
      component: AboutView,
      meta: {
        type: 'normal',
        crumb: 'About'
      }
    }
  ],
  scrollBehavior() {
    return new Promise((resolve) => {
      resolve({ left: 0, top: 0 });
    });
  }
});

// This is temporary until the site is open to everyone
// TODO: remove this when the site is open to everyone
router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  authStore.setReturnUrl(from.path.includes('verify-email') ? '/' : from.path);
  // navigate to login if localstorage has no password
  // if (to.name !== 'locked' && localStorage.getItem('secret') !== '!2S£c1_8') {
  //   next({ name: 'locked' })
  // }
  next();
});

export default router;
