<template>
  <div class="sub-menu">
    <router-link active-class="active" v-for="(link, i) in links" :key="i" class="icon" :to="'/' + link.link" append>
      <p class="small">{{ link.name }}</p>
    </router-link>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { getFooterLinks } from '@/services/strapi-service'

const links = ref([])

onMounted(async () => {
  links.value = await getFooterLinks()
})
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.sub-menu {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2.5rem;
  margin-bottom: 1rem;

  a {
    p {
      color: white;
      white-space: nowrap;
    }

    &.active {
      font-weight: 600;
    }

    &:hover {
      p {
        font-weight: 600;
      }
    }

    &:not(:last-child) {
      ::after {
        content: '|';
        margin: 0 0.5rem;
      }
    }
  }

  @include sm {
    justify-content: end;
    margin-bottom: 0;
  }
}
</style>
