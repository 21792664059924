<template>
  <section>
    <form @submit="onSubmit" id="refine-section">
      <div class="title top-borders" :class="activeSection.name">
        <h3>Refine your {{ activeSection.title.toLowerCase() }} results</h3>
        <!-- TODO FROM CMS??? -->
        <p class="lead-text">Enhance you results by answering these additional questions.</p>
        <img class="character" src="@assets/images/character.svg" />
      </div>
      <div class="right-wrapper">
        <div class="warning" :class="activeSection.name">
          <p class="quote">Are there any existing customer needs or market factors that you are aware of?</p>
        </div>
        <div class="buttons bottom-borders" :class="activeSection.name">
          <div class="input-wrapper">
            <input
              type="text"
              name="additions"
              id="additions"
              v-model="additions"
              v-bind="additionsprops"
              rows="3"
              placeholder="Describe your customer and location."
              class="bg-dark"
            />
          </div>
          <button :disabled="!isFormValid" class="reg-button">
            <span class="regenerate-button-icon"></span>
            Regenerate
          </button>
        </div>
      </div>

      <page-blocking-loader v-if="isLoading" />
    </form>
    <div class="bottom">
      <button @click="goNext()">{{ currentSection !== 'messaging' ? 'Next' : 'Back to projects' }}</button>
    </div>
  </section>
  <regeneration-popup v-if="showWarning" @proceed="regenerate()" @close-popup="() => (showWarning = false)" />
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useForm, useIsFormValid, useIsFormDirty } from 'vee-validate';
import { object, string } from 'yup';
import { useColourStore } from '@/stores/colours';
import { useMessagingStore } from '@/stores/messaging';
import { usePersonaStore } from '@/stores/persona';
import { useArchitectureStore } from '@/stores/architecture';
import { useCompanyStore } from '../../stores/company';
import { useRoute, useRouter } from 'vue-router';
import { useToastStore } from '@/stores/toast';
import RegenerationPopup from '../ui/regeneration-popup/RegenerationPopup.vue';
import { refineAllContent } from '../../services/messaging-service';
import { useSocialStore } from '../../stores/social-media';
import { useBlogContentStore } from '../../stores/blog-content';
import { toTypedSchema } from '@vee-validate/yup';
import PageBlockingLoader from '../spinner/PageBlockingLoader.vue';
import { useAuthStore } from '../../stores/auth';
import { storeToRefs } from 'pinia';

const sections: any[] = [
  { title: 'Audience profiling', name: 'personas' },
  { title: 'Website structure', name: 'architecture' },
  { title: 'Colour palette and styling', name: 'colours' },
  { title: 'Content and messaging', name: 'messaging' }
];
const route = useRoute();
const router = useRouter();
const currentSection = route.name;
const activeSection = ref(sections.find((section) => section.name === currentSection));
const { alert } = useToastStore();
const personaStore = usePersonaStore();
const messagingStore = useMessagingStore();
const socialStore = useSocialStore();
const blogStore = useBlogContentStore();
const colourStore = useColourStore();
const architectureStore = useArchitectureStore();
const isLoading = ref(false);
const companyStore = useCompanyStore();
const showWarning = ref(false);
const { user } = storeToRefs(useAuthStore());

const schema = toTypedSchema(
  object({
    additions: string().min(1).required()
  })
);

const { values, handleSubmit, errors, defineField } = useForm({
  validationSchema: schema
});
const isFormValid = useIsFormValid();
const isFormDirty = useIsFormDirty();

const [additions, additionsprops] = defineField('additions');

const goNext = () => {
  if (currentSection !== 'messaging') {
    const i = sections.findIndex((item) => item.name === currentSection);
    const nextSection = sections[i + 1];

    router.push({ name: nextSection.name, params: { id: companyStore?.myCompany?.id } });
  } else {
    router.push({ name: 'projects', params: { id: user?.value?.id } });
  }
};

const onSubmit = (e) => {
  e.preventDefault();
  showWarning.value = true;
};

const regenerate = handleSubmit(async () => {
  showWarning.value = false;

  if (!isFormValid) {
    alert('Please fill in all fields.', 'error');
    return;
  }

  const { id } = route.params;

  const hasPaid = await companyStore.checkCanViewFullProject(+id);

  if (!hasPaid) {
    alert('Please upgrade to refine project.', 'error');
    return;
  }

  switch (activeSection.value.name.toUpperCase()) {
    case 'PERSONAS':
      isLoading.value = true;
      await personaStore.refinePersonas(+id, values.additions);
      await personaStore.clearPersonas();
      isLoading.value = false;
      break;
    case 'COLOURS':
      isLoading.value = true;
      await colourStore.updateColours(+id, values.additions);
      isLoading.value = false;
      break;
    case 'ARCHITECTURE':
      isLoading.value = true;
      await architectureStore.updateArchitecture(+id, values.additions);
      isLoading.value = false;
      break;
    case 'MESSAGING':
      await refineAllContent(+id, values.additions);
      messagingStore.clearStore();
      socialStore.clearStore();
      blogStore.clearStore();
      break;
    default:
      alert('There as an error refining this section', 'error');
      break;
  }

  await companyStore.getCompany(+id);
});
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

form {
  @include lg {
    display: flex;
    align-items: stretch;
    .title {
      flex: 1;
      position: relative;

      .character {
        display: block;
        position: absolute;
        bottom: -19rem;
        right: 10rem;
        width: 28rem;
      }

      &.top-borders {
        border-top-left-radius: 30px;
        border-bottom-left-radius: 30px;
        border-top-right-radius: unset;
      }
    }
    .right-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
    }
    .warning {
      border-top-right-radius: 30px;
    }

    .buttons {
      border-bottom-left-radius: unset;
      flex: 1;
    }
  }

  .character {
    display: none;
  }

  .character {
    display: none;
    @include lg {
      display: block;
      position: absolute;
      bottom: -13rem;
      right: 7rem;
      width: 20rem;
    }
  }

  .title {
    padding: 2.25rem 1.5rem;

    @include xl {
      padding: 3rem;
    }
    background-color: $mid-green;
  }

  .warning {
    padding: 1.5rem;

    @include xl {
      padding: 3rem;
    }
    background-color: $dark-green;

    p {
      font-size: 1.25rem;
      font-weight: 300;
      line-height: 140%;
    }
  }

  .buttons {
    padding: 1.5rem;
    padding-bottom: 2.25rem;

    @include xl {
      padding: 2rem;
    }
    background-color: $light-green;

    input {
      height: 71px;
    }

    button {
      width: 100%;
      margin-top: 1.5rem;
      @include sm {
        width: fit-content;
      }

      &.reg-button {
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;

        @include sm {
          height: 64px;
        }
        .regenerate-button-icon {
          width: 28px;
          height: 28px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 24px;
          background-image: url('@assets/icons/regenerate-yellow.svg');
        }

        &:disabled {
          background-color: #93ccb8;
          color: #405950;
          border: transparent;
          .regenerate-button-icon {
            background-image: url('@assets/icons/regenerate-disabled-green.svg');
          }
        }

        &:hover {
          &:not(:disabled) {
            .regenerate-button-icon {
              background-image: url('@assets/icons/regenerate-black.svg');
            }
          }
        }
      }
    }
  }
}

.bottom {
  margin: 20px 0;

  @include sm {
    margin: 40px 0;
  }
  @include lg {
    margin-bottom: 15rem;
  }

  button {
    margin: 0 auto;
    z-index: 2;
    position: relative;
    height: 44px;
    width: 100%;

    @include sm {
      height: 64px;
      width: fit-content;
    }

    &:hover {
      background-color: white;
    }
  }

  .placeholder {
    height: 64px;
  }
}

#additions {
  background-color: $light-green;
}
</style>
