import {
  canViewFullProject,
  changeCompanyName,
  getAllCompanies,
  getMyCompany,
  regenerateCompanySection,
  updateArtStyle
} from '@/services/company-service';
import { defineStore } from 'pinia';
import { usePersonaStore } from './persona';

export const useCompanyStore = defineStore({
  id: 'company',
  state: (): storeInterface => ({ company: null, companies: [], canViewFullProject: null }),
  actions: {
    async getCompany(id: number) {
      this.company = await getMyCompany(id);
    },
    async getAllCompanies(userId: number) {
      this.companies = await getAllCompanies(userId);
    },
    async updatePersonaArtStyle(companyId: number, artStyle: string) {
      this.company = await updateArtStyle(companyId, artStyle);

      // refresh personas
      const personaStore = usePersonaStore();
      await personaStore.getPersonas(companyId, true);
    },
    async checkCanViewFullProject(projectId: number) {
      this.canViewFullProject = null;
      try {
        const response = await canViewFullProject(projectId);

        this.canViewFullProject = response.canView;
        if (response.company) this.company = response.company;
        return this.canViewFullProject;
      } catch (error) {
        this.canViewFullProject = false;
        return false;
      }
    },
    async regenerateSection(companyId: number, section: string) {
      return await regenerateCompanySection(companyId, section);
    },
    async changeName(companyId: number, name: string) {
      const res = await changeCompanyName(companyId, name);
      if (this.company) this.company.name = res.name;
    },
    setCompany(company: Company) {
      this.company = company;
    }
  },
  getters: {
    myCompany: (state) => state.company,
    myCompanies: (state) => state.companies
  }
});

type storeInterface = {
  company: Company | null;
  companies: Company[];
  canViewFullProject: boolean | null;
};

export type Company = {
  id: number | null;
  name: string;
  summary: string;
  audience: string;
  art_style: string;
  regenerations: number;
  logo: string;
  created_at: string;
  has_payment?: boolean;
};
