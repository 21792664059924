<template>
  <div>
    <CompanyDescriptionInput :showName="false" section="AUDIENCE" @submitted="handleSubmit" />
    <div class="benefits-wrapper full-width"><BenefitsComponent /></div>
    <SpinnerComponent v-if="isLoading" />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { type personaPayload, usePersonaStore } from '@/stores/persona'
import { useRoute, useRouter } from 'vue-router'
import CompanyDescriptionInput from '@components/company-description/CompanyDescriptionInput.vue'
import BenefitsComponent from '../components/benefits/BenefitsComponent.vue'
import SpinnerComponent from '@components/spinner/SpinnerComponent.vue'
import { useCompanyStore } from '@/stores/company'
import { useAuthStore } from '@/stores/auth'

const route = useRoute()
const router = useRouter()
const companyStore = useCompanyStore()
const store = usePersonaStore()
const auth = useAuthStore()
const isLoading = ref(false)
const summary = ref('')

const handleSubmit = async ({ audience, usp, artStyle }: personaPayload) => {
  try {
    isLoading.value = true
    await store.createPersonas({ summary: summary.value as string, audience, usp, artStyle })
    isLoading.value = false
    router.push({ name: 'personas', params: { id: companyStore.company.id } })
  } catch (error) {
    isLoading.value = false
  }
}

onMounted(() => {
  const urlSummary = route.query.summary
  if (urlSummary) {
    summary.value = urlSummary as string
  }
})
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';

.benefits-wrapper {
  background-color: #b5e6fb !important;
  border-top-left-radius: 120px;
  border-top-right-radius: 120px;
  margin-top: 110px;
}
</style>
