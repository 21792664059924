<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import { useAuthStore } from '@/stores/auth';
import { useCompanyStore } from '../../stores/company';

const route = useRoute();
const router = useRouter();
const store = useAuthStore();
const companyStore = useCompanyStore();
const { code } = route.query;
const returnUrl = ref('/');
const summary = localStorage.getItem('summary');

onMounted(async () => {
  const user = await store.googleCallbackExec(code as string);
  await companyStore.getAllCompanies(user.id);

  if (summary) {
    router.push({ name: 'target-audience', query: { summary } });
    localStorage.removeItem('summary');
  } else if (companyStore.companies.length <= 0) {
    router.push('/create-project');
  } else {
    router.push(`/projects/${user.id}`);
  }
});
</script>

<template>
  <main></main>
</template>
