<template>
  <div class="notification-wrapper top-borders bottom-borders">
    <div class="top">
      <h3>Email notifications</h3>
    </div>
    <div class="bottom">
      <div class="item">
        <label for="newsletter">Monthly newsletter</label>
        <VueToggles v-model="newsletter" :height="36" :width="63" checkedBg="#0D6115" uncheckedBg="#959595" />
      </div>
      <button @click="savePreference()" class="save-button">Save changes</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import ModalComponent from '../../ui/modal-component/ModalComponent.vue'
import { VueToggles } from 'vue-toggles'
import { ref } from 'vue'
import { useAuthStore } from '../../../stores/auth'
import { storeToRefs } from 'pinia'
import { useToastStore } from '../../../stores/toast'

const store = useAuthStore()
const { user } = storeToRefs(useAuthStore())
const { alert } = useToastStore()
const newsletter = ref<boolean>(user.value.newsletter)

const savePreference = async () => {
  await store.saveEmailPreference(newsletter.value)
  alert('Email preferences saved', 'success')
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.notification-wrapper {
  background-color: white;
  overflow: hidden;

  .top {
    padding: 2rem 2.5rem;
    width: 100%;
    background-color: $mid-pink;
    h3 {
      margin: 0;
    }
  }

  .bottom {
    background-color: white;
    padding: 2rem 2.5rem;

    .item {
      display: flex;
      justify-content: space-between;
      @include md {
        justify-content: flex-start;
      }
      label {
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        margin-right: 1rem;
        width: 33%;
        display: inline-block;
      }

      :deep(.vue-toggles) {
        transition: all 0.5s ease-in !important;
        .vue-toggles__dot {
          background-image: url('@assets/icons/toggle-close.svg') !important;
          background-size: 15px !important;
          background-repeat: no-repeat !important;
          background-position: 50% !important;
        }
        &[aria-checked='true'] {
          .vue-toggles__dot {
            background-image: url('@assets/icons/toggle-tick.svg') !important;
          }
        }
      }
    }

    .save-button {
      margin-top: 3.75rem;
    }
  }
}
</style>
