import {
  createCompanySiteArchitecture,
  getCompanySiteArchitecture,
  pinInfoArchitecture,
  updateAllSections,
  updateCompanySiteArchitecture
} from '@/services/architecture-service'
import { defineStore } from 'pinia'

export const useArchitectureStore = defineStore({
  id: 'architecture',
  state: (): storeInterface => ({ sections: [] }),
  actions: {
    async createArchitecture(id: number) {
      this.sections = []
      const response = await createCompanySiteArchitecture(id)
      this.sections = response.sections
    },
    async getArchitecture(id: number) {
      this.sections = await getCompanySiteArchitecture(id)
    },
    async updateArchitecture(id: number, additions: string) {
      await updateCompanySiteArchitecture({ id, additions })
      this.sections = []
    },
    async pinArchitecture(id: number) {
      const res = await pinInfoArchitecture(id)

      await this.getArchitecture(res.company_id)
    },
    async updateSections(sections: Section[]) {
      const response = await updateAllSections(sections)
    },
    clearStore() {
      this.sections = []
    }
  },
  getters: {
    companyArchitecture: (state) =>
      state.sections.sort((a, b) => {
        if (a.name.toLowerCase() === 'home' && b.name.toLowerCase() !== 'home') {
          return -1 // 'home' comes before any other value
        } else if (a.name.toLowerCase() !== 'home' && b.name.toLowerCase() === 'home') {
          return 1 // Any other value comes after 'home'
        } else {
          return 0 // Keep the order unchanged for other cases
        }
      }),
    inactive: (state) => state.sections.filter((section: Section) => !section.is_active)
  }
})

type storeInterface = {
  sections: Section[]
}

export type Section = {
  name: string
  content: string[]
  pinned: boolean
  id: number
  is_active: boolean
}
