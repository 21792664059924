<template>
  <div class="top-borders bottom-borders form-wrapper" v-if="!completed">
    <div class="top top-borders"><h3>Download a free example</h3></div>
    <form class="bottom-borders" @submit="onSubmit">
      <div class="input-wrapper">
        <label>Name</label>
        <input
          type="text"
          v-model="name"
          name="name"
          autocomplete="true"
          :class="{
            error: nameErrors?.length && name,
            success: !nameErrors?.length && name
          }"
        />
        <div class="error" v-if="nameErrors.length && name">
          <ul>
            <li class="list-none" v-for="(error, i) of nameErrors" :key="i">{{ error }}</li>
          </ul>
        </div>
      </div>
      <div class="input-wrapper">
        <label>Email</label>
        <input
          type="email"
          v-model="email"
          name="email"
          autocomplete="true"
          :class="{
            error: emailErrors?.length && email,
            success: !emailErrors?.length && email
          }"
        />
        <div class="error" v-if="emailErrors.length && email">
          <ul>
            <li class="list-none" v-for="(error, i) of emailErrors" :key="i">{{ error }}</li>
          </ul>
        </div>
      </div>
      <button type="submit" :disabled="!meta.valid">Submit</button>
    </form>
  </div>

  <div class="success-wrapper" v-else>
    <div class="character">
      <img src="@assets/images/character.svg" alt="" />
    </div>
    <div class="content">
      <h2>Thank you!</h2>
      <p>
        Thank you for downloading an example, this will be send to your email address. This normally takes a few
        minutes.
      </p>
      <button @click="() => router.push('/')">Back to home</button>
    </div>
  </div>
  <benefits-component />
</template>
<script setup lang="ts">
import BenefitsComponent from '@/components/benefits/BenefitsComponent.vue';
import { createFreeProjectDownload } from '../services/user-service';
import { useField, useForm } from 'vee-validate';
import { ref } from 'vue';
import { object, string } from 'yup';
import { useChallengeV3 } from 'vue-recaptcha';
import { useToastStore } from '../stores/toast';
import { useRouter } from 'vue-router';

const completed = ref<boolean>(false);
const { alert } = useToastStore();
const router = useRouter();

const schema = object({
  email: string().email('Please enter a valid email address').required(),
  name: string().required()
});

const { meta, handleSubmit } = useForm({
  validationSchema: schema
});

const { value: email, errors: emailErrors } = useField<string>(() => 'email');
const { value: name, errors: nameErrors } = useField<string>(() => 'name');

const { execute } = useChallengeV3('submit');

const onSubmit = handleSubmit(async (values: any) => {
  const token = await execute();

  try {
    const res = await createFreeProjectDownload({ ...values, token });
    if (res.success) {
      completed.value = true;
    }
  } catch (error) {
    alert(error?.message ?? 'Failed to save your details, please try again.', 'error');
  }
});
</script>
<style scoped lang="scss">
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.form-wrapper {
  width: 100%;
  max-width: 800px;
  margin: 44px auto 60px auto;
  background-color: $grey-3;
  height: wrap-content;
  .top {
    background-color: $black;
    color: white;
    padding: 32px 48px;

    h3 {
      margin: 0;
    }
  }

  .center {
    text-align: center;
    cursor: pointer;
  }
}

.success-wrapper {
  h2 {
    margin-bottom: 13px;
  }
}

form {
  margin: 0;
  padding: 48px;
  background-color: $grey-2;

  .input-wrapper {
    margin-bottom: 1rem;
    input {
      background-color: white;
    }
  }
  button {
    display: block;
    margin: 48px auto 0 auto;
  }
}

.success-wrapper {
  width: 100%;
  margin: 44px auto 60px auto;
  height: wrap-content;

  @include xl {
    display: flex;
    gap: 34px;
  }

  .character {
    flex: 1;
    display: none;

    @include xl {
      position: relative;
      display: block;
      height: fit-content;
      height: 600px;

      img {
        width: 100%;
        position: absolute;
      }
    }
  }

  .content {
    flex: 1;
    button {
      margin-top: 20px;
    }
  }
}
</style>
