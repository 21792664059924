import axios from 'axios'
import type { Section } from '@/stores/architecture'


const baseUrl = import.meta.env.VITE_API_URL

export const getCompanySiteArchitecture = async (companyId: number) => {
  const response = await axios.get(`${baseUrl}/info-architecture/${companyId}`)
  return response.data
}

export const createCompanySiteArchitecture = async (companyId: number) => {
  const response = await axios.post(`${baseUrl}/info-architecture/${companyId}`)
  return response.data
}

export const updateCompanySiteArchitecture = async ({ id, additions }: { id: number; additions: string }) => {
  const response = await axios.put(`${baseUrl}/info-architecture/${id}`, { additions })
  return response.data
}

export const pinInfoArchitecture = async (id: number) => {
  const response = await axios.put(`${baseUrl}/info-architecture/pin/${id}`)
  return response.data
}

export const updateAllSections = async (sections: Section[]) => {
  const response = await axios.post(`${baseUrl}/info-architecture/update-all`, { sections })
  return response.data
}
