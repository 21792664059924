<template>
  <div class="wrapper">
    <img src="../assets/images/character.svg" alt="" class="character" />
    <div class="pricing-plans">
      <div><h2>Pricing plans</h2></div>

      <div v-for="(plan, i) of plans" :key="i" class="card" :class="i % 2 ? '' : 'dark'">
        <div class="top top-borders">
          <h3>{{ plan.attributes.name }}</h3>
          <p>{{ plan.attributes.description }}</p>
        </div>
        <div class="bottom bottom-borders">
          <div class="flex">
            <h1>£{{ plan.attributes.price }}</h1>
            <h4>One off payment</h4>
          </div>

          <button class="secondary" @click="selectPlan(plan)">{{ user ? 'Select plan' : 'Try before you buy' }}</button>
          <p class="lead-text">What you get:</p>
          <ul>
            <li v-for="(item, k) of plan.attributes.perks" :key="k" class="item">
              <img :src="getImageUrl(i)" /> {{ item.text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { getProducts } from '../services/strapi-service';
import { useAuthStore } from '../stores/auth';
import { createPaymentSession, type PaymentSessionData } from '../services/payment-service';
import { useHead } from '@unhead/vue';
import { constructHead } from '../utils/constructHead';

const store = useAuthStore();

const { user } = storeToRefs(store);

const router = useRouter();
const selectPlan = async (plan) => {
  if (!user.value) {
    router.push({ name: 'home' });
    return;
  }
  localStorage.setItem('selectedPlan', JSON.stringify(plan.attributes));
  const payload: PaymentSessionData = {
    plan_id: plan.attributes.db_id,
    type: 'projects'
  };
  await createPaymentSession(payload);
  router.push({ name: 'payment-details' });
};
const getImageUrl = (index) => {
  const name = index % 2 ? 'black' : 'yellow';
  return new URL(`../assets/icons/tick-${name.toLowerCase()}.svg`, import.meta.url).href;
};
// TODO get from cms
const plans = ref([]);

onBeforeMount(async () => {
  useHead(computed(() => constructHead({ title: `Laiya - Pricing plans` })));
});

onMounted(async () => {
  plans.value = await getProducts();
});
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.wrapper {
  margin-top: 44px;
  position: relative;
  min-height: unset;
  padding-bottom: 110px;

  .character {
    display: none;
    position: absolute;
    z-index: 0;
    transform: scaleX(-1);

    @include lg {
      display: block;
      height: auto;
      width: 506px;
      bottom: 15px;
      left: 7px;
    }

    @include laptop {
      width: 586px;
      left: 32px;
      bottom: -30px;
    }
  }

  .pricing-plans {
    z-index: 2;
    display: grid;
    gap: 1.5rem;
    @include lg {
      grid-template-columns: repeat(3, 1fr);
    }
    .card {
      .top {
        padding: 2rem 2.5rem;
        background-color: $grey-2;
      }

      .bottom {
        background-color: white;
        padding: 2rem 2.5rem;

        .flex {
          margin-bottom: 1rem;
          display: flex;
          align-items: end;
          gap: 0.5rem;
          h4 {
            margin-bottom: 0.5rem;
          }
        }

        button {
          margin-bottom: 2rem;
        }
        .item {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          margin-top: 1rem;
        }
      }

      &.dark {
        .top {
          padding: 2rem 2.5rem;
          background-color: $black;
          color: white;
        }

        .bottom {
          background-color: $mid-black;
          color: white;
          padding: 2rem 2.5rem;

          button {
            transition: all 0.2s ease-in-out;
            color: $black;
            background-color: $mid-green;
            border: 1px solid $mid-green;

            &:hover {
              background-color: $black;
              border: 1px solid $mid-green;
              color: white;
            }
          }
          .item {
            display: flex;
            align-items: center;
            gap: 0.75rem;
          }
        }
      }
    }
  }
}
</style>
