import axios from 'axios';
import type { User } from '../stores/auth';

const baseUrl = import.meta.env.VITE_API_URL;

export const createEmailUser = async (user: User) => {
  const response = await axios.post(`${baseUrl}/user/register`, user);
  return response.data;
};
export const refreshUserDetails = async (id: number) => {
  const response = await axios.get(`${baseUrl}/user/refresh/${id}`);
  return response.data;
};

export const updateUser = async (user: User) => {
  const response = await axios.post(`${baseUrl}/user/update/${user.id}`, user);
  return response.data;
};

export const updatePreferences = async (newsLetter: boolean, id: number) => {
  const response = await axios.post(`${baseUrl}/user/preferences/${id}`, { newsletter: newsLetter });
  return response.data;
};

export const updateUserPassword = async (payload: { id: number; password: string; newPassword: string }) => {
  const response = await axios.post(`${baseUrl}/user/update-password/${payload.id}`, payload);
  return response.data;
};

export const deleteAccount = async (id: number) => {
  const response = await axios.delete(`${baseUrl}/user/delete/${id}`);
  return response.data;
};

export const createFreeProjectDownload = async (payload: FreeProjectUserPayload) => {
  const response = await axios.post(`${baseUrl}/user/free-project-download`, payload);
  return response.data;
};

export type UserPayload = {
  first_name: string;
  last_name: string;
  email: string;
  password: string;
};

export type FreeProjectUserPayload = {
  name: string;
  email: string;
  token: string;
};
