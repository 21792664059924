<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useToastStore } from '@/stores/toast'

const { message, messageType, toastIsOpen } = storeToRefs(useToastStore())
</script>

<template>
  <Transition name="slide-fade">
    <div class="toast-wrapper" v-if="toastIsOpen">
      <div class="icon toast-icon" :class="messageType"></div>
      <div class="message">{{ message }}</div>
    </div>
  </Transition>
</template>

<style scoped lang="scss">
@import '@assets/scss/mixins.scss';
@import '@assets/scss/variables.scss';
@import '@assets/scss/toast.scss';

.toast-wrapper {
  width: fit-content;
  max-width: 600px;
  position: fixed;
  bottom: 0;
  right: 50%;
  transform: translate(50%);
  z-index: 9999999;
  margin-bottom: 2.5rem;
  display: flex;
  background-color: $mid-black;
  border-radius: 15px;

  .icon {
    background-color: $black;
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    padding: 1.5rem;
  }

  .message {
    color: white;
    background-color: $mid-black;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    padding: 1.5rem;
  }
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translate(50%, 100px);
}
</style>
