<template>
  <div class="full-width" v-if="showBreadCrumbs()">
    <div class="body bread-crumbs">
      <router-link to="/"><p>Home</p></router-link>
      <router-link v-for="(item, i) of getCrumbs()" :key="i" :to="'/' + item.path">
        <p><img src="@assets/icons/chevron-right.svg" alt="" /> {{ item?.name }}</p></router-link
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useAuthStore } from '../../stores/auth';
import { useCompanyStore } from '../../stores/company';

const route = useRoute();
const { company } = storeToRefs(useCompanyStore());
const { user } = storeToRefs(useAuthStore());

const showBreadCrumbs = () => {
  const hide = ['home', 'payment-details', 'payment-redirect'];
  return !hide.includes(route?.name as string);
};
const getCrumbs = () => {
  let items: { name: string; path: string }[] = [];
  const name: string = route.meta?.crumb as string;
  const path: string = route?.name as string;
  const type: string = route.meta?.type as string;
  const id: string = route.params.id as string;

  if (path === 'home') return items;

  const item = { name, path: id ? `${path}/${id}` : path };

  if (name) items.push(item);

  if (type === 'project' && company.value) {
    // get user id
    items[0].path = `projects/${user.value.id}`;
    const custom = { name: company?.value.name, path: '#' };
    items.push(custom);
  }

  if (type === 'post') {
    items[0].path = 'learning-centre';
    const param = route.params.slug as string;
    // get post name
    const custom = {
      name: param.charAt(0).toUpperCase() + param.replaceAll('_', ' ').replaceAll('-', ' ').slice(1),
      path: `learning-centre/${param}`
    };
    items.push(custom);
  }

  return items;
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.full-width {
  background: $grey-2 !important;
}

.bread-crumbs {
  padding: 1.5rem;
  @include sm {
    padding: 1.5rem 0rem;
  }
  display: flex;
  align-items: center;
  gap: 1rem;

  p {
    display: flex;
    align-items: center;
    &:hover {
      font-weight: 600;
    }
  }

  img {
    height: 16px;
    height: 16px;
    margin-right: 1rem;
  }
}
</style>
