<template>
  <div class="hov-wrapper">
    <img :src="getImageUrl(icon)" />
    <div class="tooltip" :class="isBlack ? 'black' : 'grey'">
      <div class="top">
        <p><slot name="title"></slot></p>
        <img :src="getImageUrl(isBlack ? 'info' : 'info-black')" />
      </div>
      <div class="tooltip-text bottom">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { icon, isBlack } = defineProps({
  icon: {
    type: String,
    default: 'info-black'
  },
  isBlack: {
    type: Boolean,
    default: true
  }
});

const getImageUrl = (name: string) => {
  return new URL(`../../../assets/icons/${name.toLowerCase()}.svg`, import.meta.url).href;
};

const iconClicked = () => {
  // change tooltip to display block
  const tooltip = document.querySelector('.tooltip') as HTMLElement;
  tooltip.style.display = 'block';
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
.hov-wrapper {
  width: fit-content;
  height: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  img {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }

  &:hover {
    .tooltip {
      display: block;
    }
  }
  .tooltip {
    display: none;
    position: absolute;
    background-color: $black;
    border-radius: 15px;
    margin: 0 auto;
    top: 59px;
    right: -162px;
    min-width: 22rem;
    z-index: 999;

    .bottom-borders {
    }

    &::after {
      content: '';
      position: absolute;
      top: -1.5rem;
      right: 9.5rem;
      width: 0;
      height: 0;
      border-left: 1.5rem solid transparent;
      border-right: 1.5rem solid transparent;
      border-bottom: 1.5rem solid $black;
    }

    .top {
      padding: 2.5rem;
      display: flex;
      justify-content: space-between;

      p {
        font-weight: 600;
        color: white;
      }
    }

    .bottom {
      color: white;
      background-color: $mid-black;
      padding: 2.5rem;
      border-bottom-right-radius: 15px;
      border-bottom-left-radius: 15px;
    }

    &.grey {
      background-color: $grey-3;

      &::after {
        border-bottom: 1.5rem solid $grey-3;
      }

      .top {
        p {
          color: $black;
        }
      }

      .bottom {
        color: $black;
        background-color: $grey-2;
      }
    }
  }
}
</style>
