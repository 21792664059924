<template>
  <div v-if="!emailSent" class="password-wrapper top-borders bottom-borders">
    <div class="top top-borders"><h3>Trouble with logging in?</h3></div>

    <form class="bottom-borders bg" @submit="onSubmit">
      <p>Enter your email address and we’ll send you a link to get back into your account.</p>
      <div class="input-wrapper">
        <label>Email address</label>
        <input
          type="email"
          placeholder="Email"
          v-model="email"
          :class="{
            error: errors?.length && email,
            success: !errors?.length && email
          }"
        />
        <div class="error" v-if="errors.length && email">
          <ul>
            <li v-for="(error, i) of errors" :key="i">{{ error }}</li>
          </ul>
        </div>
      </div>
      <button :disabled="!meta.valid">Send reset link</button>
      <div class="arrow">
        <p class="center">Can't reset your password?</p>
        <img class="chevron" src="@assets/icons/chevron-right.svg" />
      </div>
    </form>
    <div class="arrow">
      <p class="center m-30" @click="backToLogin">Back to login</p>
      <img class="chevron" src="@assets/icons/chevron-right.svg" />
    </div>
  </div>
  <div v-else class="password-wrapper top-borders bottom-borders">
    <div class="top top-borders"><h3>Email sent</h3></div>

    <div class="bottom-borders bg">
      <p>{{ message }}</p>
      <button v-if="!isSSO" @click="backToLogin">Back to login</button>
      <GoogleButton v-else text="Sign in with Google" @clicked="google()" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { object, string } from 'yup';
import { useAuthStore } from '@/stores/auth';
import { useToastStore } from '@/stores/toast';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { useChallengeV3 } from 'vue-recaptcha';
import GoogleButton from '../../components/ui/google-button/GoogleButton.vue';
import { redirectToGoogle } from '@/services/social-auth-service';

const store = useAuthStore();
const router = useRouter();
const { alert } = useToastStore();
const schema = object({
  email: string().email('Please enter a valid email address').required()
});
const emailSent = ref<boolean>(false);
const { meta, handleSubmit } = useForm({
  validationSchema: schema
});
const { execute } = useChallengeV3('submit');
const { value: email, errors } = useField(() => 'email');

const isSSO = ref(false);
const message = ref('');

const onSubmit = handleSubmit(async () => {
  const token = await execute();
  try {
    const res = await store.forgotPassword(email.value as string, token);
    isSSO.value = res.is_sso;
    message.value = res.message;
    emailSent.value = true;
    alert(res.message, 'success');
  } catch (error) {
    alert('Something when wrong, please try again.', 'error');
  }
});

const google = async () => {
  const res = await redirectToGoogle();
  window.open(res, '_self');
};

const backToLogin = () => {
  emailSent.value = false;
  router.push('/login');
};
</script>
<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.arrow {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;

  .chevron {
    height: 16px;
    width: 16px;
    margin-left: 0.5rem;
  }

  p {
    padding-bottom: 0;
  }
}
.password-wrapper {
  width: 100%;
  max-width: 564px;
  margin: 6.875rem auto;
  margin-top: 44px;
  background-color: $grey-3;
  height: wrap-content;
  .top {
    background-color: $black;
    color: white;
    padding: 3rem;

    h3 {
      margin: 0;
    }
  }
  .bg {
    padding: 3rem 3rem 1.875rem 3rem;
    background-color: $grey-2;
  }
  form {
    margin: 0;

    .input-wrapper {
      margin-top: 1rem;
      input {
        background-color: white;
      }
    }
  }
  button {
    display: block;
    margin: 2rem auto;
  }

  .center {
    text-align: center;
    cursor: pointer;
  }
  .m-30 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.error {
  li {
    color: $signifier-error;
  }
}
</style>
