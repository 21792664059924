<template>
  <policies-component :data="policy" title="Privacy Policy" />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { getPrivacyPolicy } from '../services/strapi-service';
import { useHead } from '@unhead/vue';
import { constructHead } from '../utils/constructHead';
import PoliciesComponent from '../components/ui/policies/PoliciesComponent.vue';

const policy = ref(null);

onBeforeMount(async () => {
  const res = await getPrivacyPolicy();
  const data = { ...res, data: res.content };

  policy.value = data;

  if (res.seo) {
    useHead(computed(() => constructHead(res.seo!)));
  }
});
</script>

<style lang="scss" scoped></style>
