<template>
  <div class="card-wrapper">
    <form id="payment-form" @submit.prevent="handleSubmit">
      <div id="payment-element" />
      <div class="flex" v-if="product">
        <p>Subtotal</p>
        <p>£{{ product?.price.toFixed(2) }}</p>
      </div>
      <coupon-component class="coupon" :product="product" v-if="product"></coupon-component>
      <button id="submit" v-if="!isLoading" :disabled="paymentLoading">
        <span v-if="!paymentLoading">Pay now</span> <button-loader v-else></button-loader>
      </button>
    </form>
    <div v-if="isLoading">Loading...</div>
  </div>
</template>

<script setup lang="ts">
import { useToastStore } from '@/stores/toast'
const stripePublicKey = import.meta.env.VITE_STRIPE_PUBLIC_KEY
import { ref, onMounted } from 'vue'
import { loadStripe } from '@stripe/stripe-js'
import { getSetupIntent } from '@/services/payment-service'
import { useAuthStore } from '@/stores/auth'
import CouponComponent from '../coupons-components/CouponComponent.vue'
import ButtonLoader from '@components/ui/button-loader/ButtonLoader.vue'

const isLoading = ref(true)
const paymentLoading = ref(false)
const product = ref(null)

let stripe
let elements
const store = useAuthStore()
const { alert } = useToastStore()

onMounted(async () => {
  isLoading.value = true
  stripe = await loadStripe(stripePublicKey)
  elements = stripe.elements()

  if (!store.user?.id) return

  const { intent } = await getSetupIntent()

  elements = stripe.elements({ clientSecret: intent, locale: 'en' })
  const paymentElement = elements.create('payment')
  paymentElement.mount('#payment-element')
  product.value = JSON.parse(localStorage.getItem('selectedPlan')!)
  isLoading.value = false
})

const handleSubmit = async () => {
  paymentLoading.value = true
  const { error } = await stripe.confirmSetup({
    elements,
    confirmParams: {
      return_url: `${window.location.origin}/payment-redirect`
    }
  })

  if (error) {
    alert(error.message, 'error')
  }
  paymentLoading.value = false
}
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.card-wrapper {
  @include sm {
    min-width: 490px;
  }

  form {
    margin: 0 auto;

    button {
      display: block;
      margin: 0 auto;
      margin-top: 2rem;
      min-width: 200px;

      &:disabled {
        background-color: $black;
      }
    }
  }

  .coupon {
    margin-top: 0.5rem;
  }

  .flex {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgb(230, 230, 230);
    padding-top: 1rem;
    margin-top: 1rem;
    p {
      font-family: -apple-system, 'system-ui', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
        'Helvetica Neue', sans-serif;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
</style>
