<template>
  <main v-if="data?.data">
    <div id="policySideMenu" class="menu" :style="!offsetTop ? {} : { top: offsetTop + 'px' }">
      <ul>
        <li v-for="(term, i) of data?.data" :key="i" class="ignore-list">
          <p :class="isActive(i)" class="nav-item" @click="scrollTo(i + '')">{{ i + 1 }}. {{ term.Title }}</p>
        </li>
      </ul>
    </div>
    <div id="policyContentContainer" class="content">
      <h3 class="top-borders">{{ title }}</h3>
      <div class="terms bottom-borders">
        <div class="intro-wrapper" v-if="data?.intro_copy">
          <StrapiBlocks :content="data?.intro_copy" :modifiers="modifier" :blocks="userBlocks" />
        </div>

        <ul>
          <li v-for="(term, i) of data?.data" :key="i" :id="i + ''" class="watch ignore-list">
            <p class="lead-text">{{ i + 1 }}. {{ term.Title }}</p>
            <StrapiBlocks v-if="term.text" :content="term.text" :modifiers="modifier" :blocks="userBlocks" />
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>
<script setup lang="ts">
import { h, onBeforeMount, nextTick, ref, onUpdated, onMounted } from 'vue';
import { type BlocksComponents, type ModifiersComponents, StrapiBlocks } from 'vue-strapi-blocks-renderer';

const current = ref<number>(0);
const offsetTop = ref<number>(0);

const userBlocks = {
  // Will include the class "mb-4" on all paragraphs
  paragraph: (props: any) => h('p', { class: 'mb-4' }, props.children),
  link: (props: any) => h('a', { class: 'link', href: props.url, target: '_blank' }, props.children)
};

const modifier = {
  underline: (props: any) => h('span', { class: 'underline' }, props.children)
};

const { data } = defineProps({
  data: {
    type: Object,
    required: true
  },
  title: {
    type: String,
    required: true
  }
});

function scrollTo(id: string) {
  const element = document.getElementById(id);
  if (element) {
    window.scrollTo({
      behavior: 'smooth',
      top: element.getBoundingClientRect().top - document.body.getBoundingClientRect().top - 160
    });
    current.value = +id;
  }
}

const isActive = (id: number) => {
  if (current.value === id) return 'active';
};

const setupInterceptor = () => {
  // Get current element position and use as top margin for viewport
  offsetTop.value = document.getElementById('policySideMenu').getBoundingClientRect().top;
  // We only care if the element is hidden upwards so set bottom margin
  // to the containers height plus 999 to ensure we wrap all borders/padding etc.
  const clientHeight = document.getElementById('policyContentContainer').clientHeight + 999;

  const observerOptions = {
    rootMargin: `-${offsetTop.value}px 0px ${clientHeight}px 0px`
  };

  const observerCallback = (entries) => {
    // This only tells us the changes...
    // If something is hidden then get the maximum id
    // If something is shown the get the minimum id
    const shownEntries = entries.filter((entry) => entry.isIntersecting).map((entry) => +entry.target.id);
    const hiddenEntries = entries.filter((entry) => !entry.isIntersecting).map((entry) => +entry.target.id);

    if (shownEntries.length) {
      const min = Math.min(...shownEntries);
      current.value = min;
    } else if (hiddenEntries.length) {
      const max = Math.max(...hiddenEntries);
      current.value = max + 1;
    }
  };

  const observer = new IntersectionObserver(observerCallback, observerOptions);

  document.querySelectorAll('.watch').forEach((i) => {
    if (i) {
      observer.observe(i);
    }
  });
};

onUpdated(async () => {
  await nextTick();
  setupInterceptor();
});
</script>
<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

main {
  margin-top: 44px;
  padding-bottom: 6.875rem;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @include lg {
    flex-direction: row;
    gap: 9.125rem;
  }

  .menu {
    background-color: $grey-2;
    color: $grey-border;
    height: fit-content;
    padding: 2.5rem 2rem;
    border-radius: 30px;
    max-width: 450px;
    @include lg {
      position: sticky;
      top: 200px;
    }

    .nav-item {
      cursor: pointer;
      margin-bottom: 1rem;

      &.active {
        color: $black;
        font-weight: 600;
      }
      &:hover {
        color: $black;
      }
    }
  }

  .content {
    flex: 1;
    max-width: 750px;
    h3 {
      width: 100%;
      padding: 2.5rem 2rem;
      background-color: $black;
      color: white;
      margin: 0;
    }

    .terms {
      padding: 2.5rem 2rem;
      background-color: white;

      .intro-wrapper {
        margin-left: 1rem;
        margin-bottom: 2rem;
      }

      li {
        margin-bottom: 2rem;

        .lead-text {
          margin-bottom: 1rem;
          font-weight: 600;
        }
      }
    }
  }
}

::v-deep(ul) {
  margin-left: 1rem;
  margin-bottom: 12px;

  li {
    &:not(.ignore-list) {
      list-style: disc;
    }
  }
}

::v-deep(ol) {
  margin-left: 1rem;
  margin-bottom: 12px;
  li {
    list-style: auto;
  }
}

::v-deep(img) {
  width: 100%;
  margin: 1.5rem 0;
}
</style>
