import type { Persona, type personaPayload } from '@/stores/persona'
import axios from 'axios'

const baseUrl = import.meta.env.VITE_API_URL

export const getCompanyPersonas = async (companyId: number) => {
  const response = await axios.get(`${baseUrl}/user-stories/${companyId}`)
  return response.data
}

export const createCompanyPersonas = async (payload: personaPayload) => {
  const response = await axios.post(`${baseUrl}/user-stories`, payload)
  return response.data
}

export const updateCompanyPersonas = async ({ id, additions }: { id: number; additions: string }) => {
  const response = await axios.put(`${baseUrl}/user-stories/${id}`, { additions })
  return response.data
}

export const pinPersona = async (id: number) => {
  const response = await axios.put(`${baseUrl}/persona/pin/${id}`)
  return response.data
}

export const regenerateSinglePersona = async (id: number) => {
  const response = await axios.post(`${baseUrl}/persona/regenerate/${id}`)
  return response.data
}

export const updateSinglePersona = async (persona: Persona) => {
  const response = await axios.put(`${baseUrl}/persona/update/${persona.id}`, persona)
  return response.data
}
