<template>
  <div class="wrapper">
    <div class="top top-borders">
      <h3>Payment <span v-if="!isLoading && isPaymentSuccess">confirmation</span></h3>
    </div>
    <div class="card bottom-borders">
      <div class="loading" v-if="isLoading"><p>Please wait, payment in progress.</p></div>
      <div v-if="!isLoading && isPaymentSuccess">
        <p class="lead-text">Reference #{{ invoiceNumber }}</p>
        <p class="content">
          Your payment was successful! Visit the My projects Page to continue creating your blueprint.
        </p>
        <div class="buttons">
          <a v-if="billingUrl" :href="billingUrl" target="_blank">
            <button class="secondary">Go to Billing</button>
          </a>

          <button v-if="returnUrl.includes('personas')" @click="() => router.push({ path: returnUrl })">
            Return to project
          </button>
          <button v-else @click="() => router.push({ name: 'projects', params: { id: user.id } })">My projects</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { onMounted, ref } from 'vue';
import {
  getPaymentSession,
  type PaymentSessionData,
  savePaymentIntent,
  deletePaymentSession
} from '@/services/payment-service';
import { useAuthStore } from '@/stores/auth';
import { storeToRefs } from 'pinia';
import { pay } from '@/services/payment-service';

const route = useRoute();
const router = useRouter();
const store = useAuthStore();
const { user } = storeToRefs(store);
const { setup_intent } = route.query as { setup_intent: string };
const coupon = localStorage.getItem('coupon') ?? null;
const couponType = localStorage.getItem('couponType') ?? null;
const isLoading = ref(false);
const isPaymentSuccess = ref(false);
const invoiceNumber = ref(null);
const billingUrl = ref(null);
const returnUrl = JSON.parse(JSON.stringify(localStorage.getItem('returnUrl')));

onMounted(async () => {
  try {
    isLoading.value = true;
    const sessionData: PaymentSessionData = await getPaymentSession();
    if (!setup_intent || !sessionData.plan_id) return;
    const intentResponse = await savePaymentIntent({ setup_intent });
    if (intentResponse.status === 200) {
      const res = await pay(sessionData.plan_id, sessionData.type, sessionData.upgrade_id, coupon, couponType);

      invoiceNumber.value = res.data.invoice_number;
      billingUrl.value = res.data.url;
      isPaymentSuccess.value = true;
      await deletePaymentSession();
      localStorage.removeItem('coupon');
      localStorage.removeItem('couponType');
    }
  } catch (error) {
    isPaymentSuccess.value = false;
    isLoading.value = false;
  }

  isLoading.value = false;
});
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.wrapper {
  width: 70%;

  margin: 5rem auto;

  .top {
    padding: 2rem 2.5rem;
    background-color: $black;
    color: white;
  }

  .card {
    background-color: white;
    padding: 2rem 2.5rem;
    display: flex;
    height: 400px;
    justify-content: center;
    align-items: center;

    .content {
      margin: 2rem 0;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @include sm {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }
}
</style>
