import {
  getCompanyMessaging,
  pinMessaging,
  regenerateCompanyMessaging,
  updateCompanyMessaging
} from '@/services/messaging-service';
import { defineStore } from 'pinia';

export const useMessagingStore = defineStore({
  id: 'messages',
  state: (): messagingInterface => ({ messaging: [], summary: null }),
  actions: {
    async pinOne(id: number) {
      const tempState = [...this.messaging];
      const response = await pinMessaging(id);
      this.messaging = [];
      this.messaging = tempState.map((item) => {
        if (item.id !== response.id) return item;

        return response;
      });
    },
    async getMessaging(id: number) {
      const res = await getCompanyMessaging(id);
      if (res.messaging) this.messaging = res.messaging;
      if (res.summary) this.summary = res.summary.summary;
    },
    async updateMessaging(id: number) {
      const response = await updateCompanyMessaging({ id, messaging: this.messaging });
      this.messaging = [];
      this.messaging = response;
    },
    async regenerate(id: number) {
      const res = await regenerateCompanyMessaging(id);
      if (res) {
        this.messaging = res;
      } else {
        this.messaging = [];
      }
    },
    async clearStore() {
      this.messaging = [];
    }
  },
  getters: {
    onlineMarketing: (state) => state.messaging.filter((item) => item.campaign === 'online'),
    offlineMarketing: (state) => state.messaging.filter((item) => item.campaign === 'offline'),
    promoMarketing: (state) => state.messaging.filter((item) => item.campaign === 'promos'),
    inactive: (state) => state.messaging.filter((item) => !item.is_active)
  }
});

type messagingInterface = {
  messaging: Messaging[];
  summary: string | null;
};

export type Messaging = {
  title: string;
  id: number;
  pinned: boolean;
  structure: string;
  content: string;
  campaign: string;
  is_active: boolean;
};

export type Post = {
  title: string;
  body?: string;
  except: string;
};
