<template>
  <section class="how-it-works">
    <div class="content body">
      <div v-if="benefits" class="benefits">
        <div class="what-you-get">
          <h2>What are the benefits?</h2>
          <p class="lead-text">
            Discover the actional insights we provide in seconds after only answering two questions.
          </p>
        </div>

        <div class="benefit" v-for="(benefit, i) in benefits.benefit" :key="i">
          <div class="piggy-wrapper">
            <img class="piggy" :src="benefit.image.data[0].attributes.url" alt="" />
          </div>
          <div class="benefit-text">
            <h3>{{ benefit.title }}</h3>
            <p>{{ benefit.content }}</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';
import { getBenefits } from '../../services/strapi-service';

const benefits = ref(null);

onMounted(async () => {
  const res = await getBenefits();
  benefits.value = res;
});
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

h1 {
  margin-bottom: 0.5rem;
}

h3 {
  margin-bottom: 1rem;
}

.how-it-works {
  width: 100vw;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: $mid-cyan;

  padding: 60px;

  .content {
    margin: 0 auto;
    .benefits {
      @include laptop {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: auto 1fr;
        gap: 24px;
      }

      .what-you-get {
        margin-bottom: 2.5rem;
        h2 {
          margin-bottom: 0.75rem;
        }

        @include laptop {
          margin-top: 40px;
        }
      }
    }
    .benefit {
      margin-bottom: 2rem;
      grid-auto-rows: 1fr;
      background-color: $dark-cyan;
      border-radius: 30px;
      @include laptop {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;
      }
      .piggy-wrapper {
        background-color: $light-cyan;
        border-radius: 30px;
        position: relative;
        height: 230px;
        .piggy {
          display: block;
          margin: 0 auto;
          position: absolute;
          top: -10px;
          left: 0;
          right: 0;
          margin: 0 auto;
          height: 256px;
        }
      }

      .benefit-text {
        background-color: $dark-cyan;
        border-bottom-left-radius: 30px;
        border-bottom-right-radius: 30px;
        padding: 1.75rem;
        @include laptop {
          padding: 3rem;
        }

        flex-grow: 1;
        h3 {
          margin-bottom: 0.75rem;
        }
        p {
          font-size: 0.875rem;
          @include laptop {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
</style>
