<script setup lang="ts">
import { RouterView } from 'vue-router'
import MainNavigation from '@components/navigation/main/MainNavigation.vue'
import FooterComponent from '@components/footer/FooterComponent.vue'
import { useConfigStore } from './stores/config'
import { onMounted, ref } from 'vue'
import TooltipComponent from '@components/ui/tooltip/TooltipComponent.vue'
import ToastComponent from '@components/ui/toast-component/ToastComponent.vue'
import { useRecaptchaProvider } from 'vue-recaptcha'
import { bootstrap } from 'vue-gtag'

const store = useConfigStore()
const status = ref<string | null>(null)
useRecaptchaProvider()
onMounted(async () => {
  await store.getConfig()
})

function enableGA() {
  bootstrap().then((gtag) => {
  })
}

function cookieStatus(newStatus: string) {
  if (newStatus === 'accept') {
    enableGA()
  }
  status.value = newStatus
}

function cookieClickedAccept() {
  status.value = 'accept'
  bootstrap().then((gtag) => {
  })
}

function cookieClickedDecline() {
  status.value = 'decline'
}
</script>

<template>
  <section class="wrapper" id="parent-wrapper">
    <section class="header body">
      <MainNavigation />
    </section>
    <section class="body">
      <RouterView />
    </section>
    <section class="upgrade-component-teleport full-width"></section>
    <section class="footer">
      <FooterComponent />
    </section>
  </section>
  <ToastComponent class="toast" />
  <vue-cookie-accept-decline
    :debug="false"
    :disableDecline="false"
    :showPostponeButton="false"
    @clicked-accept="cookieClickedAccept"
    @clicked-decline="cookieClickedDecline"
    @status="cookieStatus"
    elementId="cookie-item"
    position="bottom-left"
    ref="cookie-item"
    transitionName="slideFromBottom"
    type="floating"
  >
    <!-- Optional -->
    <template #postponeContent>&times;</template>

    <!-- Optional -->
    <template #message>
      <h3>We use cookies</h3>
      <div class="content">
        <p>We use some essential cookies to make this website work.</p>
        <p>
          We’d like to set additional cookies to understand how you use our site which allows us to enhance your
          browsing experience, and tailor product marketing to your preferences.
          <a href="/cookie_policy" target="_blank">Learn More</a>
        </p>
      </div>
    </template>

    <!-- Optional -->
    <template #acceptContent>Accept additional cookies</template>

    <!-- Optional -->
    <template #declineContent>Reject additional cookies</template>
  </vue-cookie-accept-decline>
</template>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.toast {
  z-index: 99999;
}

.wrapper {
  background-color: $grey-bg;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  contain: paint;

  .body {
    flex: 1;
  }

  .upgrade-component-teleport {
    position: absolute;
    bottom: 1065px;
    z-index: 99;

    @include sm {
      bottom: 490px;
    }

    @include lg {
      bottom: 465px;
    }
  }

  .footer {
    z-index: 2;
    background-color: $black;
  }
}

.header {
  position: sticky;
  top: 0;
  z-index: 999;
  margin-bottom: 1rem;
  @include sm {
    margin-bottom: 1rem;
  }
}

#cookie-item {
  max-width: 800px;
  border-radius: 1rem;

  :deep(.cookie__floating__content) {
    max-height: unset;
    padding: 0px;

    h3 {
      padding: 2rem 2.5rem;
      background-color: $black;
      color: white;
      width: 100%;
      margin: 0;
    }

    .content {
      padding: 2rem 2.5rem;

      a {
        text-decoration: underline;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  :deep(.cookie__floating__buttons) {
    padding: 0 2.5rem;
    gap: 0.5rem;

    flex-direction: column;
    @include sm {
      gap: 2rem;
      flex-direction: row;
    }

    button {
      border-radius: 150px;
      background: $black;
      border: 1px solid $black;
      color: white;
      padding: 0 2.25rem;
      text-align: center;
      width: fit-content;
      display: block;
      font-size: 0.875rem;
      line-height: 160%;
      font-weight: 600;
      height: 64px;
      margin-bottom: 1rem;
      width: 100%;

      transition: all ease-in-out 0.2s;

      &:hover {
        cursor: pointer;
        background: transparent;
        color: $black;
      }

      &.cookie__floating__buttons__button--decline {
        background: transparent;
        color: $black;

        &:hover {
          cursor: pointer;
          background: $black;
          color: white;
          border: 1px solid white;
        }

        &:disabled {
          background: transparent;
          color: $btn-disabled-text;
          border: 1px solid $btn-disabled;
        }
      }

      .cookie__floating__buttons__button--accept {
      }
    }
  }
}
</style>
