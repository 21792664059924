<template>
  <div class="reset-wrapper top-borders bottom-borders">
    <div class="top top-borders"><h3>Create a strong password</h3></div>

    <form class="bottom-borders" @submit="onSubmit">
      <p>
        Your password must be at least eight characters, should include a combination of both lowercase and uppercase
        letters and at least one number or symbol.
      </p>
      <div class="input-wrapper">
        <label>New password</label>
        <input
          type="password"
          placeholder="Password"
          v-model="password"
          :class="{
            error: passwordErrors?.length && password,
            success: !passwordErrors?.length && password
          }"
        />
        <div class="error" v-if="passwordErrors.length && password">
          <p>Your password needs to contain:</p>
          <ul>
            <li v-for="(error, i) of passwordErrors" :key="i">{{ error }}</li>
          </ul>
        </div>
      </div>
      <div class="input-wrapper">
        <label>New password, again</label>
        <input
          type="password"
          placeholder="Password"
          v-model="confirmPassword"
          :class="{
            error: confirmPasswordErrors?.length && confirmPassword,
            success: !confirmPasswordErrors?.length && confirmPassword
          }"
        />
        <div class="error" v-if="confirmPasswordErrors.length && confirmPassword">
          <ul>
            <li v-for="(error, i) of confirmPasswordErrors" :key="i">{{ error }}</li>
          </ul>
        </div>
      </div>
      <button :disabled="!meta.valid">Reset password</button>
    </form>
    <p class="center" @click="backToLogin()">Back to login ></p>
  </div>
</template>

<script setup lang="ts">
import { useField, useForm } from 'vee-validate';
import { object, string } from 'yup';
import * as yup from 'yup';
import { useAuthStore } from '@/stores/auth';
import { useRoute, useRouter } from 'vue-router';
import { useToastStore } from '@/stores/toast';

const store = useAuthStore();
const route = useRoute();
const router = useRouter();
const { alert } = useToastStore();
const { token } = route.params as { token: string };
const { id } = route.query as { id: string };

const schema = object({
  password: string()
    .required()
    .min(8, 'At least 8 characters')
    .matches(/[a-z]/, 'A lowercase character')
    .matches(/[A-Z]/, 'An uppercase character')
    .matches(/[0-9\W]/, 'At least 1 number or symbol'),
  confirmPassword: string()
    .required()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
});

const { meta, handleSubmit } = useForm({
  validationSchema: schema
});

const { value: confirmPassword, errors: confirmPasswordErrors } = useField(() => 'confirmPassword');
const { value: password, errors: passwordErrors } = useField(() => 'password');

const onSubmit = handleSubmit(async () => {
  try {
    const res = await store.setNewPassword({
      password: password.value as string,
      password_confirmation: confirmPassword.value as string,
      token,
      id: +id
    });
    alert(res.success, 'success');
    backToLogin();
  } catch (error) {
    alert(error.response.data.error ?? 'Failed to set a new password. Please try again.', 'error');
  }
});

const backToLogin = () => {
  store.toggleLogin();
  router.push('/');
};
</script>
<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';
.reset-wrapper {
  width: 100%;
  max-width: 600px;
  margin: 6.875rem auto;
  margin-top: 44px;
  background-color: $grey-3;
  height: wrap-content;
  .top {
    background-color: $black;
    color: white;
    padding: 3rem;

    h3 {
      margin: 0;
    }
  }

  form {
    margin: 0;
    padding: 3rem 3rem 1.875rem 3rem;
    background-color: $grey-2;

    .input-wrapper {
      margin-top: 1rem;
      input {
        background-color: white;
      }
    }

    button {
      display: block;
      margin: 2rem auto;
    }
  }

  .center {
    text-align: center;
    padding: 3rem;
    cursor: pointer;
  }
}
</style>
