<template>
  <div class="verify-wrapper top-borders bottom-borders">
    <div class="top top-borders">
      <h3>Verification required</h3>
    </div>

    <div class="content bottom-borders">
      <p>
        Thank you for registering! To complete the process, please check your email and click on the verification link
        we've sent you. Once your email address is verified, you can log in to access your account.
      </p>
      <div class="buttons">
        <button @click="backToLogin()">Go to login</button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { useAuthStore } from '../../stores/auth';

const store = useAuthStore();
const router = useRouter();

const backToLogin = () => {
  store.toggleLogin(true);
  router.push('/login');
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.arrow {
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  justify-content: center;

  .chevron {
    height: 16px;
    width: 16px;
    margin-left: 0.5rem;
  }

  p {
    padding: 2.5rem 0 !important;
  }
}
.verify-wrapper {
  width: 100%;
  max-width: 600px;
  margin: 44px auto 6.875rem auto;
  background-color: $grey-3;
  height: wrap-content;
  .top {
    background-color: $black;
    color: white;
    padding: 3rem;

    h3 {
      margin: 0;
    }
  }

  .content {
    margin: 0;
    padding: 3rem 3rem 1.875rem 3rem;
    background-color: $grey-2;

    .buttons {
      display: flex;
      button {
        margin: 40px auto;
      }
    }
  }

  .center {
    text-align: center;
    padding: 3rem;
    cursor: pointer;
  }
}

.spinner {
  margin: 0 auto 6.875rem auto;
  width: fit-content;

  :deep(.image-wrapper) {
    margin: 0 auto;
  }
  h3 {
    text-align: center;
  }
}
</style>
