import { useCompanyStore } from '@/stores/company'

export const hasPaid = async (to: any, from: any, next: any) => {
  const companyStore = useCompanyStore()
  try {
    const canView = await companyStore.checkCanViewFullProject(to.params.id)
    return canView ? next() : next({ name: 'plans' })
  } catch (error: any) {
    if (error.response.status === 422) {
      return '/'
    }
  }
}
