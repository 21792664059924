import axios from 'axios';
import { useToastStore } from '@/stores/toast';
import router from '../router/index';
import { useAuthStore } from '../stores/auth';

export default function setup() {
  const { alert } = useToastStore();
  const baseUrl = import.meta.env.VITE_API_HOST;
  const authStore = useAuthStore();

  axios.interceptors.request.use(async (request) => {
    request.withCredentials = true;

    if (request.url?.includes(baseUrl)) {
      request.xsrfCookieName = 'XSRF-TOKEN';
      request.withXSRFToken = true;
    }

    if (request.headers.Authorization) return request;

    request.headers.Accept = 'application/json';

    if (request.headers['Content-Type'] !== 'multipart/form-data') {
      request.headers['Content-Type'] = 'application/json';
    }
    const user = localStorage.getItem('user');
    if (user) {
      const user_id = JSON.parse(user).id;
      request.headers['user_id'] = user_id;
    }

    return request;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error.response.status === 422) {
        return Promise.reject(error);
      }

      alert(error.response.data.message ?? error.response.data.error, 'error');
      if (error.response.status === 401 || error.response.status === 419) {
        authStore.logout();
      }
      return Promise.reject(error);
    }
  );
}
