<template>
  <div class="cost" v-if="costItems">
    <div class="cost-items">
      <h2 class="title">How much does it cost</h2>
      <div class="cost-item" v-for="(item, i) of costItems" :key="i">
        <div class="top top-borders">
          <h3>{{ item.title }}</h3>
          <p class="lead-text">{{ item.description }}</p>
        </div>
        <div class="bottom bottom-borders">
          <h1 class="price">£{{ item.price }}</h1>
          <button @click="handleProductSelected()" class="secondary">Discover more</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CostItem } from '@services/payment-service';
import { useHead } from '@unhead/vue';
import { computed, onBeforeMount, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { getPrices } from '../../services/payment-service';
import { constructHead } from '../../utils/constructHead';

const costItems: CostItem[] = ref(null);
const selectedPlan = ref(null);
const router = useRouter();

const handleProductSelected = () => {
  router.push({ name: 'plans' });
};

onMounted(async () => {
  const res = await getPrices();
  costItems.value = res.filter((item: CostItem) => item.type === 'PROJECT');
});
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

h1 {
  margin-bottom: 0.5rem;
}

button {
  background-color: transparent;
  width: 100%;
  @include laptop {
    width: fit-content;
    margin-left: 0;
  }
}

.title {
  margin-bottom: 2.5rem;
  @include laptop {
    margin-top: 40px;
  }
}
.cost {
  .cost-items {
    @include laptop {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 1rem;
    }
    .cost-item {
      margin: 1rem 0;
      display: flex;
      flex-direction: column;

      border-radius: 30px;

      &:nth-child(2n) {
        .top {
          background-color: $dark-green;
        }

        .bottom {
          background-color: $mid-green;
        }
      }
      .top {
        background-color: $dark-grey;
        @include laptop {
          padding: 2rem 2.5rem;
        }
        padding: 1.75rem;
        flex: 1;
      }

      .bottom {
        background-color: $mid-grey;
        @include laptop {
          padding: 2rem;
        }
        padding: 1.75rem;

        button {
          border-radius: 220px;
        }

        .price {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
</style>
