<template>
  <div class="wrapper" :class="background">
    <h3 :class="isPinned ? 'pinned' : ''">
      {{ title }}
    </h3>
    <ul v-if="!isEditing" class="bottom">
      <li :key="i" :class="isPinned ? 'pinned' : ''" class="item" v-for="(item, i) in content">{{ item }}</li>
    </ul>
    <ul class="bottom" v-else>
      <li v-for="(item, i) in content" :key="i">
        <textarea
          oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
          type="text"
          v-model="content[i]"
          :ref="
            (el) => {
              if (!el) return;
              el.style.height = '150px';
            }
          "
          :onfocus="() => (images[i].style.display = 'block')"
          :onBlur="() => (images[i].style.display = 'none')"
          :class="background"
        ></textarea>
        <div ref="images" class="delete-icon">
          <img class="grey" src="@assets/icons/close-grey.svg" alt="" />
          <img :onmousedown="() => removeItem(i)" class="yellow" src="@assets/icons/close-yellow.svg" alt="" />
        </div>
        <div class="cross"><img src="../../../assets/icons/close-yellow.svg" alt="" /></div>
      </li>
      <button @click="addNewItem()" class="add_item secondary">
        <span class="button-icon"></span>
      </button>
    </ul>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

const emptyItem = '';
const images = ref([]);

const { content, title, isEditing, background, isPinned } = defineProps({
  title: {
    type: String,
    required: true
  },
  content: {
    type: Array,
    required: true
  },
  isEditing: {
    type: Boolean,
    required: true
  },
  background: String,
  isPinned: {
    type: Boolean,
    required: true
  }
});

const addNewItem = () => {
  content.push(emptyItem);
};

const removeItem = (index: number) => {
  content.splice(index, 1);
};
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.wrapper {
  border-radius: 30px;
  overflow: hidden;
  flex: 1;
  background-color: $mid-grey;

  &.white {
    background-color: $light-grey;

    .bottom {
      .item {
        background-color: $mid-grey;
      }
    }
  }

  h3 {
    background-color: $black;
    color: white;
    padding: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    margin-bottom: 0;

    &.pinned {
      background-color: $grey-4;
      color: $btn-disabled-text;
    }
  }

  .bottom {
    padding: 1.5rem;

    .item {
      padding: 1rem;
      background-color: $light-grey;
      border-radius: 15px;

      &:not(:last-child) {
        margin-bottom: 0.75rem;
      }

      &.pinned {
        background-color: $grey-2;
      }
    }

    li {
      position: relative;

      .delete-icon {
        position: absolute;
        right: 1rem;
        top: 1.2rem;
        padding: 0.375rem;
        cursor: pointer;
        display: none;
        z-index: 2;

        img {
          height: 1.5rem;
          width: 1.5rem;
        }

        .grey {
          display: block;
        }

        .yellow {
          display: none;
        }

        &:hover {
          background-color: $black;
          border-radius: 0.6rem;

          .grey {
            display: none;
          }

          .yellow {
            display: block;
          }
        }
      }

      &.pinned {
        color: $btn-disabled-text;
      }

      textarea {
        width: 100%;
        padding: 1rem 1.5rem 1rem 1rem;
        background-color: $light-grey;
        border-radius: 15px;
        border: none;
        min-width: 100%;
        resize: none;
        padding-right: 3rem;
        border: 2px solid transparent;

        &:not(:last-child) {
          margin-bottom: 0.75rem;
        }

        &:focus-visible {
          outline: 2px solid black;
        }

        &.white {
          background-color: white;
        }

        &:hover {
          border: 2px solid $black;
        }
      }

      .cross {
        display: none;
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        padding: 6px;
        background-color: $black;
        height: 2.75rem;
        width: 2.75rem;
        border-radius: 5px;
      }
    }

    button {
      &.add_item {
        height: 4.438rem;
        width: 4.438rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;

        .button-icon {
          width: 28px;
          height: 28px;
          background-repeat: no-repeat;
          background-position: center;
          background-size: 24px;
          background-image: url('../../../assets/icons/cross.svg');
        }

        &:hover {
          .button-icon {
            background-image: url('../../../assets/icons/cross-yellow.svg');
          }
        }

        @include lg {
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
