<script setup lang="ts">
import { useHead } from '@unhead/vue'
import { computed, onBeforeMount, onMounted, ref } from 'vue'
import { BlogPost, getLearningCentrePage, getPosts } from '../services/strapi-service'
import { constructHead } from '../utils/constructHead'
import { VuePaginate } from '@svifty7/vue-paginate'

type BlogMetaType = {
  page: number;
  pageCount: number;
  pageSize: number;
  total: number
}

const posts = ref<BlogPost[]>(null)
const blogsMeta = ref<BlogMetaType>(null)

function formatDate(date) {
  const options = { day: '2-digit', month: 'long', year: 'numeric' }
  const formatter = new Intl.DateTimeFormat('en-US', options)
  const formattedDate = formatter.format(date)

  const parts = formattedDate.split(' ')

  const day = parts[1].replace(',', '')
  const month = parts[0]
  const year = parts[2]

  let dayWithSuffix
  switch (parseInt(day)) {
    case 1:
    case 21:
    case 31:
      dayWithSuffix = day + 'st'
      break
    case 2:
    case 22:
      dayWithSuffix = day + 'nd'
      break
    case 3:
    case 23:
      dayWithSuffix = day + 'rd'
      break
    default:
      dayWithSuffix = day + 'th'
  }

  return `${dayWithSuffix} ${month} ${year}`
}

const refetchBlogs = async (page: number = 1) => {
  const res = await getPosts(page)
  posts.value = res.posts
  blogsMeta.value = res.meta
  window.scrollTo(0, 0)
}

const end = () => {
  if (!blogsMeta?.value) return 1
  return blogsMeta?.value?.total < blogsMeta?.value?.pageSize ? blogsMeta?.value?.total : blogsMeta?.value?.pageSize * blogsMeta?.value.page > blogsMeta?.value?.total ? blogsMeta?.value?.total : blogsMeta?.value?.pageSize * blogsMeta?.value.page
}
const start = () => {
  if (!blogsMeta?.value) return 1
  return blogsMeta?.value.page === 1 ? 1 : (blogsMeta?.value.pageSize * blogsMeta?.value.page) - blogsMeta?.value?.pageSize
}


onMounted(async () => {
  await refetchBlogs()
})

onBeforeMount(async () => {
  const res = await getLearningCentrePage()

  if (res.seo) {
    useHead(computed(() => constructHead(res.seo!)))
  }
})
</script>

<template>
  <main>
    <h3 class="heading">Learning Centre</h3>
    <div class="items">
      <div class="item" v-for="(item, i) in posts" :key="i">
        <router-link :to="`/learning-centre/${item.slug}`">
          <div class="top top-borders"><img :src="item.featured" alt="Featured image" /></div>
          <div class="bottom bottom-borders">
            <p class="small">{{ formatDate(new Date(item.published)) }}</p>
            <h3>{{ item.title }}</h3>
          </div>
        </router-link>
      </div>
    </div>
    <div class="pagination">
      <div class="left"><p>
        {{ start() }} -
        {{ end() }}
        of {{ blogsMeta?.total }}
      </p></div>
      <div class="right">
        <vue-paginate :page-count="blogsMeta?.pageCount" :click-handler="refetchBlogs" container-class="pagination"
                      page-class="pointer" prev-class="link" next-class="link" :page-range="4" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

main {
  margin-top: 44px;
  margin-bottom: 110px;

  h3 {
    margin-bottom: 4rem;
    width: 100%;
    font-size: 20px;

    &.heading {
      text-align: center;
      font-size: 42px;
    }
  }

  .items {
    .item {
      margin-bottom: 2rem;

      a {
        height: 100%;
        display: flex;
        flex-direction: column;

        .top {
          overflow: hidden;
        }

        .bottom {
          transition: all 0.2s ease-in-out;
          background-color: $grey-2;
          padding: 1rem 2rem 1.5rem 2rem;
          flex: 1;

          h3 {
            margin: 0;
            width: fit-content;
          }
        }

        .small {
          font-size: 16px;
          color: $btn-disabled-text;
        }
      }

      img {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        width: 100%;
        height: 200px;
        display: block;
        object-fit: cover;
      }

      cursor: pointer;

      &:hover {
        .bottom {
          background-color: $black;
          color: white;

          h3 {
            color: $mid-green;
          }
        }

        a {
          .small {
            color: white;
          }
        }
      }
    }

    @include sm {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 1rem;
    }
    @include lg {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem;
    }
  }

  :deep(.pagination) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    border: 2px solid transparent !important;

    .active {
      border: 2px solid $black !important;
      padding: 0 0.5rem;
    }

    .pointer {
      cursor: pointer;
      padding: 0 0.5rem;

    }

    .link {
      cursor: pointer;

      &.disabled {
        color: $btn-disabled-text;

        a {
          color: $btn-disabled;
        }
      }
    }

    .chevron {
      height: 16px;
      width: 16px;
    }

    .left-chevron {
      transform: rotate(180deg);
    }


  }
}
</style>
