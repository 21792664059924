<script setup lang="ts">
const { isEditing } = defineProps({
  isEditing: {
    type: Boolean,
    default: true
  },
  disabled: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['edit']);
</script>

<template>
  <button class="icon_button secondary" @click="() => emit('edit')" :disabled="disabled">
    <span v-show="!isEditing" class="regenerate-button-icon"></span>
    {{ isEditing ? 'Save' : 'Edit' }}
  </button>
</template>

<style scoped lang="scss">
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

button {
  width: 100%;
  margin: 1.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.785rem;

  .regenerate-button-icon {
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    background-image: url('@assets/icons/pencil-black.svg');
  }

  &:disabled {
    &:hover {
      cursor: default;
    }
    .regenerate-button-icon {
      background-image: url('@assets/icons/pencil-disabled.svg');
    }
  }

  &:not(:disabled) {
    &:hover {
      .regenerate-button-icon {
        background-image: url('@assets/icons/pencil.svg');
      }
    }
  }

  @include sm {
    width: fit-content;
  }
}
</style>
