<template>
  <div class="persona-style-wrapper">
    <h2 class="margin-bottom">Change the style</h2>
    <p class="lead-text margin-bottom">You can use a regeneration to change how your audience looks.</p>
    <div class="styles-wrapper">
      <div
        class="art"
        :class="company?.art_style?.toLowerCase() === item.name.toLowerCase() ? 'active' : ''"
        v-for="(item, i) in artStyles"
        :key="i"
        @click="selectArt(item.name)"
      >
        <div class="top">
          <img :src="item.url" alt="" />
        </div>
        <div class="bottom">{{ item.name }}</div>
      </div>
    </div>
  </div>
  <regeneration-popup v-if="showWarning" @proceed="changeArtStyle()" @close-popup="() => (showWarning = false)" />
</template>

<script setup lang="ts">
import { useCompanyStore } from '@/stores/company'
import { storeToRefs } from 'pinia'
import { onMounted, ref, defineEmits } from 'vue'
import { getArtStyles } from '../../../services/strapi-service'
import { useToastStore } from '../../../stores/toast'
import RegenerationPopup from '@components/ui/regeneration-popup/RegenerationPopup.vue'

const { alert } = useToastStore()

const { company } = storeToRefs(useCompanyStore())
const isLoading = ref(false)
const artStyles = ref<{ name: string; url: string }[]>([])
const selectedArt = ref<string | undefined>(company.value?.art_style)
const showWarning = ref<boolean>(false)
const emit = defineEmits(['refresh', 'loading'])

const selectArt = (name: string) => {
  selectedArt.value = name
  showWarning.value = true
}

const changeArtStyle = async () => {
  showWarning.value = false

  try {
    isLoading.value = true
    emit('loading')
    await useCompanyStore().updatePersonaArtStyle(company.value.id, selectedArt.value)
    await useCompanyStore().getCompany(company?.value?.id)
    emit('loading')
    emit('refresh')
  } catch (error) {
    emit('loading')
    alert('Failed to change persona art, please try again', 'error')
  }
}

onMounted(async () => {
  const art = await getArtStyles()

  const items = art.map((item) => {
    return {
      name: item.Name,
      url: item.Image.data[0].attributes.url
    }
  })
  artStyles.value = items
})
</script>

<style lang="scss">
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.persona-style-wrapper {
  margin-bottom: 40px;

  p {
    &.margin-bottom {
      margin-bottom: 40px;
    }
  }

  .margin-bottom {
    margin-bottom: 1.25rem;
  }

  .styles-wrapper {
    display: grid;
    gap: 1rem;
    @include sm {
      grid-template-columns: repeat(2, 1fr);
    }
    @include lg {
      display: flex;
    }

    .art {
      cursor: pointer;
      background-color: $grey-2;
      border-radius: 30px;
      overflow: hidden;
      display: flex;
      flex: 1;
      border: 2px solid transparent;

      &:not(.active) {
        .top,
        .bottom {
          opacity: 0.5;
        }
      }

      &.active {
        background-color: $black;

        .bottom {
          color: white;
        }
      }

      &:hover {
        border: 2px solid $black;
      }

      @include sm {
        flex-direction: column;
      }

      .top {
        img {
          width: 88px;
          height: 100%;
          min-height: 95px;
          object-fit: cover;
          @include sm {
            width: 100%;
            min-height: 13rem;
          }
        }
      }

      .bottom {
        color: $black;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        @include sm {
          padding: 2rem;
        }
      }
    }
  }
}
</style>
