<template>
  <div class="wrapper">
    <div class="top top-borders">
      <h3>Payment confirmation</h3>
    </div>
    <div class="card bottom-borders">
      <div>
        <p v-if="id" class="lead-text">Reference #{{ id }}</p>
        <p class="content">
          Your payment was successful! Visit the My projects Page to continue creating your blueprint.
        </p>
        <div class="buttons">
          <a v-if="billingPortalUrl" :href="billingPortalUrl" target="_blank">
            <button class="secondary">Go to Billing</button>
          </a>
          <button @click="() => router.push({ name: 'projects', params: { id: store.user.id } })">My projects</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'

const route = useRoute()
const router = useRouter()
const store = useAuthStore()
const { billingPortalUrl } = storeToRefs(store)
const { id } = route.query
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.wrapper {
  width: 70%;

  margin: 5rem auto;

  .top {
    padding: 2rem 2.5rem;
    background-color: $black;
    color: white;
  }

  .card {
    background-color: white;
    padding: 2rem 2.5rem;
    display: flex;
    height: 400px;
    justify-content: center;
    align-items: center;

    .content {
      margin: 2rem 0;
    }

    .buttons {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      @include sm {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }
}
</style>
