import { defineStore } from 'pinia'
import {
  getCompanySocials,
  pinSocialPost,
  regenerateCompanySocials,
  updateCompanySocials
} from '../services/social-posts-service'

export const useSocialStore = defineStore({
  id: 'social-media',
  state: (): socialInterface => ({ socialPlatforms: [] }),
  actions: {
    async pinOne(id: number) {
      const tempState = [...this.socialPlatforms]
      await pinSocialPost(id)
      this.socialPlatforms = []
      this.socialPlatforms = tempState.map((item) => {
        return {
          ...item,
          social_posts: item.social_posts?.map((post: SocialPost) => {
            if (post.id !== id) return post
            return { ...post, pinned: true }
          })
        }
      })
    },
    async getSocialPosts(id: number) {
      this.socialPlatforms = await getCompanySocials(id)
    },
    async updateSocialPosts(id: number) {
      const posts: SocialPost[] = []

      this.socialPlatforms.forEach((element: SocialPlatform) => {
        posts.push(...element.social_posts!)
      })
      await updateCompanySocials({ id, data: posts })
    },
    async regenerate(id: number) {
      this.socialPlatforms = await regenerateCompanySocials(id)
    },
    async clearStore() {
      this.socialPlatforms = []
    }
  },
  getters: {
    unpinnedSocials: (state) =>
      state.socialPlatforms.filter((social) => {
        if (social.social_posts) return social.social_posts.filter((post) => !post.pinned).length > 0
      }),
    inactive: (state) => state.socialPlatforms.filter((social) => social?.social_posts?.some((post) => !post.is_active))
  }
})

type socialInterface = {
  socialPlatforms: SocialPlatform[]
}

export type SocialPlatform = {
  id: number
  platform: string
  description: string
  social_posts?: SocialPost[]
  is_active: boolean
}

export type SocialPost = {
  title: string
  id: number
  pinned: boolean
  content: PostContent[]
  is_active: boolean
}

export type PostContent = {
  title: string
  content: string
}
