<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { onMounted, ref } from 'vue'
import { useAuthStore } from '../stores/auth'
import UserSettingsComponent from '@components/account/user-settings/UserSettingsComponent.vue'
import EmailNotificationComponent from '@components/account/email-notifications/EmailNotificationComponent.vue'

const store = useAuthStore()
const { user, billingPortalUrl } = storeToRefs(store)

const activeSection = ref<string>('settings')
const tabs: Tab[] = [
  { label: 'User settings', name: 'settings' },
  { label: 'Billing and invoices', name: 'billing' },
  { label: 'Notifications', name: 'notifications' }
]

const changeTab = (tab: Tab) => {
  if (tab.name === 'billing' && billingPortalUrl) {
    window.open(billingPortalUrl.value, '_blank')
  } else {
    activeSection.value = tab.name
  }
}

onMounted(async () => {
  await store.setBillingPortalUrl()
})

type Tab = { label: string; name: string };
</script>

<template>
  <div class="account-wrapper">
    <div class="margin-bottom">
      <h3>My account</h3>
    </div>
    <div class="margin-bottom tabs">
      <div
        class="tab full-span"
        v-for="(tab, i) of tabs"
        :key="i"
        @click="changeTab(tab)"
        :class="activeSection === tab.name ? activeSection + ' active' : ''"
      >
        <p class="lead-text">{{ tab.label }}</p>
      </div>
    </div>
    <div v-if="activeSection === 'settings'">
      <user-settings-component />
    </div>
    <div v-if="activeSection === 'notifications'">
      <email-notification-component />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.account-wrapper {
  margin-top: 44px;
  padding: 0 0 6.875rem 0;

  .settings {
    background-color: $mid-green;
  }

  .notifications {
    background-color: $mid-pink;
  }

  .full-span {
    width: 100%;
  }

  .section {
    margin-bottom: 2rem;
  }

  .margin-bottom {
    margin-bottom: 2.5rem;

    h3 {
      font-size: 42px;
    }
  }

  .tabs {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    @include sm {
      flex-direction: row;
      justify-content: space-between;
    }

    .tab {
      padding: 1rem;
      background-color: $grey-2;
      border-radius: 30px;
      transition: all 0.3s ease-in-out;
      text-align: center;
      cursor: pointer;

      @include sm {
        padding: 2.5rem;
      }

      &.active {
        p {
          font-weight: 600;
        }
      }

      &.settings {
        background-color: $mid-green;
      }

      &.notifications {
        background-color: $mid-pink;
      }
    }
  }
}
</style>
