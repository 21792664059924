import { defineStore } from 'pinia'
import {
  createEmailUser,
  deleteAccount,
  refreshUserDetails,
  updatePreferences,
  updateUser,
  updateUserPassword
} from '@/services/user-service'
import { forgotPassword, login, logOut, resetPassword } from '@/services/auth-service'
import { googleCallback } from '@/services/social-auth-service'
import { getBillingPortalUrl, getUserSubscription } from '@/services/payment-service'

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): AuthInterface => ({
    user: initUser(),
    returnUrl: localStorage.getItem('returnUrl') || '/',
    isSubscribed: false,
    billingPortalUrl: null,
    showLogin: false,
    showRegister: false
  }),
  actions: {
    async registerByEmail(user: any) {
      await createEmailUser(user)
    },
    async updateUserDetails(user: User) {
      const payload = { ...user, id: this.user!.id }
      this.user = await updateUser(payload)
      saveUserToLocalStorage(this.user as User)
    },
    async changeUserPassword(payload: { password: string; newPassword: string }) {
      const data = { ...payload, id: this.user!.id } as any
      await updateUserPassword(data)
    },
    async refreshUser() {
      if (this.user) {
        this.user = await refreshUserDetails(this.user?.id as number)
        saveUserToLocalStorage(this.user)
      }
    },
    async loginEmailUser({ email, password, captchaToken }: LogIn) {
      const res = await login(email, password, captchaToken)
      const { user: newUser, verified } = res
      if (verified) {
        this.processLogin(newUser)
      }
      return res
    },
    async processLogin(user: User) {
      this.user = user
      saveUserToLocalStorage(user)
    },
    async googleCallbackExec(code: string) {
      const res = await googleCallback(code)
      const { user } = res
      this.user = user
      saveUserToLocalStorage(user)
      return user
    },
    async forgotPassword(email: string, token: string) {
      const res = await forgotPassword(email, token)
      return res
    },
    async setNewPassword({
                           password,
                           password_confirmation,
                           token,
                           id
                         }: {
      password: string;
      password_confirmation: string;
      token: string;
      id: number;
    }) {
      const res = await resetPassword(password, password_confirmation, token, id)
      return res
    },
    async getSubscription() {
      const res = await getUserSubscription()
      this.isSubscribed = res.subscription
    },
    async setBillingPortalUrl() {
      const res = await getBillingPortalUrl()
      this.billingPortalUrl = res.url
    },
    setReturnUrl(url: string) {
      this.returnUrl = url
      localStorage.setItem('returnUrl', url)
    },
    async logout() {
      this.user = null
      this.returnUrl = '/'
      this.billingPortalUrl = null

      const keysToRemove = ['user', 'returnUrl']

      keysToRemove.forEach((key) => {
        localStorage.removeItem(key)
      })
      await logOut()
      this.toggleLogin(false, false)
      this.router.push({ name: 'home' })
    },
    async deleteUserAccount() {
      await deleteAccount(this.user!.id!)
      this.logout()
    },
    async saveEmailPreference(newsLetter: boolean) {
      const res = await updatePreferences(newsLetter, this.user!.id!)
      this.processLogin(res)
    },
    toggleLogin(showLogin: boolean = false, showRegister: boolean = false) {
      this.showRegister = showRegister
      this.showLogin = showLogin
    },
    toggleRegister(showRegister: boolean = false, showLogin: boolean = false) {
      this.showLogin = showLogin
      this.showRegister = showRegister
    }
  },

  getters: {
    isLoggedIn(): boolean {
      return !!this.user
    },
    isVerified(): boolean {
      return !!this.user?.email_verified_at
    },
    userReturnUrl(): string {
      return this.returnUrl || '/'
    }
  }
})

const initUser = () => {
  const user = localStorage.getItem('user')
  return user ? JSON.parse(user) : null
}

const saveUserToLocalStorage = (user: User) => {
  localStorage.setItem('user', JSON.stringify(user))
}

type AuthInterface = {
  user: User | null;
  returnUrl: string | null;
  isSubscribed?: boolean;
  billingPortalUrl?: string | null;
  showLogin: boolean;
  showRegister: boolean;
};

export type User = {
  id: number | null;
  name: string;
  surname: string;
  email: string;
  max_projects: number;
  newsletter: boolean;
  token?: string;
  pm_last_four?: number;
  email_verified_at: string;
  auth_method: 'GOOGLE' | 'PASSWORD';
};

export type LogIn = {
  email: string;
  password: string;
  captchaToken: string;
};
