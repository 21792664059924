<template>
  <SectionNavigatorPaid @loading="() => (isUpdating = true)" @refresh="() => (isUpdating = true)" />
  <section v-show="!initialLoader">
    <div class="content-wrapper">
      <SocialMedia
        :global-loader="isUpdating"
        @disable="() => disableLoading()"
        @disable-initial="disableInititalLoader"
      />
      <MarketingCampaigns :global-loader="isUpdating" @disable-initial="disableInititalLoader" />
      <BlogContent :global-loader="isUpdating" @disable-initial="disableInititalLoader" />
      <div v-if="isUpdating || (!allPagesReady() && !initialLoader)">
        <page-blocking-loader
          :title="isUpdating ? 'Updating your vision' : 'Creating your vision'"
          :message="
            isUpdating
              ? 'Please hang tight for just a moment; we\'re almost ready to impress you!'
              : 'Please hang tight for just a moment; we\'re almost ready to dazzle you!'
          "
        />
      </div>
      <div v-if="!allPagesReady()" class="empty-container"></div>
    </div>
    <bottom-section-component @loading="() => (isUpdating = true)" @refresh="() => (isUpdating = true)" />
    <RefineResultComponent section="MESSAGING" />
  </section>
  <section v-if="initialLoader"><content-loader /></section>
</template>

<script setup lang="ts">
import RefineResultComponent from '@/components/refine-result/RefineResultComponent.vue';
import SectionNavigatorPaid from '@components/navigation/sections/SectionNavigatorPaid.vue';
import { ref } from 'vue';
import BlogContent from '@components/content/BlogContent.vue';
import MarketingCampaigns from '@components/content/MarketingCampaigns.vue';
import SocialMedia from '@components/content/SocialMedia.vue';
import { useMessagingStore } from '../stores/messaging';
import { useSocialStore } from '../stores/social-media';
import { useBlogContentStore } from '../stores/blog-content';
import { storeToRefs } from 'pinia';
import PageBlockingLoader from '../components/spinner/PageBlockingLoader.vue';
import ContentLoader from '@components/ui/content-loader/ContentLoader.vue';
import BottomSectionComponent from '../components/section-bottom/BottomSectionComponent.vue';

const isUpdating = ref<boolean>(false);
const initialLoader = ref<boolean>(true);
const disableInitialLoaderCounter = ref<number>(0);

const allPagesReady = () => {
  return messaging.value.length > 0 && socialPlatforms.value.length > 0 && articles.value.length > 0;
};

const disableInititalLoader = () => {
  disableInitialLoaderCounter.value += 1;

  if (disableInitialLoaderCounter.value >= 3) {
    initialLoader.value = false;
  }
};

const disableLoading = () => {
  isUpdating.value = false;
};

const { messaging } = storeToRefs(useMessagingStore());
const { socialPlatforms } = storeToRefs(useSocialStore());
const { articles } = storeToRefs(useBlogContentStore());
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.content-wrapper {
  position: relative;
  .empty-container {
    margin-top: 60px;
    height: 900px;
  }
}

.title {
  text-align: center;
  margin: 1rem 0;
}
h3 {
  text-align: center;
}
.blog-titles,
.messaging {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  row-gap: 1rem;
}
.messaging {
  p {
    text-align: left;
    background-color: $grey-bg;
    padding: 1rem;
    border-radius: 0.5rem;
  }
}

.refine {
  margin-top: 6.875rem;
}
</style>
