<template>
  <section v-if="messaging.length">
    <div class="top">
      <div class="title">
        <h2>Marketing campaigns</h2>
      </div>
      <div class="buttons">
        <EditButtonComponent @edit="toggleEdit()" :is-editing="isEditing" />
        <RegenerateButton @regenerate="() => (showWarning = true)" :enabled="!isEditing" />
      </div>
    </div>
    <div class="info-wrapper" v-if="summary">
      <InfoComponent>
        <template #body>
          <p class="info-body">
            {{ summary }}
          </p>
        </template>
      </InfoComponent>
    </div>

    <h3 class="spacer">Online Marketing</h3>

    <div class="grid">
      <ContentCard
        v-for="(item, i) of onlineMarketing"
        :key="i"
        :content="item"
        :isEditing="isEditing"
        :pinItem="pinItem"
        section="messaging"
      />
    </div>
    <h3 class="spacer">Offline Marketing</h3>
    <div class="grid">
      <ContentCard
        v-for="(item, i) of offlineMarketing"
        :key="i"
        :content="item"
        :isEditing="isEditing"
        :pinItem="pinItem"
        section="messaging"
      />
    </div>
    <h3 class="spacer">Promotions and Specials</h3>
    <div class="grid">
      <ContentCard
        v-for="(item, i) of promoMarketing"
        :key="i"
        :content="item"
        :isEditing="isEditing"
        :pinItem="pinItem"
        section="messaging"
      />
    </div>
    <div v-if="inactive.length && !globalLoader">
      <page-blocking-loader
        title="Updating your vision"
        message="Please hang tight for just a moment; we're almost ready to impress you!"
      />
    </div>
  </section>
  <regeneration-popup v-if="showWarning" @proceed="regenerate()" @close-popup="() => (showWarning = false)" />
</template>

<script setup lang="ts">
import ContentCard from '../ui/card/ContentCard.vue';
import { storeToRefs } from 'pinia';
import { onMounted, onUnmounted, onUpdated, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useMessagingStore } from '../../stores/messaging';
import InfoComponent from '../info-component/InfoComponent.vue';
import RegenerationPopup from '../ui/regeneration-popup/RegenerationPopup.vue';
import EditButtonComponent from '@components/ui/edit-button/EditButtonComponent.vue';
import RegenerateButton from '@components/ui/regenerate-button/RegenerateButton.vue';
import PageBlockingLoader from '../spinner/PageBlockingLoader.vue';

const store = useMessagingStore();
const { onlineMarketing, promoMarketing, offlineMarketing, inactive, messaging, summary } = storeToRefs(store);
const emit = defineEmits(['disable-initial']);
const isEditing = ref(false);
const showWarning = ref(false);
const route = useRoute();
const { id } = route.params;
let interval;

const { globalLoader } = defineProps({
  globalLoader: { type: Boolean, default: false }
});

const toggleEdit = () => {
  if (isEditing.value) {
    store.updateMessaging(+id);
  }
  isEditing.value = !isEditing.value;
};

const pinItem = async (id) => {
  await store.pinOne(id);
};
const loadContent = async () => {
  await store.getMessaging(+id);
  if (inactive.value.length <= 0 && messaging.value.length) {
    clearInterval(interval);
    interval = null;
  }
  emit('disable-initial');
};
const regenerate = async () => {
  showWarning.value = false;
  await store.regenerate(+id);
};

onMounted(async () => {
  await loadContent();
  if (!messaging.value.length || inactive.value.length > 0) {
    if (!interval) interval = setInterval(loadContent, 3000);
  }
});

onUpdated(() => {
  if (!messaging.value.length || inactive.value.length > 0) {
    if (!interval) interval = setInterval(loadContent, 3000);
  }
});

onUnmounted(async () => {
  clearInterval(interval);
  await store.clearStore();
});
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

section {
  padding: 60px 0;
  position: relative;

  .global-loader-wrapper {
    margin-top: 110px;
  }
}

.top {
  @include sm {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .buttons {
    .reg-button {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 0.75rem;
      background-color: transparent;

      &:hover {
        background-color: $black;
      }

      img {
        width: 1.8rem;
        height: 1.666rem;
      }
    }

    button {
      width: 100%;

      @include sm {
        width: auto;
      }
    }

    @include sm {
      display: flex;
      gap: 1rem;
    }
  }
}

.info-wrapper {
  width: 80%;
  margin: 0 auto;
  margin-top: 1.688rem;

  .info-body {
    font-size: 20px;
    font-weight: 600;
  }
}

.spacer {
  margin-top: 4.5rem;
  margin-bottom: 1.688rem;
}
</style>
