<script setup lang="ts">
const { enabled } = defineProps({
  enabled: {
    type: Boolean,
    default: true
  },
  dark: {
    type: Boolean,
    default: false,
    required: false
  }
})

const emit = defineEmits(['regenerate'])
</script>

<template>
  <button :disabled="!enabled" class="secondary icon_button" @click="() => emit('regenerate')" :class="{ dark: dark }">
    <span class="regenerate-button-icon"></span>
    Regenerate
  </button>
</template>

<style scoped lang="scss">
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

button {
  width: 100%;
  margin: 1.25rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.785rem;

  .regenerate-button-icon {
    width: 28px;
    height: 28px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    background-image: url('@assets/icons/regenerate-black.svg');
  }

  &:disabled {
    &:hover {
      cursor: default;
    }
    .regenerate-button-icon {
      background-image: url('@assets/icons/regenerate.svg');
    }
  }

  &:not(:disabled) {
    &:hover {
      .regenerate-button-icon {
        background-image: url('@assets/icons/regenerate-yellow.svg');
      }
    }
  }

  &.dark {
    background-color: $black;
    color: white;

    .regenerate-button-icon {
      background-image: url('@assets/icons/regenerate-yellow.svg');
    }

    &:hover {
      background-color: transparent;
      color: $black;
      border: 1px solid $black;

      .regenerate-button-icon {
        background-image: url('@assets/icons/regenerate-black.svg');
      }
    }
  }

  @include sm {
    width: fit-content;
  }
}
</style>
