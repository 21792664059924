import { defineStore } from 'pinia'
import { getResourceLimits } from '../services/config-service'

export const useConfigStore = defineStore({
  id: 'config-store',
  state: (): ConfigInterface => ({ resourceLimits: [] }),
  actions: {
    async getConfig() {
      const res = await getResourceLimits()
      this.resourceLimits = res
    }
  },
  getters: {
    personaLimit: (state) => state.resourceLimits.find((item) => item.type === 'persona')?.max_number
  }
})

type ConfigInterface = {
  resourceLimits: ResourceLimit[]
}

export type ResourceLimit = {
  name: string
  max_number: number
  type: string
}
