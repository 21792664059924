<template>
  <modal-component @close="() => emit('close-popup')">
    <template v-slot:title>
      <p class="lead-text">Content that is not pinned will be replaced</p>
    </template>
    <template v-slot:body>
      <div class="">
        <p>
          A friendly reminder: Anything you have not pinned will not be saved. Once you regenerate, you won't be able to
          go back.
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <div class="close">
        <button class="secondary" @click="() => emit('close-popup')">Cancel</button>
        <button @click="() => emit('proceed')">Go ahead</button>
      </div>
    </template>
  </modal-component>
</template>

<script setup lang="ts">
import ModalComponent from '../modal-component/ModalComponent.vue';

const emit = defineEmits(['close-popup', 'proceed']);
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.close {
  width: 100%;
  display: flex;
  gap: 1.25rem;
  flex-direction: column;

  button {
    margin: 0;
    width: 100%;
  }
  @include sm {
    flex-direction: row;
    justify-content: center;
  }
}
</style>
