import SetNewPasswordViewVue from '../../views/auth/SetNewPasswordView.vue';
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { reactive, ref, toRef, toRefs } from 'vue';
import { useAuthStore, User } from '../../stores/auth';
import { useField, useForm } from 'vee-validate';
import { object, string } from 'yup';
import { useToastStore } from '../../stores/toast';

const { user, billingPortalUrl } = storeToRefs(useAuthStore());
const store = useAuthStore();
const { alert } = useToastStore();
const isEditing = ref<boolean>(false);

const schema = object({
  password: string().required('Password is required'),
  newPassword: string()
    .required()
    .min(8, 'At least 8 characters')
    .matches(/[a-z]/, 'A lowercase character')
    .matches(/[A-Z]/, 'An uppercase character')
    .matches(/[0-9!@#$%^&*()-_=+{}|[\]:";'<>?,.\/]/, 'At least 1 number or symbol')
});

const { values, meta, resetForm } = useForm<{ password: string; newPassword: string }>({
  validationSchema: schema
});

const { value: password, errors: passwordErrors } = useField(() => 'password');
const { value: newPassword, errors: newPasswordErrors } = useField(() => 'newPassword');

const onSubmit = async () => {
  if (user.value?.auth_method !== 'PASSWORD') return;
  if (!meta.value.valid) {
    alert('Please fill in all fields', 'error');
    return;
  }

  try {
    await store.changeUserPassword(values);
    alert('New password saved', 'success');
    isEditing.value = false;
    resetForm();
  } catch (error) {
    alert(error.response.data.message ?? error.response.data.error, 'error');
  }
};
</script>

<template>
  <div class="full-span bottom-borders top-borders">
    <div class="top settings top-borders">
      <h3>Password</h3>
    </div>
    <div class="bottom">
      <div v-if="!isEditing" class="details">
        <div class="section">
          <p class="lead-text">Password</p>
          <p>***********************</p>
        </div>
      </div>
      <div v-else class="details section">
        <form v-if="user?.auth_method === 'PASSWORD'">
          <div class="input-wrapper">
            <label>Current password</label>
            <input
              type="password"
              v-model="password"
              :class="{
                error: passwordErrors?.length && password,
                success: !passwordErrors?.length && password
              }"
            />
          </div>
          <div class="input-wrapper">
            <label>New password</label>
            <input
              type="password"
              v-model="newPassword"
              :class="{
                error: newPasswordErrors?.length && newPassword,
                success: !newPasswordErrors?.length && newPassword
              }"
            />
            <div class="error" v-if="newPasswordErrors.length && newPassword.length">
              <p>Your password needs to contain:</p>
              <ul>
                <li v-for="(error, i) of newPasswordErrors" :key="i">{{ error }}</li>
              </ul>
            </div>
          </div>
        </form>
      </div>
      <div class="buttons">
        <button :disabled="user?.auth_method !== 'PASSWORD'" v-if="!isEditing" @click="() => (isEditing = true)">
          Change password
        </button>
        <button :disabled="!meta.valid || user?.auth_method !== 'PASSWORD'" v-else @click="onSubmit()">
          Change password
        </button>
        <button v-if="isEditing" @click="() => (isEditing = false)" class="secondary">Cancel</button>
      </div>
      <p v-if="user?.auth_method !== 'PASSWORD'">Your credentials are linked to your Google account.</p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.buttons {
  display: flex;
  gap: 1.25rem;
}
.settings {
  background-color: $mid-green;
}
.full-span {
  width: 100%;
  background-color: white;
}

.section {
  margin-bottom: 2rem;

  .lead-text {
    font-weight: 600;
  }
}

.top {
  padding: 2rem 2.5rem;
  width: 100%;
  h3 {
    margin: 0;
  }
}

.bottom {
  padding: 2rem 2.5rem;
}

form {
  .input-wrapper {
    margin-top: 1rem;
    input {
      background-color: $grey-bg;
      border: 1px solid $grey-5;
      &:hover {
        border: 2px solid $black !important;
      }
    }
  }
  .error {
    color: $signifier-error;
  }
}
</style>
