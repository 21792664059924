<template>
  <policies-component title="Cookie policy" :data="policy" />
</template>

<script setup lang="ts">
import { computed, onBeforeMount, ref } from 'vue';
import { getCookiePolicy } from '../services/strapi-service';
import { useHead } from '@unhead/vue';
import { constructHead } from '../utils/constructHead';
import PoliciesComponent from '../components/ui/policies/PoliciesComponent.vue';

const policy = ref(null);

onBeforeMount(async () => {
  const res = await getCookiePolicy();
  const data = { ...res, data: res.content };
  policy.value = data;

  if (res.seo[0]) {
    useHead(computed(() => constructHead(res.seo[0]!)));
  }
});
</script>

<style lang="scss" scoped></style>
