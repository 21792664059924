<script setup lang="ts">
const { enabled } = defineProps({
  enabled: {
    type: Boolean,
    default: true
  },
  pinned: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['pin'])
</script>

<template>
  <button :disabled="!enabled" class="pin" @click="() => emit('pin')" :class="pinned ? 'pinned' : 'secondary'">
    <span class="button-icon" :class="pinned ? 'pinned' : ''"></span>
  </button>
</template>

<style scoped lang="scss">
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

button {
  display: none;
  margin: 1.25rem 0;

  &.pinned {
  }

  .button-icon {
    display: block;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 32px;
    transition: all 0.2s ease-in-out;
    background-image: url('@assets/icons/pin-black.svg');

    &.pinned {
      background-image: url('@assets/icons/pinned-yellow.svg');
    }
  }

  &:disabled {
    &:hover {
      cursor: default;
    }
    .button-icon {
      background-image: url('@assets/icons/pin.svg');

      &.pinned {
        background-image: url('@assets/icons/pinned-disabled.svg');
      }
    }
  }

  &:not(:disabled) {
    &:hover {
      .button-icon {
        background-image: url('@assets/icons/pin-yellow.svg');

        &.pinned {
          background-image: url('@assets/icons/pinned.svg');
        }
      }
    }
  }

  @include sm {
    border-radius: 50%;
    padding: 1rem;
    width: 4rem;
    height: 4rem;
    display: block;
  }
}
</style>
