<template>
  <SectionNavigatorPaid @refresh="refreshPage()" @loading="() => (isLoading = true)" />
  <section v-if="!initialLoader">
    <div class="sections full-width" v-if="companyArchitecture.length >= 4">
      <div class="body">
        <div class="top">
          <div class="title">
            <h2>Your website structure</h2>
            <p class="lead-text">Below you will find the recommended structure for your website.</p>
          </div>
          <div class="buttons">
            <EditButtonComponent :isEditing="isEditing" @edit="toggleSave()" />

            <RegenerateButton :enabled="!isEditing" @regenerate="() => (showWarning = true)" />
          </div>
        </div>

        <div class="item-wrapper">
          <div
            class="item"
            :class="section.name.toLocaleLowerCase() == 'home' ? 'is-home' : ''"
            v-for="(section, i) in companyArchitecture"
            :key="i"
          >
            <h3>
              {{ section.name }}
              <PinIconComponent
                @pin="pinArchitecture(section.id)"
                :enabled="!isEditing"
                :pinned="section.pinned"
                style="display: block"
                :isYellow="i === 0"
                v-if="!isEditing"
              />

              <div
                v-if="isEditing && section.name.toLowerCase() !== 'home'"
                class="delete-section-icon"
                @click="deleteSection(i)"
              ></div>
            </h3>
            <ul>
              <li v-for="(point, index) in section.content" :key="index">
                <p v-if="!isEditing">{{ point }}</p>
                <textarea
                  v-else
                  v-model="section.content[index]"
                  oninput='this.style.height = "";this.style.height = this.scrollHeight + "px"'
                  :onfocus="() => (selectedIndex = Number(`${i}${index}`))"
                  :onblur="() => (selectedIndex = null)"
                  :ref="
                    (el) => {
                      if (!el) return;
                      el.style.height = '150px';
                    }
                  "
                ></textarea>
                <div
                  class="delete-icon"
                  :style="{ display: selectedIndex === Number(`${i}${index}`) ? 'block' : 'none' }"
                >
                  <img class="grey" src="@assets/icons/close-grey.svg" alt="" />
                  <img
                    :onmousedown="() => removeItem(i, index)"
                    class="yellow"
                    src="@assets/icons/close-yellow.svg"
                    alt=""
                  />
                </div>
              </li>
              <button v-if="isEditing" @click="addNewItem(i)" class="add_item primary"
                      :class="section.name.toLocaleLowerCase() === 'home' ? 'is-home' : ''">
                <span class="button-icon" :class="section.name.toLocaleLowerCase() == 'home' ? 'is-home' : ''"></span>
              </button>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <page-blocking-loader v-else title="Creating your vision" />
    <bottom-section-component @refresh="refreshPage()" @loading="() => (isLoading = true)" />
    <div v-if="isLoading || inactive.length">
      <page-blocking-loader
        title="Updating your vision"
        message="Please hang tight for just a moment; we're almost ready to impress you!"
      />
    </div>
    <RefineResultComponent section="ARCHITECTURE" />
    <regeneration-popup v-if="showWarning" @proceed="regenerateSection()" @close-popup="() => (showWarning = false)" />
  </section>
  <section v-else>
    <content-loader />
  </section>
</template>

<script setup lang="ts">
import RefineResultComponent from '@/components/refine-result/RefineResultComponent.vue'
import SectionNavigatorPaid from '@components/navigation/sections/SectionNavigatorPaid.vue'
import { useRoute } from 'vue-router'
import { onMounted, onUnmounted, onUpdated, ref } from 'vue'
import { useArchitectureStore } from '../stores/architecture'
import { storeToRefs } from 'pinia'
import { useCompanyStore } from '@/stores/company'
import RegenerationPopup from '../components/ui/regeneration-popup/RegenerationPopup.vue'
import EditButtonComponent from '@components/ui/edit-button/EditButtonComponent.vue'
import RegenerateButton from '@components/ui/regenerate-button/RegenerateButton.vue'
import PinIconComponent from '@components/ui/pin-icon/PinIconComponent.vue'
import PageBlockingLoader from '../components/spinner/PageBlockingLoader.vue'
import ContentLoader from '@components/ui/content-loader/ContentLoader.vue'
import BottomSectionComponent from '../components/section-bottom/BottomSectionComponent.vue'

const { companyArchitecture, inactive } = storeToRefs(useArchitectureStore())
const { pinArchitecture, getArchitecture, updateSections, clearStore } = useArchitectureStore()
const companyStore = useCompanyStore()

const route = useRoute()
const { id } = route.params
const initialLoader = ref<boolean>(true)
const isLoading = ref<boolean>(false)
const isEditing = ref<boolean>(false)
const selectedIndex = ref(null)
const showWarning = ref<boolean>(false)

let interval: any

const refreshPage = async () => {
  await getArchitecture(+id)
  initialLoader.value = false
  if (inactive.value.length <= 0 && companyArchitecture.value.length >= 4) {
    clearInterval(interval)
    interval = null
    isLoading.value = false
  }
}

const regenerateSection = async () => {
  showWarning.value = false
  try {
    isLoading.value = true
    await companyStore.regenerateSection(+id, 'architecture')
    await companyStore.getCompany(+id)
    refreshPage()
  } catch (error) {
    isLoading.value = false
  }
}

const addNewItem = (itemIndex: number) => {
  companyArchitecture.value[itemIndex]['content'].push('')
}

const deleteSection = (sectionId: number) => {
  companyArchitecture.value.splice(sectionId, 1)
}

const removeItem = (parentIndex: number, itemIndex: number) => {
  companyArchitecture.value[parentIndex].content.splice(itemIndex, 1)
}

const toggleSave = async () => {
  isEditing.value = !isEditing.value
  if (!isEditing.value) {
    await updateSections(companyArchitecture.value)
    await refreshPage()
  }
}

onMounted(async () => {
  try {
    await refreshPage()
    if (inactive.value.length > 0 || companyArchitecture.value.length < 4) {
      if (!interval) interval = setInterval(refreshPage, 3000)
    }
  } catch (error) {
    isLoading.value = false
  }
})


onUpdated(() => {
  if (inactive.value.length > 0 || companyArchitecture.value.length < 4) {
    if (!interval) interval = setInterval(refreshPage, 3000)
  }
})

onUnmounted(() => {
  clearStore()
})
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.placeholder-wrapper {
  margin: 110px 0;
}

.sections {
  margin: 40px 0;
  background-color: $mid-grey;
  position: relative;

  .body {
    padding: 60px 0;

    .top {
      h2 {
        margin-bottom: 1.25rem;
      }

      .title {
        margin-bottom: 64px;
      }

      @include xl {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }


      .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        margin-bottom: 40px;

        @include sm {
          flex-direction: row;
          justify-content: flex-start;
        }

        button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 0.5rem;
          margin: 0;
          height: 44px;

          img {
            width: 1.25rem;
            height: 1.25rem;
          }

          @include sm {
            height: 64px;
            width: fit-content;
          }
        }
      }
    }

    .item-wrapper {
      @include xl {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
      }

      .item {
        border-radius: 30px;
        overflow: hidden;
        background-color: white;
        margin-bottom: 1.25rem;

        @include xl {
          margin-bottom: 0;
        }

        h3 {
          padding: 32px 40px;
          margin-bottom: 0;
        }

        ul {
          padding: 24px;
        }

        h3 {
          width: 100%;
          background-color: $grey-3;
          display: flex;
          justify-content: space-between;
          align-items: center;

          div {
            cursor: pointer;
          }
        }

        ul {
          li {
            position: relative;

            p {
              padding: 1rem;
              background-color: $mid-grey;
              border-radius: 15px;
              margin-bottom: 12px;
            }

            .delete-icon {
              position: absolute;
              right: 1rem;
              top: 1.2rem;
              padding: 0.375rem;
              cursor: pointer;
              display: none;
              z-index: 2;

              img {
                height: 1.5rem;
                width: 1.5rem;
              }

              .grey {
                display: block;
              }

              .yellow {
                display: none;
              }

              &:hover {
                background-color: $black;
                border-radius: 0.6rem;

                .grey {
                  display: none;
                }

                .yellow {
                  display: block;
                }
              }
            }

            textarea {
              padding: 1rem 2.5rem 1rem 1rem;
              background-color: $mid-grey;
              border-radius: 15px;
              margin-bottom: 12px;
              width: 100%;
              height: 100px;
              resize: none;
            }

            .cross {
              display: none;
              position: absolute;
              right: 20px;
              top: 20px;
              cursor: pointer;
              padding: 6px;
              background-color: $black;
              height: 2.75rem;
              width: 2.75rem;
              border-radius: 5px;
            }
          }

          .add_item {
            height: 4.438rem;
            width: 4.438rem;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            background-color: transparent;

            &:hover {
              background-color: $black;
            }

            &.is-home {
              border-color: white;

              &:hover {
                background-color: white;
              }
            }

            .button-icon {
              width: 28px;
              height: 28px;
              background-repeat: no-repeat;
              background-position: center;
              background-size: 24px;
              background-image: url('@assets/icons/cross.svg');

              &.is-home {
                background-image: url('@assets/icons/cross-yellow.svg');
              }

            }

            &:hover {
              .button-icon {
                background-image: url('@assets/icons/cross-yellow.svg');

                &.is-home {
                  background-image: url('@assets/icons/cross.svg');
                }
              }
            }

            &.is-home {

            }

            @include lg {
              margin: 0 auto;
            }
          }
        }

        .delete-section-icon {
          transition: all 0.2s ease-in-out;
          background-image: url('@assets/icons/close-black.svg');
          background-size: 24px 24px;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          width: 32px;
          height: 32px;
          padding: 0.375rem;
          border-radius: 0.6rem;
          cursor: pointer;

          &:hover {
            background-image: url('@assets/icons/close-yellow.svg');
            background-color: $black;
          }
        }

        &.is-home {
          background-color: $mid-black;
          color: white;
          @include xl {
            margin-bottom: 40px;
          }

          h3 {
            background-color: $black;
            width: 100%;
          }

          ul {
            background-color: $mid-black;

            li {
              p {
                background-color: $light-black;
                color: white;
              }
            }
          }
        }
      }
    }

    .item-wrapper > :first-child {
    }

    @include lg {
      .item-wrapper > :first-child {
        grid-column: 2 /3;
      }
      .item-wrapper > :nth-child(2) {
        grid-column: 1 /2;
      }
    }
  }

  .global-loader-wrapper {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 1.25rem;
    display: flex;
    align-items: center;
    border-radius: 30px;

    @include sm {
      padding: 6.875rem 0;
      border-radius: 120px;
    }
  }
}
</style>
