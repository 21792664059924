<template>
  <div v-if="post">
    <div class="blog-wrapper">
      <div class="top">
        <h2>{{ post.title }}</h2>
        <p>{{ formatDate(new Date(post.published)) }}</p>
      </div>
    </div>
    <div class="full-width">
      <p v-if="post.description" class="content body intro">
        {{ post.description }}
      </p>
      <div class="content body">
        <StrapiBlocks :content="post.blog_content" :modifiers="modifier" :blocks="userBlocks" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, h, onBeforeMount, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { BlogPost, getPost } from '../services/strapi-service'
import { ModifiersComponents, StrapiBlocks } from 'vue-strapi-blocks-renderer'
import { useHead } from '@unhead/vue'
import { constructHead } from '../utils/constructHead'

const post = ref<BlogPost | null>(null)

const route = useRoute()
const { slug } = route.params

function formatDate(date) {
  const options = { day: '2-digit', month: 'long', year: 'numeric' }
  const formatter = new Intl.DateTimeFormat('en-US', options)
  const formattedDate = formatter.format(date)

  const parts = formattedDate.split(' ')

  const day = parts[1].replace(',', '')
  const month = parts[0]
  const year = parts[2]

  let dayWithSuffix
  switch (parseInt(day)) {
    case 1:
    case 21:
    case 31:
      dayWithSuffix = day + 'st'
      break
    case 2:
    case 22:
      dayWithSuffix = day + 'nd'
      break
    case 3:
    case 23:
      dayWithSuffix = day + 'rd'
      break
    default:
      dayWithSuffix = day + 'th'
  }

  return `${dayWithSuffix} ${month} ${year}`
}

const userBlocks: BlocksComponents = {
  // Will include the class "mb-4" on all paragraphs
  paragraph: (props) => h('p', { class: 'mb-4' }, props.children),
  link: (props: any) => h('a', { class: 'link', href: props.url, target: '_blank' }, props.children)
}

const modifier: ModifiersComponents = {
  underline: (props) => h('span', { class: 'underline' }, props.children)
}

onBeforeMount(async () => {
  const res = await getPost(slug as string)
  post.value = res

  if (post.value) {
    useHead(computed(() => constructHead({ title: `Laiya - ${post?.value?.title}` })))
  }
})

onMounted(async () => {
})
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.intro {
  margin-bottom: 40px;
  font-size: 20px;
}

.blog-wrapper {
  .top {
    margin-top: 44px;
    margin-bottom: 60px;

    h2 {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }

    text-align: center;
  }
}

.full-width {
  background-color: $grey-2;
  padding: 3.75rem 0;
  border-radius: 60px 60px 0px 0px;
  @include lg {
    border-radius: 120px 120px 0px 0px;
  }

  .body {
    max-width: 696px;

    ::v-deep(.mb-4) {
      min-height: 10px;
    }
  }
}

::v-deep(ul) {
  margin-left: 1rem;
  margin-bottom: 12px;

  li {
    list-style: disc;
  }
}

::v-deep(ol) {
  margin-left: 1rem;
  margin-bottom: 12px;

  li {
    list-style: auto;
  }
}

::v-deep(img) {
  width: 100%;
  margin: 1.5rem 0;
}


</style>
