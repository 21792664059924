<template>
  <div class="coupon-wrapper">
    <div class="input-wrapper" v-if="!couponDetails">
      <label id="coupon-label">Coupon code</label>
      <input
        type="text"
        style="text-transform: uppercase"
        id="coupon"
        v-model="coupon"
        :class="{
          error: coupon && isValidCoupon === false,
          success: coupon && isValidCoupon === true
        }"
      />
    </div>

    <p v-if="coupon && isValidCoupon === false" id="error_label">You have entered an invalid or expired coupon code</p>
    <div class="chip-wrapper" v-if="couponDetails">
      <div class="chip">
        <p>{{ couponDetails.name }}</p>
        <img @click="removeCoupon" src="@assets/icons/close-yellow.svg" alt="" />
      </div>
      <p class="coupon-description">{{ couponDetails.metadata.description }}</p>
    </div>

    <div class="flex">
      <p>Total due today</p>
      <p v-if="couponDetails">
        £{{ calculatePrice(product.price, couponDetails?.amount_off, couponDetails?.percent_off) }}
      </p>
      <p v-else>£{{ product.price.toFixed(2) }}</p>
    </div>
  </div>
</template>

<script setup lang="ts">
import { watchDebounced } from '@vueuse/core';
import { useField, useForm } from 'vee-validate';
import { ref, defineProps } from 'vue';
import { object, string } from 'yup';
import { checkIfCouponIsValid } from '../../../services/payment-service';

const calculatePrice = (price: number, amountOff: number, percentOff: number) => {
  let total = price;
  if (amountOff > 0) {
    total = price - amountOff;
  } else {
    total = price - price * (percentOff / 100);
  }

  return total.toFixed(2);
};

const { product } = defineProps({
  product: {
    required: true
  }
});
const schema = object({
  coupon: string()
});

const { meta, handleSubmit } = useForm({
  validationSchema: schema
});

type CouponType = {
  amount_off: number;
  name: string;
  percent_off: number;
  is_valid: true;
  metadata: {
    description: string;
  };
  type: string;
};
const isValidCoupon = ref<boolean | null>(null);
const couponDetails = ref<CouponType | null>();

function removeCoupon() {
  isValidCoupon.value = null;
  coupon.value = '';
  localStorage.removeItem('coupon');
  localStorage.removeItem('couponType');
  couponDetails.value = null;
}

const { value: coupon, errors: couponErrors } = useField<string>(() => 'coupon');

watchDebounced(
  coupon,
  async () => {
    isValidCoupon.value = null;
    try {
      if (coupon.value.length < 3) return;
      const res = await checkIfCouponIsValid(coupon.value.toUpperCase());

      if (!res.is_valid) {
        isValidCoupon.value = false;
        return;
      }

      localStorage.setItem('coupon', res.id);
      localStorage.setItem('couponType', res.type);
      couponDetails.value = res;
      isValidCoupon.value = res.is_valid;
    } catch (error) {
      isValidCoupon.value = false;
    }
  },
  { debounce: 1000, maxWait: 2000 }
);
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
#coupon-label,
#error_label {
  color: rgb(48, 49, 61);
  font-family: -apple-system, 'system-ui', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  font-weight: 400;
  font-size: 14.88px;
  margin-bottom: 4px;
  line-height: 17px;
}

#coupon-label {
  padding: 0 !important;
}

#error_label {
  margin-top: 0.5rem;
  color: #df1b41;
}

#coupon {
  border-radius: 5px;
  border: 1px solid rgb(230, 230, 230);
  background-color: rgb(255, 255, 255);
  color: rgb(48, 49, 61);
  font-family: -apple-system, 'system-ui', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  padding: 12px;
  height: unset;
  margin: 0;

  &:hover {
    border: 1px solid rgb(230, 230, 230);
  }

  &:focus {
    border-color: rgba(5, 115, 225, 0.5);
  }

  &:focus-visible {
    outline: 4px solid rgba(5, 115, 225, 0.5);
    border: none;
  }

  &.error {
    border: 1px solid #df1b41;
    outline: 1px solid #df1b41;
    background-image: none;
  }
}
.chip-wrapper {
  margin: 1rem 0;
  color: rgb(48, 49, 61);
  font-family: -apple-system, 'system-ui', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  .chip {
    background-color: $black;
    color: white;
    width: fit-content;
    align-items: center;
    display: flex;
    padding: 0.5rem 1rem;
    border-radius: 30px;
    gap: 1rem;
    img {
      width: 24px;
      cursor: pointer;
    }
  }

  .coupon-description {
    margin-top: 4px;
    color: rgb(109, 110, 120);
    font-family: -apple-system, 'system-ui', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-size: 12px;
  }
}

.flex {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(230, 230, 230);
  padding-top: 1rem;

  p {
    font-family: -apple-system, 'system-ui', 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
    font-size: 16px;
    color: $black !important;
    margin-top: 1rem;
    font-weight: 700;
  }
}
</style>
