<template>
  <section class="content-wrapper full-width" v-if="articles.length">
    <div class="body">
      <div class="top">
        <div class="title">
          <h2>Blog articles</h2>
          <p class="lead-text">
            Below you will find suggested blog articles to keep your audience updated and informed.
          </p>
        </div>
        <div class="buttons">
          <EditButtonComponent @edit="toggleEdit()" :is-editing="isEditing" />
          <RegenerateButton @regenerate="() => (showWarning = true)" :enabled="!isEditing" />
        </div>
      </div>
      <div class="grid">
        <ContentCard
          v-for="(article, i) of articles"
          :key="i"
          :content="article"
          :isEditing="isEditing"
          :pinItem="pinItem"
          :yellow-icon="false"
          section="blog"
        />
      </div>
    </div>
  </section>
  <page-blocking-loader
    v-if="inactive.length && !globalLoader"
    title="Updating your vision"
    message="Please hang tight for just a moment; we're almost ready to impress you!"
  />
  <regeneration-popup v-if="showWarning" @proceed="regenerateArticles()" @close-popup="() => (showWarning = false)" />
</template>

<script setup lang="ts">
import ContentCard from '../ui/card/ContentCard.vue';
import { onMounted, onUnmounted, onUpdated, ref } from 'vue';
import { useBlogContentStore } from '../../stores/blog-content';
import { useRoute } from 'vue-router';
import { storeToRefs } from 'pinia';
import RegenerationPopup from '../ui/regeneration-popup/RegenerationPopup.vue';
import EditButtonComponent from '@components/ui/edit-button/EditButtonComponent.vue';
import RegenerateButton from '@components/ui/regenerate-button/RegenerateButton.vue';
import PageBlockingLoader from '../spinner/PageBlockingLoader.vue';
import { useCompanyStore } from '../../stores/company';

const isEditing = ref(false);
const showWarning = ref(false);
const store = useBlogContentStore();
const companyStore = useCompanyStore();
const { articles, inactive } = storeToRefs(store);
const route = useRoute();
const { id } = route.params;
let interval: any;
const emit = defineEmits(['disable-initial']);
const { globalLoader } = defineProps({
  globalLoader: { type: Boolean, default: false }
});

const toggleEdit = () => {
  if (isEditing.value) {
    store.updateArticles(+id);
  }
  isEditing.value = !isEditing.value;
};

const regenerateArticles = async () => {
  showWarning.value = false;
  await store.regenerateArticles(+id);
  await companyStore.getCompany(+id);
};

const pinItem = (id: number) => {
  store.pinSinglePost(id);
};

const loadContent = async () => {
  await store.getArticles(+id);

  if (inactive.value.length <= 0 && articles.value.length) {
    clearInterval(interval);
    interval = null;
  }
  emit('disable-initial');
};

onMounted(async () => {
  await loadContent();

  if (!articles.value.length || inactive.value.length > 0) {
    if (!interval) interval = setInterval(loadContent, 3000);
  }
});

onUpdated(() => {
  if (!articles.value.length || inactive.value.length > 0) {
    if (!interval) interval = setInterval(loadContent, 3000);
  }
});

onUnmounted(async () => {
  clearInterval(interval);
  await store.clearStore();
});
</script>

<style lang="scss" scoped>
@import '@assets/scss/variables.scss';
@import '@assets/scss/mixins.scss';

.content-wrapper {
  background: $grey-2;
  padding: 60px 0;
  margin-bottom: 40px;

  .top {
    margin-bottom: 4.5rem;
    @include sm {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .buttons {
      .reg-button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        background-color: transparent;

        &:hover {
          background-color: $black;
        }

        img {
          width: 1.8rem;
          height: 1.666rem;
        }
      }

      button {
        width: 100%;

        @include sm {
          width: auto;
        }
      }

      @include sm {
        display: flex;
        gap: 1rem;
      }
    }
  }
}
</style>
