import axios from 'axios';

const baseUrl = import.meta.env.VITE_API_URL;
const apiHost = import.meta.env.VITE_API_HOST;

export const getCSRFToken = async () => {
  await axios.get(`${apiHost}/sanctum/csrf-cookie`);
};

export const login = async (email: string, password: string, captchaToken: string) => {
  const response = await axios.post(`${baseUrl}/auth/login`, { email, password, captchaToken });
  return response.data;
};

export const logOut = async () => {
  const response = await axios.get(`${baseUrl}/auth/log-out`);
  return response.data;
};

export const forgotPassword = async (email: string, token: string) => {
  const response = await axios.post(`${baseUrl}/auth/forgot-password`, { email, token });
  return response.data;
};

export const resetPassword = async (password: string, password_confirmation: string, token: string, id: number) => {
  const response = await axios.post(`${baseUrl}/auth/reset-password`, { password, password_confirmation, token, id });
  return response.data;
};

export const verifyEmail = async (token: string) => {
  const response = await axios.post(`${baseUrl}/auth/verify-email/${token}`);
  return response;
};

export const resendVerificationEmail = async (id: string) => {
  const response = await axios.post(`${baseUrl}/auth/resend-verification-email/${id}`);
  return response.data;
};
